import { Box, Text } from '@chakra-ui/react'

import React from 'react'

import Button from '../../ui/Button'

interface CreateDentallyPatientConfirmationProps {
	onClose: () => void
}

const CreateDentallyPatientConfirmation: React.FC<CreateDentallyPatientConfirmationProps> = ({
	onClose,
}) => {
	const handleDone = () => {
		onClose()
	}

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				height: 'calc(100% - 106px)',
				justifyContent: 'space-between',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100%',
				}}
			>
				<Text
					sx={{
						fontSize: '46px',
						fontWeight: '700',
						lineHeight: '46px',
						color: '#303030',
						padding: 0,
						margin: '10px 0',
					}}
				>
					Thank you!
				</Text>
				<Text
					sx={{
						fontSize: '24px',
						fontWeight: '500',
						lineHeight: '46px',
						color: '#676767',
						padding: 0,
					}}
				>
					You have been successfully submitted the form.
				</Text>
			</Box>
			<Box
				sx={{
					background: '#FFF8F3',
					padding: '20px 40px',
					color: '#fff',
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<Button
					type='button'
					onClick={handleDone}
					sx={{
						background: '#2D3748',
						color: '#fff',
						fontSize: '17px',
						height: '40px',
						marginRight: '15px',
						width: '160px',
						textTransform: 'uppercase',
						borderRadius: '6px',
						_hover: {
							background: '#47546b',
						},
					}}
				>
					Done
				</Button>
			</Box>
		</Box>
	)
}

export default CreateDentallyPatientConfirmation
