import { gql, useLazyQuery, useMutation } from '@apollo/client'
import { CheckIcon, CloseIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'
import {
	FormControl,
	FormLabel,
	Stack,
	Skeleton,
	Text,
	Flex,
	HStack,
	Spinner,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import { AlarmInput as AlarmInputProps } from '__generated__/globalTypes'
import { format } from 'date-fns'
import { Controller, useForm } from 'react-hook-form'
import { AiOutlinePlus } from 'react-icons/ai'

import React, { FC, useEffect, useState } from 'react'

import DatePickerInput, { TimePicker } from '~/components/DatePicker'
import { onError } from '~/components/helpers'
import Button from '~/components/ui/Button'
import useToast from '~/components/ui/Toast'
import { processTimeAsDate, setKanbanRefetchVal } from '~/helpers'
import { Alarm, AlarmVariables } from '~/inputs/__generated__/Alarm'
import { DeleteAlarm, DeleteAlarmVariables } from '~/inputs/__generated__/DeleteAlarm'
import { FindLead_findLead } from '~/views/__generated__/FindLead'

import { CreateAlarm, CreateAlarmVariables } from './__generated__/CreateAlarm'
import { UpdateAlarm, UpdateAlarmVariables } from './__generated__/UpdateAlarm'

/***
 *
 * Queries & Mutations
 *
 ***/

export const alarmQuery = gql`
	query Alarm($id: ID!) {
		alarm(id: $id) {
			id
			due_date
			due_time
		}
	}
`

export const updateAlarmMutation = gql`
	mutation UpdateAlarm($alarmData: updateAlarmInput!) {
		updateAlarm(input: $alarmData) {
			alarm {
				id
				due_date
				due_time
			}
		}
	}
`

export const createAlarmMutation = gql`
	mutation CreateAlarm($alarmData: createAlarmInput!) {
		createAlarm(input: $alarmData) {
			alarm {
				id
				due_date
				due_time
			}
		}
	}
`

const deleteAlarmMutation = gql`
	mutation DeleteAlarm($input: deleteAlarmInput!) {
		deleteAlarm(input: $input) {
			alarm {
				id
				due_date
				due_time
			}
		}
	}
`

/***
 *
 * Interface & Type
 *
 ***/
interface Props {
	leadDetails?: FindLead_findLead
	onRefetchLead: () => void
}

const StyledAlarmWrapper = styled(FormControl)`
	flex-direction: column;
	position: relative;
	width: 100%;
	border: 1px solid rgba(100, 85, 75, 0.2);
	border-radius: 8px;
	padding: 18px;
	margin-right: 10px;
`

/***
 *
 * Alarm Input Component
 *
 ***/
const AlarmInput: FC<Props> = props => {
	const { leadDetails, onRefetchLead } = props
	const toast = useToast()
	const [isEditMode, setIsEditMode] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	const { control, handleSubmit, reset } = useForm({
		defaultValues: {
			due_date: leadDetails?.alarm?.due_date,
			due_time: leadDetails?.alarm?.due_time,
		},
	})

	const [fetchAlarm, { data, loading, refetch }] = useLazyQuery<Alarm, AlarmVariables>(alarmQuery)

	const [createAlarm] = useMutation<CreateAlarm, CreateAlarmVariables>(createAlarmMutation, {
		onError: error => onError(error, toast),
	})
	const [updateAlarm] = useMutation<UpdateAlarm, UpdateAlarmVariables>(updateAlarmMutation, {
		onError: error => onError(error, toast),
	})

	const [deleteAlarm] = useMutation<DeleteAlarm, DeleteAlarmVariables>(deleteAlarmMutation, {
		onError: error => onError(error, toast),
	})

	const dueTime = processTimeAsDate(data?.alarm?.due_date, data?.alarm?.due_time)

	useEffect(() => {
		if (leadDetails?.alarm?.id) {
			const fetcher = async () => fetchAlarm({ variables: { id: leadDetails?.alarm!.id } })
			fetcher()
		}
	}, [leadDetails?.alarm?.id])

	const createAlarmHandler = async (formData: AlarmInputProps) => {
		setIsLoading(true)
		const { data, errors } = await createAlarm({
			variables: {
				alarmData: { data: { ...formData, lead_id: leadDetails?.id } },
			},
		})

		if (!errors && data?.createAlarm?.alarm?.id) {
			const createdAlarmId = data?.createAlarm?.alarm?.id
			if (createdAlarmId) {
				await refetch({ id: createdAlarmId })
			}
			onRefetchLead()
			toast({ title: 'Alarm created.', position: 'top-right' })
			setIsEditMode(false)
		}
		setIsLoading(false)
	}

	const updateAlarmHandler = async (formData: AlarmInputProps) => {
		setIsLoading(true)
		setKanbanRefetchVal('true')

		const { errors } = await updateAlarm({
			variables: {
				alarmData: {
					where: { id: leadDetails?.alarm?.id as string },
					data: { ...formData, lead_id: leadDetails?.id },
				},
			},
		})

		if (!errors) {
			await refetch({ id: leadDetails?.alarm?.id as string })
			onRefetchLead()
			toast({ title: 'Alarm updated.', status: 'success', position: 'top-right' })
			setIsEditMode(false)
		}
		setIsLoading(false)
	}

	const deleteAlarmHandler = async () => {
		setIsLoading(true)
		setKanbanRefetchVal('true')

		const { errors } = await deleteAlarm({
			variables: { input: { where: { id: leadDetails?.alarm?.id as string } } },
		})

		if (!errors) {
			await refetch({ id: leadDetails?.alarm?.id as string })
			onRefetchLead()
			toast({ title: 'Alarm deleted.', status: 'success', position: 'top-right' })
			setIsEditMode(false)
		}
		setIsLoading(false)
	}

	const renderDateTime = () => {
		const renderString = () => {
			if (data?.alarm?.due_date && dueTime) {
				return `${format(new Date(data?.alarm?.due_date), 'LLL dd, yyyy')}, ${format(
					new Date(dueTime),
					'hh:mm a'
				)}`
			}
			if (data?.alarm?.due_date) {
				return `${format(new Date(data?.alarm?.due_date), 'LLL dd, yyyy')}`
			}
			if (dueTime) {
				return `${format(new Date(dueTime), 'hh:mm a')}`
			}
			return null
		}

		const stringToRender = renderString()

		if (stringToRender) {
			return (
				<Flex align='center' gap='0.5em'>
					<Text textAlign='left' color='#64554B' fontWeight={700} fontSize='1em'>
						{stringToRender}
					</Text>
				</Flex>
			)
		}
		return null
	}

	const onEditClick = () => {
		if (isEditMode) {
			if (!leadDetails?.alarm?.id) {
				console.log('create alarm')
				handleSubmit(createAlarmHandler)()
			} else {
				console.log('update alarm')
				handleSubmit(updateAlarmHandler)()
			}
		} else {
			setIsEditMode(true)
		}
	}

	const onDeleteClick = async () => {
		if (isEditMode) {
			reset()
			setIsEditMode(false)
		} else {
			await deleteAlarmHandler()
		}
	}

	const renderControls = () => {
		if (data?.alarm?.id || isEditMode) {
			return (
				<>
					<Button
						title='Edit'
						aria-label='Edit'
						bg='blue.400'
						color='white'
						_hover={{ bg: 'blue.500' }}
						leftIcon={isEditMode ? <CheckIcon /> : <EditIcon />}
						size='xs'
						onClick={onEditClick}
					/>
					<Button
						title='Delete'
						aria-label='Delete'
						colorScheme='red'
						leftIcon={isEditMode ? <CloseIcon /> : <DeleteIcon />}
						size='xs'
						onClick={onDeleteClick}
					/>
				</>
			)
		}
		return (
			<>
				<Button
					title='Edit'
					aria-label='Edit'
					bg='#EEAB7E'
					color='white'
					_hover={{ bg: '#EEAB7E', filter: 'brightness(0.9)' }}
					leftIcon={<AiOutlinePlus color='#fff' />}
					size='xs'
					onClick={() => setIsEditMode(true)}
				/>
			</>
		)
	}

	const renderContent = () => {
		if (isEditMode) {
			return (
				<Flex align='center' gap='1.5em'>
					<FormControl isRequired>
						<FormLabel
							fontSize='sm'
							color='#64554B'
							fontWeight={400}
							textTransform='capitalize'
							marginBottom='7px'
						>
							Date
						</FormLabel>
						<Controller
							control={control}
							name='due_date'
							rules={{ required: true }}
							render={({ field: { onChange } }) => (
								<DatePickerInput
									defaultValue={leadDetails?.alarm?.due_date}
									onChange={onChange}
									minDate={new Date()}
								/>
							)}
						/>
					</FormControl>

					<FormControl>
						<FormLabel
							fontSize='sm'
							color='#64554B'
							fontWeight={400}
							textTransform='capitalize'
							marginBottom='7px'
						>
							Time
						</FormLabel>
						<Controller
							control={control}
							name='due_time'
							render={({ field: { onChange } }) => (
								<TimePicker
									defaultValue={processTimeAsDate(
										leadDetails?.alarm?.due_date,
										leadDetails?.alarm?.due_time
									)}
									onChange={onChange}
								/>
							)}
						/>
					</FormControl>
				</Flex>
			)
		}
		return (
			<Flex direction='column'>
				<FormLabel
					fontSize='sm'
					color='#64554B'
					fontWeight={400}
					textTransform='capitalize'
					marginBottom='7px'
				>
					Alarm
				</FormLabel>
				{renderDateTime()}

				{!data?.alarm?.id && (
					<Text textAlign='left' color='#64554B' fontWeight={700} fontSize='1em'>
						No alarm added.
					</Text>
				)}
			</Flex>
		)
	}

	if (loading)
		return (
			<Stack spacing={10} w='full' mr={2}>
				<Skeleton height='8em' rounded='md' />
			</Stack>
		)

	return (
		<>
			<StyledAlarmWrapper>
				{isLoading && (
					<Flex
						justify='center'
						gap='1em'
						p='1em'
						sx={{
							position: 'absolute',
							top: 0,
							left: 0,
							width: '100%',
							zIndex: 10,
							height: '100%',
							backgroundColor: 'rgba(255,255,255,0.5)',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<Spinner />
						<Text color='gray.500' fontSize='sm'>
							Loading
						</Text>
					</Flex>
				)}
				<Flex align='center' justify='space-between'>
					{renderContent()}
					<HStack spacing={2}>{renderControls()}</HStack>
				</Flex>
			</StyledAlarmWrapper>
		</>
	)
}

export default AlarmInput
