import { useMutation, useQuery } from '@apollo/client'
import { FormControl, FormLabel, VStack, useDisclosure, Flex, Text } from '@chakra-ui/react'
import { AiOutlinePlus } from 'react-icons/ai'

import React, { FC, useContext, useState } from 'react'

import ConfirmPopup from '~/components/ConfirmPopup'
import { onError } from '~/components/helpers'
import Note from '~/components/lead/Note'
import NotesForm from '~/components/lead/NotesForm'
import { AuthContext } from '~/components/page/context'
import Button from '~/components/ui/Button'
import useToast from '~/components/ui/Toast'
import { FindLead_findLead_notes } from '~/views/__generated__/FindLead'
import { LeadNotes, LeadNotesVariables } from '~/views/__generated__/LeadNotes'
import { NoteDelete, NoteDeleteVariables } from '~/views/__generated__/NoteDelete'
import { allNotesQuery, deleteNoteMutation } from '~/views/notes'

/***
 *
 * Interface & Type
 *
 ***/
interface Props {
	notes?: (FindLead_findLead_notes | null)[] | null
	leadId?: string
	onRefetch: () => Promise<void>
}

/***
 *
 * Notes Input Component
 *
 ***/
const NotesInput: FC<Props> = props => {
	const [noteId, setNoteId] = useState('')
	const notesEditModal = useDisclosure()
	const deleteConfirmModal = useDisclosure()
	const addNoteModal = useDisclosure()
	const toast = useToast()
	const user = useContext(AuthContext)

	const { notes, onRefetch, leadId } = props

	const { refetch } = useQuery<LeadNotes, LeadNotesVariables>(allNotesQuery, {
		variables: { id: leadId as string },
	})

	const [deleteNote] = useMutation<NoteDelete, NoteDeleteVariables>(deleteNoteMutation, {
		onError: error => onError(error, toast),
	})

	const refetchHandler = async () => {
		await refetch?.()
		await onRefetch()
	}

	const handleNoteDelete = async () => {
		const { errors } = await deleteNote({
			variables: { noteInput: { where: { id: noteId, leadId: leadId as string, owner: user.id } } },
		})

		if (!errors) {
			await refetchHandler()
			deleteConfirmModal.onClose()
			toast({ title: 'Note deleted.', position: 'top-right' })
		}
	}

	const noteList = (!notes ? [] : notes.filter(Boolean)) as FindLead_findLead_notes[]

	return (
		<>
			<FormControl
				bg='#fff'
				border='1px solid rgba(100, 85, 75, 0.2)'
				borderRadius='8px'
				padding='15px 20px'
			>
				<Flex align='center' justify='space-between'>
					<FormLabel color='#64554B' fontWeight='400' fontSize='14px'>
						Notes
					</FormLabel>
					<Button
						title='Add'
						aria-label='Add'
						_hover={{ bg: '#EEAB7E', filter: 'brightness(0.9)' }}
						bg='#EEAB7E'
						w='34px'
						p={0}
						h='34px'
						leftIcon={<AiOutlinePlus color='#fff' />}
						onClick={addNoteModal.onOpen}
					/>
				</Flex>

				{!!notes?.length && (
					<VStack
						spacing={5}
						align='start'
						borderRadius='md'
						mt='1em'
						mb='2em'
						height='40vh'
						overflowY='scroll'
					>
						{noteList.map(note => (
							<div key={note.id}>
								<Note
									note={note}
									showDetails={true}
									onEdit={() => {
										setNoteId(note.id)
										notesEditModal.onOpen()
									}}
									onDelete={() => {
										setNoteId(note.id)
										deleteConfirmModal.onOpen()
									}}
								/>
							</div>
						))}
					</VStack>
				)}

				{!noteList.length && (
					<Text mt='1em' fontSize='1em' color='#64554B' fontWeight='700'>
						No notes added
					</Text>
				)}
			</FormControl>

			{addNoteModal.isOpen && leadId && (
				<NotesForm
					leadId={leadId}
					isOpen={addNoteModal.isOpen}
					onClose={addNoteModal.onClose}
					onRefetch={onRefetch}
				/>
			)}
			{notesEditModal.isOpen && (
				<NotesForm
					isOpen={notesEditModal.isOpen}
					onClose={notesEditModal.onClose}
					leadId={leadId as string}
					onRefetch={refetchHandler}
					noteId={noteId}
				/>
			)}
			<ConfirmPopup
				isOpen={deleteConfirmModal.isOpen}
				onClose={deleteConfirmModal.onClose}
				onConfirm={handleNoteDelete}
				message='Selected note will be deleted.'
			/>
		</>
	)
}

export default NotesInput
