import { gql, useQuery, useLazyQuery, useMutation } from '@apollo/client'
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	ModalFooter,
	FormControl,
	FormLabel,
	Flex,
	useDisclosure,
	Tag,
	Text,
} from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'
import { BiEdit } from 'react-icons/bi'
import { MultiValue } from 'react-select'

import React, { FC, useEffect } from 'react'

import Button from '~/components/ui/Button'
import SelectDropdown from '~/components/ui/SelectDropdown'
import useToast from '~/components/ui/Toast'
import { setKanbanRefetchVal } from '~/helpers'

import { Inquiries, Inquiries_inquiries } from './__generated__/Inquiries'
import { StageInquiries, StageInquiriesVariables } from './__generated__/StageInquiries'
import { UpdateInquiries, UpdateInquiriesVariables } from './__generated__/UpdateInquiries'

/***
 *
 * Queries & Mutations
 *
 ***/
export const inquiryListQuery = gql`
	query Inquiries {
		inquiries {
			id
			name
		}
	}
`

export const stageInquiriesQuery = gql`
	query StageInquiries($stageId: ID!) {
		stage(id: $stageId) {
			id
			inquiries {
				id
				name
			}
		}
	}
`

const updateInquiriesMutation = gql`
	mutation UpdateInquiries($input: updateStageInput!) {
		updateStage(input: $input) {
			stage {
				id
				inquiries {
					id
					name
				}
			}
		}
	}
`

/***
 *
 * Interface & Type
 *
 ***/
interface Props {
	stageId?: string
	onCreate?: (ids: string[]) => void
}

/***
 *
 * Inquiries Input Component
 *
 ***/
const InquiriesInput: FC<Props> = props => {
	const { stageId } = props
	const toast = useToast()

	const { isOpen, onClose, onOpen } = useDisclosure()
	const { control, handleSubmit, formState } = useForm<{ inquiries: string[] }>({
		mode: 'onChange',
	})

	const { data: inquiryData, refetch } = useQuery<Inquiries>(inquiryListQuery)
	const [fetchStageInquiries, { data: stageInquiries, loading: loadingStageInquiries }] =
		useLazyQuery<StageInquiries, StageInquiriesVariables>(stageInquiriesQuery)

	const [updateInquiries, { loading }] = useMutation<UpdateInquiries, UpdateInquiriesVariables>(
		updateInquiriesMutation
	)

	const inquiryList = stageInquiries?.stage?.inquiries?.length
		? stageInquiries.stage?.inquiries
		: []

	const inquiriesDefaultVal = stageInquiries?.stage?.inquiries
		?.map(inq => inq?.id)
		.filter(Boolean) as string[]

	const submitHandler = async (formData: { inquiries: string[] }) => {
		updateInquiries({
			variables: {
				input: { where: { id: stageId as string }, data: { inquiries: formData.inquiries } },
			},
		})

		await refetch()
		onClose()
		toast({ title: 'Enquiries updated.', position: 'top-right' })
		setKanbanRefetchVal('true')
	}

	useEffect(() => {
		if (!stageId) return

		fetchStageInquiries({ variables: { stageId } })
	}, [stageId])

	return (
		<>
			<FormControl isRequired={true} position='relative'>
				<Flex align='center' justify='space-between'>
					<FormLabel
						fontSize='1em'
						color='#191C1A'
						fontWeight='bold'
						textTransform='uppercase'
						requiredIndicator={<span />}
						m={0}
					>
						Inquiries
					</FormLabel>
					<Button
						title='Add'
						aria-label='Add'
						colorScheme='transparent'
						p={0}
						size='sm'
						leftIcon={<BiEdit color='#0000004d' fontSize={20} />}
						onClick={onOpen}
					/>
				</Flex>

				<Flex mt='1em' gap='0.5em' flexWrap='wrap'>
					{!inquiryList.length && (
						<Text fontSize='0.9em' color='gray.400'>
							No enquiries
						</Text>
					)}

					{inquiryList.map((inquiry, index) => (
						<Tag
							fontSize='1em'
							px='0.8em'
							py='0.5em'
							key={index}
							bg='#F8F8F8'
							color='#64554B'
							fontWeight={700}
							rounded='full'
							border='1px solid #64554B'
						>
							{inquiry?.name}
						</Tag>
					))}
				</Flex>
			</FormControl>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay backdropBlur='xl' />
				<ModalContent as='form'>
					<ModalHeader>Select</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<FormControl isRequired>
							<FormLabel color='gray.600' fontWeight='bold'>
								Enquiries
							</FormLabel>
							<Controller
								name='inquiries'
								control={control}
								rules={{ required: true }}
								defaultValue={inquiriesDefaultVal}
								render={({ field: { onChange } }) => (
									<SelectDropdown<Inquiries_inquiries>
										error={!!formState?.errors?.inquiries}
										isMulti
										variant='small'
										isLoading={loadingStageInquiries}
										options={inquiryData?.inquiries as Inquiries_inquiries[]}
										getOptionLabel={option => option.name as string}
										getOptionValue={option => option.id}
										placeholder='Select Enquiries'
										defaultValue={stageInquiries?.stage?.inquiries as Inquiries_inquiries[]}
										onChange={selected => {
											const values = selected as MultiValue<Inquiries_inquiries>
											onChange(values.length ? values?.map(val => val.id) : [])
										}}
									/>
								)}
							/>
						</FormControl>
					</ModalBody>
					<ModalFooter mt={2}>
						<Button onClick={onClose} mr={3} isDisabled={loading}>
							Cancel
						</Button>
						<Button
							colorScheme='green'
							type='button'
							isDisabled={loading}
							isLoading={loading}
							onClick={handleSubmit(submitHandler)} // Using handleSubmit on button to avoid event bubbling, as this is a nested form.
						>
							Save
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}

export default InquiriesInput
