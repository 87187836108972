import { ApolloError, gql, useMutation, useQuery } from '@apollo/client'
import {
	Card,
	CardBody,
	CardHeader,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	Input,
	Spinner,
	Text,
} from '@chakra-ui/react'
import { useFormik } from 'formik'

import React, { useState } from 'react'

import { onError } from '~/components/helpers'
import { Clinics } from '~/components/settings/__generated__/Clinics'
import Button from '~/components/ui/Button'
import useToast from '~/components/ui/Toast'

export const clinicsQuery = gql`
	query Clinics {
		clinics {
			id
			name
			np_target
		}
	}
`

export const updateClinicMutation = gql`
	mutation UpdateClinic($clinicData: updateClinicInput!) {
		updateClinic(input: $clinicData) {
			clinic {
				id
				name
				np_target
			}
		}
	}
`

const ClinicTargets = () => {
	const { data: clinicsData, loading: clinicsLoading } = useQuery<Clinics>(clinicsQuery)
	const [validationError, setValidationError] = useState<string | null>(null)
	const [isLoading, setIsLoading] = useState(false)
	const toast = useToast()

	const [updateClinic] = useMutation(updateClinicMutation, {
		onError: error => onError(error, toast),
		refetchQueries: ['Clinics'],
	})

	const onSubmit = async () => {
		return submitHandler(formik.values)
	}

	const submitHandler = async (formData: { [key: string]: number }) => {
		if (Object.values(formData).some(item => item <= 0)) {
			setValidationError('Value cannot be less than 0')
			return
		}
		setIsLoading(true)
		await Promise.all(
			Object.keys(formData).map(async key => {
				const clinicId = key
				const npTarget = formData[key]
				try {
					await updateClinic({
						variables: {
							clinicData: {
								where: { id: clinicId },
								data: { np_target: npTarget },
							},
						},
					})
				} catch (error) {
					onError(error as ApolloError, toast)
				}
			})
		)
		setIsLoading(false)
	}

	const getInitialValues = () => {
		const initialValues: { [key: string]: number } = {}
		clinicsData?.clinics?.forEach(clinic => {
			if (clinic) {
				initialValues[clinic.id] = clinic.np_target || ''
			}
		})
		return initialValues
	}

	const formik = useFormik({
		initialValues: getInitialValues(),
		validateOnChange: false,
		enableReinitialize: true,
		onSubmit: (values: { [key: string]: number }) => {
			submitHandler(values)
		},
	})
	const renderClinicsSettings = () => {
		if (isLoading) {
			return (
				<Flex justify='center' gap='1em' p='1em' minHeight='500px'>
					<Spinner />
					<Text color='gray.500' fontSize='sm'>
						Loading
					</Text>
				</Flex>
			)
		}
		return (
			<CardBody paddingTop={0}>
				<form
					onSubmit={e => {
						e.preventDefault()
						onSubmit()
					}}
				>
					{clinicsData?.clinics?.map(clinic => {
						if (clinic) {
							return (
								<Flex
									bg='white'
									px={4}
									py={2}
									rounded='md'
									shadow='sm'
									align='center'
									gap={2}
									key={clinic.id}
								>
									<Flex width='300px'>
										<FormControl
											isRequired
											w='18em'
											isInvalid={Boolean(formik?.errors?.[clinic.id])}
										>
											<FormLabel>{clinic.name}</FormLabel>
											<Input
												type='number'
												name={clinic.id}
												required={true}
												value={formik.values[clinic.id] || undefined}
												onChange={formik.handleChange}
												placeholder='Type here'
											/>
											<FormErrorMessage>Value is required</FormErrorMessage>
										</FormControl>
									</Flex>
								</Flex>
							)
						}
						return null
					})}
					<Flex gap='1em' padding='0px 20px 10px 20px' marginTop='30px'>
						<Button
							colorScheme='green'
							isLoading={clinicsLoading}
							onClick={onSubmit}
							width='200px'
							disabled={Boolean(validationError) || clinicsLoading}
						>
							Save Clinic Targets
						</Button>
						{validationError ? (
							<Text fontSize='sm' color='red.400' mt='0.5em'>
								{validationError}
							</Text>
						) : null}
					</Flex>
				</form>
			</CardBody>
		)
	}

	return (
		<Card mt={2}>
			<CardHeader>
				<Heading size='md'>Clinics Targets</Heading>
			</CardHeader>
			{renderClinicsSettings()}
		</Card>
	)
}

export default ClinicTargets
