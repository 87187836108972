import { gql, useMutation, useQuery } from '@apollo/client'
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Stack,
	Skeleton,
	useDisclosure,
	Flex,
	VStack,
	Divider,
	Text,
} from '@chakra-ui/react'
import { FaPlus } from 'react-icons/fa'

import { FC, useContext, useState } from 'react'

import ConfirmPopup from '~/components/ConfirmPopup'
import { onError } from '~/components/helpers'
import Note from '~/components/lead/Note'
import NotesForm from '~/components/lead/NotesForm'
import { AuthContext } from '~/components/page/context'
import Button from '~/components/ui/Button'
import useToast from '~/components/ui/Toast'

import { FindLead_findLead_notes } from './__generated__/FindLead'
import { LeadNotes, LeadNotesVariables } from './__generated__/LeadNotes'
import { NoteDelete, NoteDeleteVariables } from './__generated__/NoteDelete'
import UserView from './user'

/***
 *
 * Queries & Mutations
 *
 ***/

export const deleteNoteMutation = gql`
	mutation NoteDelete($noteInput: deleteNoteCustomInput!) {
		deleteNoteCustom(input: $noteInput)
	}
`

export const allNotesQuery = gql`
	query LeadNotes($id: ID!) {
		lead(id: $id) {
			notes(sort: "updated_at:desc") {
				id
				text
				type
				updated_at
				owner {
					id
					username
				}
			}
		}
	}
`

/***
 *
 * Interface & Type
 *
 ***/
interface Props {
	isOpen: boolean
	onClose: () => void
	leadId: string
	onRefetch: () => Promise<void>
}

/***
 *
 * Notes View Component
 *
 ***/
const NotesView: FC<Props> = props => {
	const { isOpen, onClose, onRefetch, leadId } = props
	const toast = useToast()
	const user = useContext(AuthContext)

	const {
		data,
		loading: loadingNotes,
		refetch,
	} = useQuery<LeadNotes, LeadNotesVariables>(allNotesQuery, {
		variables: { id: leadId },
	})

	const noteList = (
		!data?.lead?.notes ? [] : data?.lead?.notes?.filter(Boolean)
	) as FindLead_findLead_notes[]

	const [deleteNote] = useMutation<NoteDelete, NoteDeleteVariables>(deleteNoteMutation, {
		onError: error => onError(error, toast),
	})

	const notesEditModal = useDisclosure()
	const noteAddModal = useDisclosure()
	const deleteConfirmModal = useDisclosure()
	const userViewModal = useDisclosure()

	const [noteId, setNoteId] = useState('')
	const [userId, setUserId] = useState('')

	const refetchHandler = async () => {
		await refetch?.()
		await onRefetch()
	}

	const handleNoteDelete = async () => {
		const { errors } = await deleteNote({
			variables: { noteInput: { where: { id: noteId, leadId, owner: user.id } } },
		})

		if (!errors) {
			await refetchHandler()
			deleteConfirmModal.onClose()
			toast({ title: 'Note deleted.', position: 'top-right' })
		}
	}

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} size='4xl'>
				<ModalOverlay backdropBlur='xl' />

				<ModalContent as='form'>
					<ModalHeader>Notes</ModalHeader>
					<ModalCloseButton />
					<ModalBody pb={6}>
						{loadingNotes ? (
							<Stack spacing={10} mt={10}>
								<Skeleton height='4em' rounded='md' />
								<Skeleton height='4em' rounded='md' />
								<Skeleton height='4em' rounded='md' />
								<Skeleton height='4em' rounded='md' />
							</Stack>
						) : (
							<VStack alignItems='start' spacing={10}>
								{noteList.length > 0 && (
									<Flex
										flexWrap='wrap'
										justifyContent='space-between'
										mt={5}
										maxH='35em'
										overflowY='auto'
										gap={10}
									>
										{noteList.map(note => (
											<Note
												key={note.id}
												note={note}
												showDetails={true}
												onEdit={() => {
													setNoteId(note.id)
													notesEditModal.onOpen()
												}}
												onDelete={() => {
													setNoteId(note.id)
													deleteConfirmModal.onOpen()
												}}
												onViewUser={userId => {
													setUserId(userId)
													userViewModal.onOpen()
												}}
											/>
										))}
									</Flex>
								)}

								{!noteList.length && (
									<Flex w='full' justify='center'>
										<Text mt='1em' fontSize='1.1em' color='gray.400'>
											No notes added
										</Text>
									</Flex>
								)}

								<VStack w='full' spacing={5}>
									<Divider w='20em' borderWidth={1} />
									<Button
										colorScheme='green'
										size='sm'
										leftIcon={<FaPlus />}
										onClick={noteAddModal.onOpen}
									/>
								</VStack>
							</VStack>
						)}
					</ModalBody>
					<ModalFooter>
						<Button onClick={onClose} mr={3}>
							Close
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>

			{noteAddModal.isOpen && leadId && (
				<NotesForm
					leadId={leadId}
					isOpen={noteAddModal.isOpen}
					onClose={noteAddModal.onClose}
					onRefetch={refetchHandler}
				/>
			)}

			{notesEditModal.isOpen && (
				<NotesForm
					isOpen={notesEditModal.isOpen}
					onClose={notesEditModal.onClose}
					leadId={leadId}
					onRefetch={refetchHandler}
					noteId={noteId}
				/>
			)}

			{userViewModal.isOpen && (
				<UserView userId={userId} isOpen={userViewModal.isOpen} onClose={userViewModal.onClose} />
			)}

			<ConfirmPopup
				isOpen={deleteConfirmModal.isOpen}
				onClose={deleteConfirmModal.onClose}
				onConfirm={handleNoteDelete}
				message='Selected note will be deleted.'
			/>
		</>
	)
}

export default NotesView
