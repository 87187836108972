import { gql, useMutation, useQuery } from '@apollo/client'
import {
	Flex,
	Spinner,
	Switch,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react'
import { SingleValue } from 'react-select'

import React, { FC, useEffect, useState } from 'react'

import { onError } from '~/components/helpers'
import LeadApptsDentallyDataForm from '~/components/lead/components/LeadApptsDentallyDataForm'
import {
	CreateMultiplePractitioners,
	CreateMultiplePractitionersVariables,
} from '~/components/lead/components/__generated__/CreateMultiplePractitioners'
import {
	GetPractitionersAndReferrersByTxItemPlan,
	GetPractitionersAndReferrersByTxItemPlan_getPractitionersAndReferrersByTxItemPlan_practitioners,
	GetPractitionersAndReferrersByTxItemPlanVariables,
} from '~/components/lead/components/__generated__/GetPractitionersAndReferrersByTxItemPlan'
import {
	GetTreatmentPlanItems,
	GetTreatmentPlanItems_getTreatmentPlanItems_treatment_plan_items,
	GetTreatmentPlanItemsVariables,
} from '~/components/lead/components/__generated__/GetTreatmentPlanItems'
import {
	GetTreatmentPlans,
	GetTreatmentPlans_getTreatmentPlans_treatment_plans,
	GetTreatmentPlansVariables,
} from '~/components/lead/components/__generated__/GetTreatmentPlans'
import {
	PractitionersSearch,
	PractitionersSearchVariables,
} from '~/components/lead/components/__generated__/PractitionersSearch'
import Button from '~/components/ui/Button'
import SelectDropdown from '~/components/ui/SelectDropdown'
import useToast from '~/components/ui/Toast'
import { useUpdateLead } from '~/crud/lead'
import { FindLead_findLead } from '~/views/__generated__/FindLead'

import { createMultiplePractitionerInput } from '../../../../__generated__/globalTypes'

export const getTreatmentPlansQuery = gql`
	query GetTreatmentPlans($params: getTreatmentPlansParams!) {
		getTreatmentPlans(params: $params) {
			treatment_plans {
				id
				private_treatment_value
				nickname
			}
		}
	}
`

export const getTreatmentPlanItemsMutation = gql`
	mutation GetTreatmentPlanItems($params: getTreatmentPlanItemsParams!) {
		getTreatmentPlanItems(params: $params) {
			treatment_plan_items {
				id
				nomenclature
				completed
				price
				practitioner_id
				referrer_id
			}
		}
	}
`

export const getPractitionersAndReferrersByTxItemPlanMutation = gql`
	mutation GetPractitionersAndReferrersByTxItemPlan($params: getTreatmentPlanItemsParams!) {
		getPractitionersAndReferrersByTxItemPlan(params: $params) {
			practitioners {
				id
				user {
					first_name
					last_name
				}
			}
		}
	}
`

export const practitionersSearchQuery = gql`
	query PractitionersSearch($where: JSON) {
		practitionersSearch(where: $where) {
			id
			lead_id
			practitioner_id
			referrer_id
		}
	}
`

export const createMultiplePractitionersMutation = gql`
	mutation CreateMultiplePractitioners($data: createMultiplePractitionersInput!) {
		createMultiplePractitioners(data: $data) {
			id
			lead_id
			practitioner_id
			referrer_id
		}
	}
`

interface LeadTxPlanProps {
	leadDetails?: FindLead_findLead
	active: boolean
}

const LeadTxPlan: FC<LeadTxPlanProps> = props => {
	const { leadDetails } = props
	if (!props.active) {
		return null
	}
	const toast = useToast()
	const [leadUpdating, setLeadUpdating] = useState<boolean>(false)
	const [practitionersUpdating, setPractitionersUpdating] = useState<boolean>(false)
	const [dataIsLoading, setDataIsLoading] = useState<boolean>(false)
	const [leadControlLoading, setLeadControlLoading] = useState<boolean>(false)
	const [practitionersLoading, setPractitionersLoading] = useState<boolean>(false)
	const [treatmentPlanId, setTreatmentPlanId] = useState<number | null>(
		Number(leadDetails?.treatment_plan_id) || null
	)
	const [treatmentPlanItems, setTreatmentPlanItems] = useState<
		GetTreatmentPlanItems_getTreatmentPlanItems_treatment_plan_items[]
	>([])
	const [treatmentPlanItemsPrice, setTreatmentPlanItemsPrice] = useState<number | null>(
		leadDetails?.treatment_plan_price ? leadDetails?.treatment_plan_price : 0
	)

	const [practitioners, setPractitioners] = useState<
		GetPractitionersAndReferrersByTxItemPlan_getPractitionersAndReferrersByTxItemPlan_practitioners[]
	>([])

	const { data, loading } = useQuery<GetTreatmentPlans, GetTreatmentPlansVariables>(
		getTreatmentPlansQuery,
		{
			variables: { params: { patient_id: leadDetails?.person?.dentally_person_id } },
		}
	)
	const { data: savedPractitioners, loading: savedPractitionersLoading } = useQuery<
		PractitionersSearch,
		PractitionersSearchVariables
	>(practitionersSearchQuery, {
		variables: { where: { lead_id: Number(leadDetails?.id) } },
	})

	const { handleLeadUpdate } = useUpdateLead({
		onSuccess: {
			handler: () => {
				setLeadUpdating(false)
				setLeadControlLoading(false)
			},
		},
	})

	const [createMultiplePractitioners] = useMutation<
		CreateMultiplePractitioners,
		CreateMultiplePractitionersVariables
	>(createMultiplePractitionersMutation, {
		onError: error => onError(error, toast),
		onCompleted: () => {
			setPractitionersUpdating(false)
		},
	})

	const [getTreatmentPlanItems] = useMutation<
		GetTreatmentPlanItems,
		GetTreatmentPlanItemsVariables
	>(getTreatmentPlanItemsMutation, {
		onError: error => onError(error, toast),
	})

	const [getPractitionersAndReferrersByTxItemPlan] = useMutation<
		GetPractitionersAndReferrersByTxItemPlan,
		GetPractitionersAndReferrersByTxItemPlanVariables
	>(getPractitionersAndReferrersByTxItemPlanMutation, {
		onError: error => onError(error, toast),
	})

	useEffect(() => {
		if (treatmentPlanId) {
			setDataIsLoading(true)
			setPractitionersLoading(true)
			const requestTxPlanItems = async () => {
				return await getTreatmentPlanItems({
					variables: {
						params: {
							treatment_plan_id: treatmentPlanId.toString(),
						},
					},
				})
			}

			const requestTxPlanItemReferrersAndPractitioners = async () => {
				return await getPractitionersAndReferrersByTxItemPlan({
					variables: {
						params: {
							treatment_plan_id: treatmentPlanId.toString(),
						},
					},
				})
			}

			requestTxPlanItems().then(res => {
				setTreatmentPlanItems(res.data?.getTreatmentPlanItems?.treatment_plan_items || [])
				setDataIsLoading(false)
			})

			requestTxPlanItemReferrersAndPractitioners().then(res => {
				setPractitioners(res.data?.getPractitionersAndReferrersByTxItemPlan?.practitioners || [])
				setPractitionersLoading(false)
			})
		}
	}, [treatmentPlanId])

	useEffect(() => {
		if (leadDetails?.treatment_plan_id && treatmentPlanId) {
			if (
				Number(leadDetails?.treatment_plan_id) === treatmentPlanId &&
				leadDetails?.treatment_plan_price !== treatmentPlanItemsPrice
			) {
				handleSave()
			}
		}
	}, [leadDetails, treatmentPlanId, treatmentPlanItemsPrice])

	const prepareTreatmentPlanOptions = (
		treatment_plans: GetTreatmentPlans_getTreatmentPlans_treatment_plans[] | undefined
	) => {
		if (treatment_plans && treatment_plans.length) {
			return treatment_plans.map(plan => {
				const planLabel = plan.nickname ? `${plan.id} - ${plan.nickname}` : plan.id
				return {
					label: planLabel,
					value: plan.id,
				}
			})
		}
		return []
	}

	const getNotExistingPractitioners = () => {
		if (savedPractitionersLoading) {
			return []
		}
		if (!savedPractitioners) {
			return practitioners
		}
		return practitioners.filter(
			practitioner =>
				!savedPractitioners?.practitionersSearch.find(
					saved => saved?.practitioner_id === practitioner.id
				)
		)
	}

	const handleSave = async () => {
		const notExistingPractitioners = getNotExistingPractitioners()
		if (notExistingPractitioners.length && leadDetails?.id) {
			const practitionersToSave: createMultiplePractitionerInput[] = notExistingPractitioners.map(
				practitioner => {
					return {
						lead_id: leadDetails.id,
						practitioner_id: practitioner.id,
						referrer_id: practitioner.id,
						practitioner_name: practitioner.user?.first_name + ' ' + practitioner.user?.last_name,
						referrer_name: practitioner.user?.first_name + ' ' + practitioner.user?.last_name,
						current: true,
					}
				}
			)
			setPractitionersUpdating(true)
			await createMultiplePractitioners({
				variables: {
					data: {
						practitioners: practitionersToSave,
					},
				},
			})
		}
		if (leadDetails?.id && treatmentPlanId && treatmentPlanItemsPrice) {
			setLeadUpdating(true)
			await handleLeadUpdate(leadDetails.id, {
				treatment_plan_id: treatmentPlanId.toString(),
				treatment_plan_price: treatmentPlanItemsPrice,
			})
		}
	}

	const renderTable = () => {
		if (dataIsLoading && treatmentPlanId) {
			return (
				<Flex justify='center' gap='1em' p='1em'>
					<Spinner />
					<Text color='gray.500' fontSize='sm'>
						Loading
					</Text>
				</Flex>
			)
		}
		return (
			<Flex direction='column' mt={4}>
				<TableContainer>
					<Table variant='simple'>
						<Thead
							sx={{
								background: '#F2F2F2',
							}}
						>
							<Tr>
								<Th>Item No.</Th>
								<Th>Name</Th>
								<Th>Completed</Th>
								<Th>Price</Th>
							</Tr>
						</Thead>
						<Tbody>
							{treatmentPlanItems.map((planItem, i) => {
								return (
									<Tr key={planItem.id}>
										<Td>
											<Text fontSize='1em' color='#474747' fontWeight='700' lineHeight='1'>
												{++i}
											</Text>
										</Td>
										<Td>
											<Text fontSize='1em' color='#474747' fontWeight='700' lineHeight='1'>
												{planItem.nomenclature}
											</Text>
										</Td>
										<Td>
											<Text fontSize='1em' color='#474747' fontWeight='700' lineHeight='1'>
												{planItem.completed ? 'Yes' : 'No'}
											</Text>
										</Td>
										<Td>£{planItem.price}</Td>
									</Tr>
								)
							})}
						</Tbody>
					</Table>
				</TableContainer>
			</Flex>
		)
	}

	const getUniquePractitionerNames = (referrers?: boolean) => {
		const practitionerIds = treatmentPlanItems.map(
			item => item[referrers ? 'referrer_id' : 'practitioner_id']
		)
		const uniqueIds = [...new Set(practitionerIds)]
		return practitioners.filter(practitioner => uniqueIds.includes(practitioner.id))
	}

	const renderPractitioners = (referrers?: boolean) => {
		if (!practitioners.length || practitionersLoading) {
			return null
		}
		const uniquePractitioners = getUniquePractitionerNames(referrers)
		return (
			<Flex
				sx={{
					direction: 'column',
					border: '1px solid #D1D1D1',
					padding: '15px',
					marginLeft: '10px',
					borderRadius: '5px',
					'&:first-of-type': {
						marginLeft: 0,
					},
				}}
			>
				<Text sx={{ color: '#191C1A' }}>{referrers ? 'Referrers:' : 'Practitioners:'}</Text>
				<Text sx={{ fontWeight: 600, color: '#191C1A', marginLeft: '5px' }}>
					{uniquePractitioners.map((practitioner, i) => {
						const name = practitioner?.user?.first_name + ' ' + practitioner?.user?.last_name
						const comma = i !== uniquePractitioners.length - 1 ? ', ' : ''
						return (
							<span key={practitioner.id}>
								{name}
								{comma}
							</span>
						)
					})}
				</Text>
			</Flex>
		)
	}

	const renderTxPlanControls = () => {
		const treatmentPlanOptions = prepareTreatmentPlanOptions(
			data?.getTreatmentPlans?.treatment_plans
		)
		return (
			<>
				<Flex direction='column' width='400px'>
					<SelectDropdown
						variant='small'
						name='treatmentPlanId'
						options={treatmentPlanOptions}
						placeholder='Select treatment plan'
						defaultValue={treatmentPlanOptions.find(id => id.value === treatmentPlanId)}
						onChange={selected => {
							const newOption = selected as SingleValue<{ value: number }>
							setTreatmentPlanId(newOption?.value || null)
							const price = data?.getTreatmentPlans?.treatment_plans.find(
								plan => plan.id === newOption?.value
							)?.private_treatment_value
							setTreatmentPlanItemsPrice(price ? Number(price) : 0)
						}}
					/>
				</Flex>
				<Flex sx={{ margin: '10px 0' }}>
					<Flex
						sx={{
							direction: 'column',
							border: '1px solid #D1D1D1',
							borderRadius: '5px',
							padding: '15px',
						}}
					>
						<Text sx={{ color: '#191C1A' }}>Treatment plan cost:</Text>
						<Text sx={{ fontWeight: 600, color: '#191C1A', marginLeft: '5px' }}>
							£{treatmentPlanItemsPrice}
						</Text>
					</Flex>
					<Flex
						sx={{
							direction: 'column',
							border: '1px solid #D1D1D1',
							padding: '15px',
							marginLeft: '10px',
							borderRadius: '5px',
						}}
					>
						<Text sx={{ color: '#191C1A' }}>Treatment plan ID:</Text>
						<Text sx={{ fontWeight: 600, color: '#191C1A', marginLeft: '5px' }}>
							{treatmentPlanId ? treatmentPlanId : 'Not set'}
						</Text>
					</Flex>
				</Flex>
				<Flex sx={{ margin: '10px 0 20px 0' }}>
					{renderPractitioners()}
					{renderPractitioners(true)}
				</Flex>
				<Button
					colorScheme='green'
					type='button'
					sx={{ width: '200px' }}
					isDisabled={
						leadUpdating ||
						(!treatmentPlanItemsPrice && !getNotExistingPractitioners().length) ||
						practitionersUpdating
					}
					isLoading={leadUpdating || practitionersUpdating}
					onClick={handleSave}
				>
					Save
				</Button>
			</>
		)
	}

	const renderData = () => {
		if (!loading && !leadDetails?.person?.dentally_person_id) {
			return <LeadApptsDentallyDataForm leadDetails={leadDetails} />
		}
		return (
			<>
				{renderTxPlanControls()}
				{renderTable()}
			</>
		)
	}

	const renderLeadControls = () => {
		if (leadDetails?.id) {
			const leadControlsConfig = [
				{
					id: 'deposit_paid',
					title: 'Deposit Paid',
					value: leadDetails?.deposit_paid,
				},
				{
					id: 'payment_taken',
					title: 'Payment taken',
					value: leadDetails?.payment_taken,
				},
				{
					id: 'waiting_for_clin_check',
					title: 'Waiting for clin check',
					value: leadDetails?.waiting_for_clin_check,
				},
				{
					id: 'finance_application',
					title: 'Finance application',
					value: leadDetails?.finance_application,
				},
				{
					id: 'converted',
					title: 'Converted',
					value: leadDetails?.converted,
				},
			]
			return (
				<Flex direction='column' sx={{ marginBottom: '20px' }}>
					{leadControlsConfig.map(control => {
						return (
							<Flex key={control.id} width='300px' sx={{ margin: '5px 0' }}>
								<Switch
									onChange={evt => {
										setLeadControlLoading(true)
										handleLeadUpdate(leadDetails?.id, { [control.id]: evt.target.checked })
									}}
									disabled={leadControlLoading}
									colorScheme='twitter'
									defaultChecked={Boolean(control.value)}
								/>
								<Text fontSize='0.8em' ml={2}>
									{control.title}
								</Text>
							</Flex>
						)
					})}
				</Flex>
			)
		}
		return null
	}

	if (loading) {
		return (
			<Flex justify='center' gap='1em' p='1em'>
				<Spinner />
				<Text color='gray.500' fontSize='sm'>
					Loading
				</Text>
			</Flex>
		)
	}

	return (
		<Flex direction='column'>
			<Flex position='relative' direction='column' width='100%' mb='22px'>
				<Text color='#191C1A' fontSize='1.8em' fontWeight={700} casing='capitalize'>
					TX Plan
				</Text>
			</Flex>
			{renderLeadControls()}
			{renderData()}
		</Flex>
	)
}

export default LeadTxPlan
