import { gql, useLazyQuery } from '@apollo/client'
import {
	FormControl,
	FormLabel,
	useDisclosure,
	Flex,
	Stack,
	Skeleton,
	Text,
} from '@chakra-ui/react'
import { editStageInput } from '__generated__/globalTypes'
import { format } from 'date-fns'
import { BiEdit } from 'react-icons/bi'

import React, { FC, useEffect, useState } from 'react'

import Button from '~/components/ui/Button'
import { processTimeAsDate } from '~/helpers'
import CallbackInfoView from '~/views/callbackInfo'

import {
	CallbackInfoDetails,
	CallbackInfoDetailsVariables,
} from './__generated__/CallbackInfoDetails'

/***
 *
 * Queries & Mutations
 *
 ***/
const callbackInfoDetails = gql`
	query CallbackInfoDetails($id: ID!) {
		stage(id: $id) {
			id
			callback_date
			callback_time
		}
	}
`

/***
 *
 * Interface & Type
 *
 ***/
interface Props {
	stageId?: string
	editMode?: boolean
	onAdd?: (param: editStageInput) => void
}

/***
 *
 * Callback Info Input Component
 *
 ***/
const CallbackInfoInput: FC<Props> = props => {
	const { stageId, editMode, onAdd } = props
	const { isOpen, onOpen, onClose } = useDisclosure()

	const [fetchCallbackInfoDetails, { data, loading }] = useLazyQuery<
		CallbackInfoDetails,
		CallbackInfoDetailsVariables
	>(callbackInfoDetails)

	const callbackDate = data?.stage?.callback_date
	const callbackTime = processTimeAsDate(callbackDate, data?.stage?.callback_time)

	const [addedInfo, setAddedInfo] = useState<editStageInput>({
		callback_date: callbackDate,
		callback_time: callbackTime,
	})

	const callbackInfo = editMode
		? {
				callback_date: callbackDate,
				callback_time: callbackTime,
		  }
		: addedInfo

	useEffect(() => {
		stageId && fetchCallbackInfoDetails({ variables: { id: stageId } })
	}, [stageId])

	const renderDateTime = () => {
		const renderString = () => {
			if (callbackInfo.callback_date && callbackInfo.callback_time) {
				return `${format(new Date(callbackInfo.callback_date), 'LLL dd, yyyy')}, ${format(
					new Date(callbackInfo.callback_time),
					'hh:mm a'
				)}`
			}
			if (callbackInfo.callback_date) {
				return `${format(new Date(callbackInfo.callback_date), 'LLL dd, yyyy')}`
			}
			if (callbackInfo.callback_time) {
				return `${format(new Date(callbackInfo.callback_time), 'hh:mm a')}`
			}
			return null
		}

		const stringToRender = renderString()

		if (stringToRender) {
			return (
				<Flex align='center' gap='0.5em'>
					<Text textAlign='left' color='#64554B' fontWeight={700} fontSize='1em'>
						{stringToRender}
					</Text>
				</Flex>
			)
		}
		return null
	}

	if (loading)
		return (
			<Stack spacing={10} w='full' ml={2}>
				<Skeleton height='8em' rounded='md' />
			</Stack>
		)

	return (
		<>
			<FormControl
				flexDirection='column'
				border='1px solid rgba(100, 85, 75, 0.2)'
				borderRadius='8px'
				padding='18px'
			>
				<Flex align='center' justify='space-between'>
					<Flex direction='column'>
						<FormLabel
							fontSize='sm'
							color='#64554B'
							fontWeight={400}
							textTransform='capitalize'
							marginBottom='7px'
						>
							Callback Info
						</FormLabel>
						<Flex direction='column' gap='0.5em'>
							{editMode && !callbackInfo.callback_date && (
								<Text textAlign='left' color='#64554B' fontWeight={700} fontSize='1em'>
									Callback info is unset
								</Text>
							)}

							{renderDateTime()}
						</Flex>
					</Flex>
					<Button
						title={editMode ? 'Edit' : 'Add'}
						aria-label={editMode ? 'Edit' : 'Add'}
						colorScheme='transparent'
						p={0}
						size='sm'
						leftIcon={<BiEdit color='#0000004d' fontSize={20} />}
						onClick={onOpen}
					/>
				</Flex>
			</FormControl>

			{isOpen && (
				<CallbackInfoView
					stageId={stageId}
					isOpen={isOpen}
					onClose={onClose}
					editMode={editMode}
					defaultValues={{
						callback_date: callbackInfo.callback_date,
						callback_time: callbackInfo.callback_time,
					}}
					onAdd={data => {
						setAddedInfo({
							...data,
							callback_time: processTimeAsDate(data.callback_date, data.callback_time),
						})

						onAdd?.(data)
						onClose()
					}}
				/>
			)}
		</>
	)
}

export default CallbackInfoInput
