import { gql, useLazyQuery, useQuery, useMutation } from '@apollo/client'
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalFooter,
	ModalCloseButton,
	Box,
	Flex,
	Stack,
	Skeleton,
	useDisclosure,
	Tag,
	Select,
} from '@chakra-ui/react'
import styled from '@emotion/styled'

import { FC, useContext, useEffect, useState } from 'react'

import ConfirmPopup from '~/components/ConfirmPopup'
import { onError } from '~/components/helpers'
import { isLeadReadyToMove } from '~/components/kanban/helpers'
import Timeline from '~/components/lead/Timeline'
import { AuthContext } from '~/components/page/context'
import Button from '~/components/ui/Button'
import useToast from '~/components/ui/Toast'
import { StageStatusValues } from '~/constants'
import { useUpdateLead } from '~/crud/lead'
import { setKanbanRefetchVal } from '~/helpers'
import { CreateStage, CreateStageVariables } from '~/views/__generated__/CreateStage'
import { createStageMutation } from '~/views/addLead'
import StageView, { StageOnSubmit } from '~/views/stage'

import { ENUM_STAGE_TYPE } from '../../__generated__/globalTypes'
import { AllStages, AllStagesVariables, AllStages_stages } from './__generated__/AllStages'
import {
	CurrentStageDetails,
	CurrentStageDetailsVariables,
} from './__generated__/CurrentStageDetails'

/***
 *
 * Query & Mutations
 *
 ***/
export const allStagesQuery = gql`
	query AllStages($where: JSON) {
		stages(sort: "created_at:asc", where: $where) {
			id
			type
			created_at
			tx_plan {
				id
				cost
			}
			owner {
				id
				username
			}
		}
	}
`

export const currentStageDetailsQuery = gql`
	query CurrentStageDetails($id: ID!) {
		stage(id: $id) {
			id
			type
			possible_next_stages
			failure_tag
			failure_notes
		}
	}
`

const StyledButton = styled(Button)`
	border-radius: 17px;
	background: #64554b;
	padding: 0 20px;
	color: #fff;
	line-height: 1;
	height: 32px;
	font-weight: 400;
	span {
		font-weight: 700;
	}
	&:hover {
		background: #64554b;
		filter: brightness(0.9);
	}
`

/***
 *
 * Interface & Type
 *
 ***/
interface Props {
	leadId: string
	archivedLead?: boolean
	leadCurrentStageId: string
	onRefetch: () => Promise<void>
	personId: string
	txPlanId: string | null
}

/***
 *
 * Stage Input Component
 *
 ***/
const StageInput: FC<Props> = props => {
	const { leadId, archivedLead, leadCurrentStageId, onRefetch, personId, txPlanId } = props
	const toast = useToast()
	const user = useContext(AuthContext)

	const leadCurrentStageResult = useQuery<CurrentStageDetails, CurrentStageDetailsVariables>(
		currentStageDetailsQuery,
		{ variables: { id: leadCurrentStageId } }
	)

	const [createStage] = useMutation<CreateStage, CreateStageVariables>(createStageMutation, {
		onError: error => onError(error, toast),
		refetchQueries: ['PersonDetails', 'ActiveTXPlan', 'AllStages', 'RestrictedStages'],
	})

	const [, { data: allStageData, loading: loadingAllStages }] = useLazyQuery<
		AllStages,
		AllStagesVariables
	>(allStagesQuery, {
		variables: { where: { origin: leadId } },
	})

	const { onOpen, isOpen, onClose } = useDisclosure()
	const stageHistoryModal = useDisclosure()
	const archiveModal = useDisclosure()
	const [stageIsLoading, setStageIsLoading] = useState(false)

	const [viewStageInfo, setViewStageInfo] = useState<{
		id: string
		type: ENUM_STAGE_TYPE
	}>()
	const [transitionStageInfo, setTransitionStage] = useState<{ type: ENUM_STAGE_TYPE }>()

	const [stageEditMode, setStageEditMode] = useState<boolean>(false)

	useEffect(() => {
		if (!transitionStageInfo?.type) return
		const stageHandler = async () => {
			if (isLeadReadyToMove(transitionStageInfo?.type as string, txPlanId)) {
				setStageIsLoading(true)
				await onTransitionStage({}).finally(() => setStageIsLoading(false))
			} else {
				onOpen()
			}
		}
		stageHandler()
	}, [transitionStageInfo?.type])

	const stages = allStageData?.stages?.length
		? (allStageData?.stages?.filter(Boolean) as AllStages_stages[])
		: []

	const possibleNextStages = leadCurrentStageResult.data?.stage?.possible_next_stages?.filter(
		Boolean
	) as ENUM_STAGE_TYPE[]

	const stageType = leadCurrentStageResult?.data?.stage?.type as ENUM_STAGE_TYPE

	const { handleLeadUpdate } = useUpdateLead({
		refetchQueries: ['FindLead'],
		onSuccess: {
			message: archivedLead ? 'Lead has been unarchived' : 'Lead has been archived',
			handler: () => {
				setKanbanRefetchVal('true')
				archiveModal.onClose()
			},
		},
	})

	const handleNextStageClick = async (stageType: ENUM_STAGE_TYPE) => {
		if (!leadCurrentStageResult.data?.stage?.id) return

		setTransitionStage({ type: stageType })
		setViewStageInfo({
			id: leadCurrentStageResult.data.stage.id,
			type: leadCurrentStageResult.data.stage.type,
		})
	}

	const onTransitionStage: StageOnSubmit = async stageData => {
		if (!transitionStageInfo?.type) return {}

		setKanbanRefetchVal('true')

		const { data, errors } = await createStage({
			variables: {
				createStageInput: {
					data: {
						...stageData,
						type: transitionStageInfo.type,
						origin: leadId,
						owner: user.id,
					},
				},
			},
		})

		if (!errors) {
			onClose()
			toast({
				title: `Moved to ${StageStatusValues[transitionStageInfo.type]}.`,
				status: 'success',
				position: 'top-right',
			})

			await onRefetch()

			return { stageId: data?.createStage?.stage?.id }
		}

		return {}
	}

	const possibleStageOptions = possibleNextStages?.length ? (
		possibleNextStages.map((stage, index) => {
			return (
				<option key={index} value={stage}>
					{StageStatusValues[stage]}
				</option>
			)
		})
	) : (
		<option key='notValidOption' value='' disabled={true}>
			No possible next stage.
		</option>
	)

	const handleClickCurrentStage = () => {
		if (!leadCurrentStageResult.data?.stage?.id) return

		setStageEditMode(true)
		setViewStageInfo({
			id: leadCurrentStageResult.data.stage.id,
			type: stageType,
		})
		setTransitionStage(undefined)
		onOpen()
	}

	if (leadCurrentStageResult.loading || !leadCurrentStageResult.data?.stage?.id || stageIsLoading) {
		return (
			<Flex align='center' justify='space-between' width='100%'>
				<Skeleton height='2em' rounded='md' width='200px' />
				<Skeleton height='2em' rounded='md' width='200px' mt={0} />
			</Flex>
		)
	}

	return (
		<>
			<Flex align='center' justify='space-between' width='100%'>
				<Box position='relative' width='100%' display='flex'>
					<StyledButton onClick={handleClickCurrentStage}>
						Stage: <span>{StageStatusValues[stageType]}</span>
					</StyledButton>

					{[
						ENUM_STAGE_TYPE.consult_failed_to_book,
						ENUM_STAGE_TYPE.failed_to_convert,
						ENUM_STAGE_TYPE.TX_done,
					].includes(stageType) && (
						<>
							<Flex align='center' justify='space-between'>
								<Flex align='center' gap='0.5em' margin='0 10px'>
									<Tag size='sm' colorScheme={archivedLead ? 'red' : 'green'}>
										{archivedLead ? 'Archived' : 'Unarchived'}
									</Tag>
								</Flex>
								<Button colorScheme='green' size='sm' onClick={archiveModal.onOpen}>
									{archivedLead ? 'Unarchive' : 'Archive'}
								</Button>

								{archiveModal.isOpen && (
									<ConfirmPopup
										isOpen={archiveModal.isOpen}
										onClose={archiveModal.onClose}
										variant={archivedLead ? 'add' : 'delete'}
										title='Are you sure ?'
										message={archivedLead ? 'Lead will be unarchived' : 'Lead will be archived'}
										onConfirm={async () => {
											await handleLeadUpdate(leadId, { archive: !archivedLead })
										}}
									/>
								)}
							</Flex>
						</>
					)}
				</Box>

				<Box width='100%' justifyContent='flex-end' display='flex'>
					<Select
						placeholder='Update stage'
						bg='#64554B'
						width='200px'
						borderRadius='17px'
						onChange={e => handleNextStageClick(e.target.value as ENUM_STAGE_TYPE)}
						height='32px'
						border='none'
						color='#fff'
					>
						{possibleStageOptions}
					</Select>
				</Box>
			</Flex>

			{isOpen && viewStageInfo?.id && leadCurrentStageResult.data?.stage?.id && (
				<StageView
					leadId={leadId}
					txPlanId={txPlanId}
					personId={personId}
					editMode={stageEditMode}
					leadsCurrentStageInfo={{
						id: leadCurrentStageId,
						type: leadCurrentStageResult.data.stage.type,
					}}
					viewStageInfo={viewStageInfo}
					transitionStageInfo={transitionStageInfo}
					isOpen={isOpen}
					onClose={() => {
						setStageEditMode(false)
						onClose()
					}}
					onSubmit={onTransitionStage}
					onRefetch={onRefetch}
				/>
			)}
			<Modal
				isOpen={stageHistoryModal.isOpen}
				onClose={stageHistoryModal.onClose}
				size='xl'
				scrollBehavior='inside'
			>
				<ModalOverlay backdropBlur='xl' />
				<ModalContent as='form'>
					<ModalHeader>Stages History</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						{loadingAllStages ? (
							<Stack spacing={10} mt={6}>
								<Skeleton height='3em' rounded='md' />
								<Skeleton height='2em' rounded='md' />
								<Skeleton height='2em' rounded='md' />
							</Stack>
						) : (
							<Timeline
								stages={stages}
								onEditStage={(id, type) => {
									setStageEditMode(true)
									setViewStageInfo({ id, type })
									setTransitionStage(undefined)
									onOpen()
								}}
							/>
						)}
					</ModalBody>

					<ModalFooter>
						<Button onClick={stageHistoryModal.onClose}>Close</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}

export default StageInput
