import { useQuery } from '@apollo/client'
import {
	Avatar,
	Flex,
	FormControl,
	FormLabel,
	Skeleton,
	Stack,
	Text,
	useDisclosure,
} from '@chakra-ui/react'
import { BiEdit } from 'react-icons/bi'

import React, { FC } from 'react'

import { onError } from '~/components/helpers'
import { allTags } from '~/components/lead/LeadFormNew'
import { AllTags, AllTags_tags } from '~/components/lead/__generated__/AllTags'
import Button from '~/components/ui/Button'
import useToast from '~/components/ui/Toast'
import { FindLead_findLead_tags } from '~/views/__generated__/FindLead'
import TagsView from '~/views/tags'

/***
 *
 * Interface & Type
 *
 ***/
interface Props {
	stageView?: boolean
	onCreate?: (tags: string[]) => Promise<void>
	leadTags?: (FindLead_findLead_tags | null)[] | null
}

/***
 *
 * Assignees Input Component
 *
 ***/
const TagsInput: FC<Props> = props => {
	const { stageView, leadTags, onCreate } = props

	const { data: tagsData, loading: loadingTags } = useQuery<AllTags>(allTags, {
		onError: error => onError(error, toast),
	})

	const toast = useToast()
	const { isOpen, onOpen, onClose } = useDisclosure()

	if (loadingTags)
		return (
			<Stack spacing={10} w='full'>
				<Skeleton height='5em' rounded='md' />
			</Stack>
		)

	return (
		<>
			<FormControl isRequired={stageView}>
				<Flex align='center' justify='space-between'>
					<FormLabel
						fontSize='1em'
						color='#191C1A'
						fontWeight='bold'
						textTransform='uppercase'
						requiredIndicator={<span />}
						m={0}
					>
						Tags
					</FormLabel>
					<Button
						title={leadTags?.length ? 'Edit' : 'Add'}
						aria-label={leadTags?.length ? 'Edit' : 'Add'}
						colorScheme='transparent'
						p={0}
						size='sm'
						onClick={onOpen}
						leftIcon={<BiEdit color='#0000004d' fontSize={20} />}
					/>
				</Flex>

				{leadTags?.length ? (
					<Flex flexWrap='wrap' gap={5} mt='1em'>
						{leadTags.map(tag => (
							<Flex align='center' justify='start' key={tag?.id}>
								<Avatar size='sm' name={tag?.text} />
								<Text fontSize='1em' color='#64554B' ml={2}>
									{tag?.text}
								</Text>
							</Flex>
						))}
					</Flex>
				) : (
					<></>
				)}

				{!leadTags?.length && (
					<Text mt='1em' fontSize='0.9em' color='gray.400'>
						No tags added.
					</Text>
				)}
			</FormControl>

			{isOpen && (
				<TagsView
					isOpen={isOpen}
					onClose={onClose}
					leadTags={leadTags}
					onCreate={onCreate}
					tagsData={tagsData?.tags as AllTags_tags[]}
				/>
			)}
		</>
	)
}

export default TagsInput
