import { gql, useMutation, useQuery } from '@apollo/client'
import {
	Avatar,
	Flex,
	Spinner,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useDisclosure,
} from '@chakra-ui/react'
import moment from 'moment'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { FaLink } from 'react-icons/fa6'
import { FiCalendar, FiClock, FiEdit, FiMapPin } from 'react-icons/fi'

import React, { FC, useState } from 'react'

import { onError } from '~/components/helpers'
import CreateAppointmentForm from '~/components/lead/components/CreateAppointmentForm/CreateAppointmentForm'
import LeadApptsDentallyDataForm from '~/components/lead/components/LeadApptsDentallyDataForm'
import {
	GetAppointmentsData,
	GetAppointmentsData_getAppointmentsData_appointments,
	GetAppointmentsDataVariables,
} from '~/components/lead/components/__generated__/GetAppointmentsData'
import Button from '~/components/ui/Button'
import useToast from '~/components/ui/Toast'
import { useUpdateLead } from '~/crud/lead'
import { UpdateStage, UpdateStageVariables } from '~/inputs/__generated__/UpdateStage'
import { updateStageMutation } from '~/inputs/clinic'
import { FindLead_findLead } from '~/views/__generated__/FindLead'

interface LeadNotesProps {
	leadDetails?: FindLead_findLead
	active: boolean
}

export const getAppointmentsDataQuery = gql`
	query GetAppointmentsData($params: getAppointmentsParams!) {
		getAppointmentsData(params: $params) {
			appointments {
				id
				start_time
				duration
				reason
				state
				practitioner {
					id
					user {
						id
						first_name
						image_url
						last_name
						middle_name
						role
					}
				}
				site {
					id
					address_line_1
					address_line_2
					name
					nickname
					logo_url
					town
				}
				paymentPlan {
					id
					name
				}
			}
		}
	}
`

const LeadAppts: FC<LeadNotesProps> = props => {
	// const toast = useToast()
	const { isOpen, onOpen, onClose } = useDisclosure()
	const { leadDetails } = props
	const [isLoading, setIsLoading] = useState(false)
	const toast = useToast()

	const { data: appointmentsData, loading } = useQuery<
		GetAppointmentsData,
		GetAppointmentsDataVariables
	>(getAppointmentsDataQuery, {
		skip: !leadDetails?.person?.dentally_person_id,
		variables: { params: { patient_id: leadDetails?.person?.dentally_person_id } },
	})

	const [updateStage] = useMutation<UpdateStage, UpdateStageVariables>(updateStageMutation, {
		onError: error => onError(error, toast),
	})

	const { handleLeadUpdate } = useUpdateLead({
		refetchQueries: ['FindLead', 'GetAppointmentsData'],
		onSuccess: {
			message: 'Lead has been updated',
			handler: () => {
				setIsLoading(false)
			},
		},
	})

	const onEdit = () => {
		return window.open(
			`${process.env.GATSBY_DENTALLY_URL}/patients/${leadDetails?.person?.dentally_uuid}/appointments`,
			'_blank'
		)
	}

	const renderLoader = () => {
		if (loading || isLoading) {
			return (
				<Flex
					justify='center'
					gap='1em'
					align='center'
					p='1em'
					sx={{
						position: 'absolute',
						minHeight: '200px',
						width: '100%',
						zIndex: 10,
						height: '100%',
						backgroundColor: 'rgba(255, 255, 255, 0.7)',
						top: '0',
						left: '0',
					}}
				>
					<Spinner />
					<Text color='gray.500' fontSize='sm'>
						Loading
					</Text>
				</Flex>
			)
		}
		return null
	}

	const renderDentallyRequestButton = () => {
		if (!loading && !leadDetails?.person?.dentally_person_id) {
			return <LeadApptsDentallyDataForm leadDetails={leadDetails} />
		}
		return null
	}

	const renderLinkedInfo = (appointment: GetAppointmentsData_getAppointmentsData_appointments) => {
		if (
			leadDetails?.dentally_appointment_id &&
			leadDetails?.dentally_appointment_id === appointment.id
		) {
			return (
				<Text fontSize='1em' color='green.500' fontWeight='600'>
					Linked
				</Text>
			)
		}
		if (leadDetails) {
			return (
				<Button
					onClick={async () => {
						console.log(appointmentsData)
						setIsLoading(true)
						await handleLeadUpdate(leadDetails.id, {
							dentally_appointment_id: appointment.id,
							dentally_appointment_state: appointment.state,
							dentally_appointment_start_time: appointment.start_time,
						})
						if (leadDetails?.current_stage?.id) {
							await updateStage({
								variables: {
									stageData: {
										where: { id: leadDetails?.current_stage?.id },
										data: {
											dentally_appointment_state: appointment.state,
											dentally_appointment_start_time: appointment.start_time,
										},
									},
								},
							})
						}
					}}
					sx={{
						background: 'transparent',
					}}
				>
					<Flex sx={{ width: '20px' }}>
						<FaLink color='#64554B' fontSize={19} />
					</Flex>
				</Button>
			)
		}
		return null
	}

	const renderTable = () => {
		if (!loading && leadDetails?.person?.dentally_person_id) {
			return (
				<TableContainer>
					<Table variant='simple'>
						<Thead
							sx={{
								background: '#F2F2F2',
							}}
						>
							<Tr>
								<Th>Name</Th>
								<Th>Date</Th>
								<Th>Time</Th>
								<Th>Address</Th>
								<Th></Th>
							</Tr>
						</Thead>
						<Tbody>
							{appointmentsData?.getAppointmentsData.appointments.map(appointment => {
								const practitionerName =
									appointment.practitioner.user?.first_name +
									' ' +
									appointment.practitioner.user?.last_name
								return (
									<Tr key={appointment.id}>
										<Td>
											<Flex flexWrap='wrap' gap={5}>
												<Flex align='center' justify='start'>
													<Avatar
														size='sm'
														name={practitionerName}
														src={
															appointment.practitioner.user?.image_url
																? appointment.practitioner.user?.image_url
																: undefined
														}
													/>
													<Flex direction='column' ml={6}>
														<Text fontSize='1em' color='#474747' fontWeight='700'>
															{practitionerName}
														</Text>
														<Text fontSize='0.8em' color='#B9B9B9' fontWeight='600'>
															{appointment.practitioner.user?.role}
														</Text>
													</Flex>
												</Flex>
											</Flex>
										</Td>
										<Td>
											{appointment.start_time && (
												<Flex direction='row'>
													<Flex sx={{ width: '20px' }}>
														<FiCalendar color='#64554B' fontSize={23} />
													</Flex>
													<Text fontSize='0.9em' color='#474747' fontWeight='700' marginLeft={2}>
														{moment(appointment.start_time).format('DD MMM, YYYY')}
													</Text>
												</Flex>
											)}
										</Td>
										<Td>
											<Flex direction='row' alignItems='center'>
												<Flex sx={{ width: '20px' }}>
													<FiClock color='#64554B' fontSize={23} />
												</Flex>
												<Flex direction='column' ml={2}>
													<Text fontSize='1em' color='#474747' fontWeight='700' lineHeight='1'>
														{moment(appointment.start_time).format('HH:mm')}
													</Text>
													<Text fontSize='0.8em' color='#B9B9B9' fontWeight='600'>
														{appointment.reason}({appointment.duration})
													</Text>
												</Flex>
											</Flex>
										</Td>
										<Td>
											<Flex direction='row' alignItems='center'>
												<Flex sx={{ width: '20px' }}>
													<FiMapPin color='#64554B' fontSize={23} />
												</Flex>
												<Flex direction='column' ml={2}>
													<Text fontSize='1em' color='#474747' fontWeight='700' lineHeight='1'>
														{appointment.site.name}
													</Text>
													<Text fontSize='0.8em' color='#B9B9B9' fontWeight='600'>
														{appointment.site.address_line_2}
													</Text>
												</Flex>
											</Flex>
										</Td>
										<Td>
											<Flex direction='row' alignItems='center'>
												{renderLinkedInfo(appointment)}
												<Button
													onClick={onEdit}
													sx={{
														background: 'transparent',
													}}
												>
													<Flex sx={{ width: '20px' }}>
														<FiEdit color='#64554B' fontSize={19} />
													</Flex>
												</Button>
											</Flex>
										</Td>
									</Tr>
								)
							})}
						</Tbody>
					</Table>
				</TableContainer>
			)
		}
		return null
	}

	return (
		<Flex direction='column'>
			<Flex
				position='relative'
				direction='row'
				width='100%'
				mb='10px'
				justifyContent='space-between'
				alignItems='center'
			>
				<Flex position='relative' direction='column' width='100%'>
					<Text color='#191C1A' fontSize='1.8em' fontWeight={700} casing='capitalize'>
						Appointments
					</Text>
					<Text color='#6B6B6B' fontSize='1em' fontWeight={400}>
						There is the latest update for the last 7 days. Check now
					</Text>
				</Flex>
				<Button
					size='sm'
					onClick={onOpen}
					isDisabled={!leadDetails?.person?.dentally_person_id}
					sx={{
						width: '245px',
						height: '45px',
						background: '#2D3748',
						borderRadius: '6px',
						color: '#fff',
						fontSize: '1em',
						fontWeight: '600',
						_hover: {
							background: '#2D3748',
							opacity: '0.8',
						},
					}}
					leftIcon={<AiOutlinePlusCircle color='#EEAB7E' fontSize={20} />}
				>
					New Appointment
				</Button>
			</Flex>
			<Flex direction='column' mt={4} position='relative'>
				{renderLoader()}
				{renderTable()}
				{renderDentallyRequestButton()}
			</Flex>
			{isOpen && (
				<CreateAppointmentForm
					isOpen={isOpen}
					onClose={onClose}
					dentallyPatientId={leadDetails?.person?.dentally_person_id}
				/>
			)}
		</Flex>
	)
}

export default LeadAppts
