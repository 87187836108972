import {
	Box,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Button,
	Text,
	useDisclosure,
} from '@chakra-ui/react'
import { FaPlus } from 'react-icons/fa'

import React from 'react'

import CreatePatientFormWrapper from '~/components/forms/CreatePatientDentally/CreatePatientFormWrapper'

const CreatePatientDrawer: React.FC = () => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	return (
		<>
			<Box w='10em' ml='1.5em'>
				<Button
					leftIcon={<FaPlus />}
					sx={{
						width: '40px',
						padding: 0,
						span: {
							width: '100%',
							margin: 0,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						},
					}}
					size='md'
					color='white'
					bg='primary'
					_hover={{ bg: 'primary' }}
					onClick={onOpen}
				/>
			</Box>
			<Drawer placement='right' onClose={onClose} isOpen={isOpen} size='lg'>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerHeader
						sx={{
							background: 'linear-gradient(90deg, #C29265 0%, #B68150 100%)',
							padding: '20px 40px',
							color: '#fff',
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<Text sx={{ marginRight: '10px' }}>Create Patient</Text>
					</DrawerHeader>
					<DrawerBody sx={{ padding: 0 }}>
						<CreatePatientFormWrapper onClose={onClose} />
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</>
	)
}

export default CreatePatientDrawer
