import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	ModalFooter,
	Button,
	FormControl,
	FormLabel,
	Box,
	Tag,
} from '@chakra-ui/react'
import { MultiValue } from 'react-select'

import { FC, useState } from 'react'

import { AllTags_tags } from '~/components/lead/__generated__/AllTags'
import SelectDropdown from '~/components/ui/SelectDropdown'
import useToast from '~/components/ui/Toast'
import { setKanbanRefetchVal } from '~/helpers'
import { FindLead_findLead_tags } from '~/views/__generated__/FindLead'

/***
 *
 * Interface & Type
 *
 ***/
interface Props {
	isOpen: boolean
	onClose: () => void
	leadTags?: (FindLead_findLead_tags | null)[] | null
	tagsData: AllTags_tags[]
	onCreate?: (tags: string[]) => Promise<void>
}

/***
 *
 * Assignees Input Component
 *
 ***/
const TagsView: FC<Props> = props => {
	const { isOpen, onClose, leadTags, tagsData, onCreate } = props
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const toast = useToast()

	const [tags, setTags] = useState<string[]>(leadTags?.length ? leadTags.map(t => t!.id) : [])
	const [submitAttempted, setSubmitAttempted] = useState(false)

	const submitHandler = async () => {
		setSubmitAttempted(true)
		setKanbanRefetchVal('true')

		if (!tags.length) {
			toast({
				title: 'At least 1 tag is required.',
				status: 'error',
				position: 'top-right',
			})
			return
		}

		const selectedTags = tagsData.filter(item => tags.includes(item?.id))
		if (onCreate) {
			setIsLoading(true)
			await onCreate(selectedTags.map(t => t?.id)).finally(() => setIsLoading(false))
		}

		toast({
			title: 'Tags added.',
			status: 'success',
			position: 'top-right',
		})

		onClose()
	}

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay backdropBlur='xl' />
				<ModalContent as='form'>
					<ModalHeader>Select</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<FormControl isRequired>
							<FormLabel color='gray.600' fontWeight='bold'>
								Tags
							</FormLabel>
							<Box>
								<SelectDropdown
									error={!tags.length && submitAttempted}
									variant='small'
									isMulti
									isDisabled={isLoading}
									options={tagsData}
									placeholder='Choose Tags'
									getOptionLabel={opt => opt.text}
									getOptionValue={opt => opt.id}
									getOptionTag={opt => (
										<Tag colorScheme='green' size='sm'>
											{opt?.text}
										</Tag>
									)}
									defaultValue={
										tagsData.filter(opt => {
											const ids = tags?.map(v => v) ?? []
											return ids.includes(opt.id)
										}) as AllTags_tags[]
									}
									onChange={value => {
										const selectedValues = value as MultiValue<AllTags_tags>
										const ids = selectedValues.map(item => item.id)

										setTags(ids)
									}}
								/>
							</Box>
						</FormControl>
					</ModalBody>
					<ModalFooter mt={2}>
						<Button onClick={onClose} mr={3} isDisabled={isLoading}>
							Cancel
						</Button>
						<Button
							colorScheme='green'
							type='button'
							isDisabled={isLoading}
							isLoading={isLoading}
							onClick={submitHandler}
						>
							Save
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}

export default TagsView
