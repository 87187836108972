import { gql, useMutation, useLazyQuery } from '@apollo/client'
import { Flex, useDisclosure } from '@chakra-ui/react'
import { DragDropContext, DragStart, DropResult } from 'react-beautiful-dnd'

import { FC, useState, useContext, useRef, useEffect } from 'react'

import { StageStatusValues } from '~/constants'
import { useSocketEvents } from '~/context/SocketProvider'
import { getKanbanRefetchVal, setKanbanRefetchVal } from '~/helpers'
import { CreateStage, CreateStageVariables } from '~/views/__generated__/CreateStage'
import { createStageMutation } from '~/views/addLead'
import StageView, { StageOnSubmit } from '~/views/stage'

import { ENUM_STAGE_TYPE } from '../../../__generated__/globalTypes'
import { onError, useAsyncEffect } from '../helpers'
import { AuthContext } from '../page/context'
import useToast from '../ui/Toast'
import Column from './Column'
import Filters from './Filters'
import StatusConfirmDialog from './StatusConfirmDialog'
import {
	DraggedLeadDetails,
	DraggedLeadDetailsVariables,
	DraggedLeadDetails_lead,
} from './__generated__/DraggedLeadDetails'
import {
	receptionColumnStatusTypes,
	tcoColumnStatusTypes,
	generateColumns,
	LeadTeam,
	FiltersType,
	getLocalStorageFilters,
	ColumnRefType,
	octColumnStatusTypes,
	isLeadReadyToMove,
} from './helpers'

const draggedLeadQuery = gql`
	query DraggedLeadDetails($id: ID!) {
		lead(id: $id) {
			id
			current_stage {
				id
				type
				possible_next_stages
			}
			person {
				id
			}
			consented {
				origin
				consented
			}
			treatment_plan_id
		}
	}
`

export const clinicsListQuery = gql`
	query ClinicsList {
		clinics {
			id
			name
			dentally_site_id
		}
	}
`

/***
 *
 * Interface & Type
 *
 ***/
interface Props {
	leadTeam?: LeadTeam
	searchQuery?: string
}

/***
 *
 * Kanban Board Component
 *
 ***/
const KanbanBoard: FC<Props> = props => {
	const { leadTeam, searchQuery } = props
	const [stageIsLoading, setStageIsLoading] = useState(false)
	const toast = useToast()
	const user = useContext(AuthContext)
	const { activeEvents, markEventDone } = useSocketEvents()

	const statusConfirmModal = useDisclosure()
	const stageViewModal = useDisclosure()
	const columnRef = useRef<ColumnRefType>(null)

	const [filterValue, setFilterValue] = useState(() => getLocalStorageFilters(leadTeam))

	const [fetchDraggedLeadDetails, draggedLeadQueryResult] = useLazyQuery<
		DraggedLeadDetails,
		DraggedLeadDetailsVariables
	>(draggedLeadQuery)

	const [viewStageInfo, setViewStageInfo] = useState<{
		id: string
		type: ENUM_STAGE_TYPE
	}>()
	const [transitionStageInfo, setTransitionStage] = useState<{ type: ENUM_STAGE_TYPE }>()

	const [draggedLead, setDraggedLead] = useState<DraggedLeadDetails_lead>()
	const [refreshingLeads, setRefreshingLeads] = useState(false)
	const [possibleNextStages, setPossibleNStages] = useState<ENUM_STAGE_TYPE[]>([])

	const [createStage] = useMutation<CreateStage, CreateStageVariables>(createStageMutation, {
		onError: error => onError(error, toast),
	})

	useEffect(() => {
		if (!transitionStageInfo?.type) return
		const stageHandler = async () => {
			if (
				isLeadReadyToMove(
					transitionStageInfo?.type as string,
					draggedLead?.treatment_plan_id as string
				)
			) {
				setStageIsLoading(true)
				await onTransitionStage({}).finally(() => setStageIsLoading(false))
			} else {
				stageViewModal.onOpen()
			}
		}
		stageHandler()
	}, [transitionStageInfo?.type])

	const columnsList = generateColumns(leadTeam)

	const handleStageSelect = (stageType: ENUM_STAGE_TYPE) => {
		if (!draggedLead?.current_stage?.id) return

		setViewStageInfo({
			id: draggedLead.current_stage.id,
			type: draggedLead.current_stage?.type,
		})
		setTransitionStage({ type: stageType })
		stageViewModal.onOpen()
	}

	const refetchColumns = async () => {
		if (!columnRef.current) return

		const allRefetchFunctions = Object.values(columnRef.current)

		setRefreshingLeads(true)
		await Promise.all(allRefetchFunctions.map(colRefetchFunc => colRefetchFunc()))
		setRefreshingLeads(false)
	}

	const onTransitionStage: StageOnSubmit = async stageData => {
		if (!transitionStageInfo?.type) return {}

		console.log({
			data: {
				...stageData,
				type: transitionStageInfo.type,
				origin: draggedLead?.id as string,
				owner: user.id,
			},
		})

		const { data, errors } = await createStage({
			variables: {
				createStageInput: {
					data: {
						...stageData,
						type: transitionStageInfo.type,
						origin: draggedLead?.id as string,
						owner: user.id,
					},
				},
			},
		})

		if (!errors) {
			statusConfirmModal.onClose()
			stageViewModal.onClose()

			await refetchColumns()
			toast({
				title: `Moved to ${StageStatusValues[transitionStageInfo.type]}.`,
				status: 'success',
				position: 'top-right',
			})

			return { stageId: data?.createStage?.stage?.id }
		}

		return {}
	}

	const onDragStartHandler = async (result: DragStart) => {
		await fetchDraggedLeadDetails({ variables: { id: result.draggableId } })
	}

	const onDragEndHandler = (result: DropResult) => {
		if (!result.destination?.droppableId) return

		const draggedLead = draggedLeadQueryResult.data?.lead

		if (!draggedLead?.current_stage?.id) return
		let dropColumnStatuses: ENUM_STAGE_TYPE[] = []
		switch (leadTeam) {
			case 'reception':
				dropColumnStatuses = receptionColumnStatusTypes[result.destination?.droppableId]
				break
			case 'tco':
				dropColumnStatuses = tcoColumnStatusTypes[result.destination?.droppableId]
				break
			case 'oct':
				dropColumnStatuses = octColumnStatusTypes[result.destination?.droppableId]
				break
			default:
				break
		}

		const isDropAllowed = !!dropColumnStatuses.find(status =>
			draggedLead?.current_stage?.possible_next_stages.includes(status)
		)

		if (!isDropAllowed) {
			toast({
				title: `Lead cannot be moved to any status in this column.`,
				status: 'error',
				position: 'top-right',
			})

			return
		}

		const possibleNextStages =
			(draggedLead?.current_stage?.possible_next_stages
				.filter(Boolean)
				.filter(stage => stage && dropColumnStatuses.includes(stage)) as ENUM_STAGE_TYPE[]) ?? []

		setDraggedLead(draggedLead)
		setPossibleNStages(possibleNextStages)

		if (possibleNextStages.length !== 1) {
			statusConfirmModal.onOpen()
			return
		}

		setViewStageInfo({
			id: draggedLead.current_stage.id,
			type: draggedLead.current_stage?.type,
		})

		setTransitionStage({ type: possibleNextStages[0] })
	}

	const handleFilterChange = (args: { sort?: string | null; filters?: FiltersType }) => {
		const sortValue = args.sort === null ? null : args.sort ?? filterValue?.sort

		const finalFilters: { [index: string]: string | string[] | undefined | null | FiltersType } = {
			sort: sortValue,
			filters: { ...filterValue.filters, ...args.filters },
		}

		!finalFilters.sort && delete finalFilters.sort

		localStorage.setItem(`${leadTeam}_filters`, JSON.stringify(finalFilters))
		setFilterValue(finalFilters)
	}

	const onRefresh = async () => {
		await refetchColumns()
		toast({
			title: 'New info loaded',
			status: 'success',
			position: 'top-right',
		})
		markEventDone('new_info')
	}

	useAsyncEffect(async () => {
		const refetch = getKanbanRefetchVal(leadTeam)

		if (refetch === 'true') {
			await refetchColumns()
			setKanbanRefetchVal('false', leadTeam)
		}
	}, [])

	return (
		<Flex direction='column' h='full'>
			<Flex gap='1em' align='center' my='1.5em'>
				<Filters
					isDisabled={false}
					filterValue={filterValue}
					onChange={handleFilterChange}
					activeEvents={activeEvents}
					refreshingLeads={refreshingLeads}
					onRefresh={onRefresh}
				/>
			</Flex>

			<DragDropContext onDragStart={onDragStartHandler} onDragEnd={onDragEndHandler}>
				<Flex w='full' flexGrow={1} gap='1em' overflowX='auto'>
					{columnsList.map(column => (
						<Column
							stageIsLoading={stageIsLoading}
							ref={columnRef}
							key={column.columnId}
							filterValue={filterValue}
							searchQuery={searchQuery}
							{...column}
						/>
					))}
				</Flex>
			</DragDropContext>

			{statusConfirmModal.isOpen && draggedLead?.id && (
				<StatusConfirmDialog
					isLoading={draggedLeadQueryResult.loading}
					leadId={draggedLead.id}
					isOpen={statusConfirmModal.isOpen}
					onClose={statusConfirmModal.onClose}
					possibleNextStages={possibleNextStages}
					onSelect={handleStageSelect}
				/>
			)}

			{stageViewModal.isOpen &&
				viewStageInfo?.id &&
				draggedLead?.id &&
				draggedLead.current_stage?.id &&
				draggedLead?.person?.id && (
					<StageView
						leadId={draggedLead.id}
						txPlanId={draggedLead.treatment_plan_id}
						consented={draggedLead.consented?.consented || false}
						personId={draggedLead.person.id}
						editMode={false}
						leadsCurrentStageInfo={{
							id: draggedLead.current_stage?.id,
							type: draggedLead.current_stage?.type,
						}}
						viewStageInfo={viewStageInfo}
						transitionStageInfo={transitionStageInfo}
						isOpen={stageViewModal.isOpen}
						onClose={stageViewModal.onClose}
						onSubmit={onTransitionStage}
					/>
				)}
		</Flex>
	)
}

export default KanbanBoard
