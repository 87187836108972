import { Flex, Heading, Spinner, Text } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { FaWhatsapp } from 'react-icons/fa'
import { FiPhone } from 'react-icons/fi'
import { TfiEmail } from 'react-icons/tfi'

import { FC, useState } from 'react'

import { Attempts_attempts } from '~/inputs/__generated__/Attempts'

import { ENUM_ATTEMPT_TEAM, ENUM_ATTEMPT_TYPE } from '../../__generated__/globalTypes'

/***
 *
 * Interface & Type
 *
 ***/
interface Props {
	team: ENUM_ATTEMPT_TEAM
	attempts?: Attempts_attempts[] | null
	onConfirm: (attemptConfig: AttemptConfigState) => Promise<void>
	leadId: string
}

type AttemptGraph = {
	[index in ENUM_ATTEMPT_TEAM]: {
		call: Attempts_attempts[]
		whatsapp: Attempts_attempts[]
		other: Attempts_attempts[]
	}
}

export type AttemptConfigState = {
	id?: string
	lead_id: string
	type: ENUM_ATTEMPT_TYPE
	team: ENUM_ATTEMPT_TEAM
	create: boolean
}

const StyledIconButton = styled.button`
	background-color: transparent;
	border: none;
	cursor: pointer;
	margin: 0.5em 1em 0.5em 0;
	svg {
		width: 20px;
		height: 20px;
	}
`

const StyledAttemptsGroup = styled(Flex)`
	flex-direction: column;
	position: relative;
	width: 100%;
	border: 1px solid rgba(100, 85, 75, 0.2);
	border-radius: 8px;
	padding: 18px;
	&:first-of-type {
		margin-right: 10px;
	}
	&:last-of-type {
		margin-left: 10px;
	}
`

/***
 *
 * Attempt  Group Component
 *
 ***/
const AttemptsGroup: FC<Props> = props => {
	const { team, attempts, onConfirm, leadId } = props
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const intialGraph: AttemptGraph = {
		tco: {
			call: [],
			whatsapp: [],
			other: [],
		},
		reception: {
			call: [],
			whatsapp: [],
			other: [],
		},
	}

	const attemptGraph = attempts?.reduce((prevVal, attempt) => {
		if (!prevVal[attempt.team]) return prevVal

		prevVal[attempt.team][attempt.type].push(attempt)

		return prevVal
	}, intialGraph)

	const iconClickHandler = async (attemptConfig: AttemptConfigState) => {
		setIsLoading(true)
		await onConfirm(attemptConfig).finally(() => setIsLoading(false))
	}

	return (
		<>
			<StyledAttemptsGroup>
				{isLoading && (
					<Flex
						justify='center'
						gap='1em'
						p='1em'
						sx={{
							position: 'absolute',
							top: 0,
							left: 0,
							width: '100%',
							height: '100%',
							backgroundColor: 'rgba(255,255,255,0.5)',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<Spinner />
						<Text color='gray.500' fontSize='sm'>
							Loading
						</Text>
					</Flex>
				)}
				<Heading
					alignSelf='start'
					fontSize='sm'
					color='#64554B'
					fontWeight={400}
					textTransform='capitalize'
					marginBottom='7px'
				>
					{team}
				</Heading>

				<AttemptIcons
					attemptTeam={team}
					leadId={leadId}
					attemptType={ENUM_ATTEMPT_TYPE.call}
					attemptGraph={attemptGraph}
					onClick={iconClickHandler}
				/>

				<AttemptIcons
					attemptTeam={team}
					leadId={leadId}
					attemptType={ENUM_ATTEMPT_TYPE.other}
					attemptGraph={attemptGraph}
					onClick={iconClickHandler}
				/>

				<AttemptIcons
					attemptTeam={team}
					leadId={leadId}
					attemptType={ENUM_ATTEMPT_TYPE.whatsapp}
					attemptGraph={attemptGraph}
					onClick={iconClickHandler}
				/>
			</StyledAttemptsGroup>
		</>
	)
}

/***
 *
 * Attempt  Icons Component
 *
 ***/
interface AttemptIconProps {
	attemptTeam: ENUM_ATTEMPT_TEAM
	attemptType: ENUM_ATTEMPT_TYPE
	attemptGraph?: AttemptGraph
	leadId: string
	onClick: (attemptConfig: AttemptConfigState) => void
}

const AttemptIcons: FC<AttemptIconProps> = props => {
	const { attemptTeam, attemptGraph, attemptType, onClick, leadId } = props
	const iconList = Array.from(Array(attemptTeam === ENUM_ATTEMPT_TEAM.tco ? 4 : 4).keys())

	const getIcon = (color: string) => {
		switch (attemptType) {
			case ENUM_ATTEMPT_TYPE.call:
				return <FiPhone stroke={color} />
			case ENUM_ATTEMPT_TYPE.whatsapp:
				return <FaWhatsapp fill={color} />
			case ENUM_ATTEMPT_TYPE.other:
			default:
				return <TfiEmail fill={color} />
		}
	}

	return (
		<Flex gap={3}>
			{iconList.map((icon, index) => {
				const currentAttempt = attemptGraph?.[attemptTeam]?.[attemptType]?.[index]
				const hasAttempted = Boolean(currentAttempt?.id)

				const handleClick = () => {
					const attemptConfig = {
						...(hasAttempted && { id: currentAttempt?.id }),
						create: !hasAttempted,
						lead_id: leadId,
						type: ENUM_ATTEMPT_TYPE[attemptType],
						team: attemptTeam,
					}

					onClick(attemptConfig)
				}

				return (
					<StyledIconButton onClick={handleClick} key={icon}>
						{getIcon(hasAttempted ? '#EEAB7E' : '#938881')}
					</StyledIconButton>
				)
			})}
		</Flex>
	)
}

export default AttemptsGroup
