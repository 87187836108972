/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ENUM_ATTEMPT_TEAM {
  reception = "reception",
  tco = "tco",
}

export enum ENUM_ATTEMPT_TYPE {
  call = "call",
  other = "other",
  whatsapp = "whatsapp",
}

export enum ENUM_AUDIT_ACTION {
  create = "create",
  delete = "delete",
  update = "update",
}

export enum ENUM_AUDIT_ENTITY {
  assignees = "assignees",
  attempt = "attempt",
  message = "message",
  note = "note",
  stage = "stage",
  transaction = "transaction",
}

export enum ENUM_LEAD_PREFERRED_CONTACT_METHOD {
  email = "email",
  facebook_dm = "facebook_dm",
  instagram = "instagram",
  phone_call = "phone_call",
  whatsapp = "whatsapp",
}

export enum ENUM_NOTE_TYPE {
  automated = "automated",
  reception = "reception",
  tco = "tco",
}

export enum ENUM_STAGE_FAILURE_TAG {
  duplicate = "duplicate",
  failed_to_contact_us = "failed_to_contact_us",
  fta = "fta",
  gone_elsewhere = "gone_elsewhere",
  not_interested = "not_interested",
  other = "other",
  patient_cancelled_appointment = "patient_cancelled_appointment",
  shopping_around = "shopping_around",
  too_expensive = "too_expensive",
}

export enum ENUM_STAGE_TYPE {
  TX_done = "TX_done",
  TX_given = "TX_given",
  TX_incomplete = "TX_incomplete",
  TX_updated = "TX_updated",
  appointment_attended = "appointment_attended",
  appointment_cancelled = "appointment_cancelled",
  appointment_failed_to_attend = "appointment_failed_to_attend",
  appointment_pending = "appointment_pending",
  cancelled = "cancelled",
  consult_attended = "consult_attended",
  consult_booked = "consult_booked",
  consult_failed_to_attend = "consult_failed_to_attend",
  consult_failed_to_book = "consult_failed_to_book",
  contacted = "contacted",
  created = "created",
  failed_to_convert = "failed_to_convert",
  oct_appointment_booked = "oct_appointment_booked",
  oct_appointment_cancelled = "oct_appointment_cancelled",
  oct_appointment_failed_to_attend = "oct_appointment_failed_to_attend",
  treatment_booked = "treatment_booked",
}

export enum ENUM_TRANSACTION_MEDIUM {
  bank = "bank",
  card = "card",
  cash = "cash",
  go_cardless = "go_cardless",
  pay_as_you_go = "pay_as_you_go",
  tabeo = "tabeo",
}

export enum ENUM_TRANSACTION_TYPE {
  deposit = "deposit",
  payment = "payment",
  refund = "refund",
  top_up = "top_up",
}

export interface AlarmInput {
  due_date?: any | null;
  note?: string | null;
  origin?: string | null;
  due_time?: any | null;
  lead_id?: string | null;
  published_at?: any | null;
  created_by?: string | null;
  updated_by?: string | null;
}

export interface AttemptInput {
  type: ENUM_ATTEMPT_TYPE;
  team: ENUM_ATTEMPT_TEAM;
  origin?: string | null;
  note?: string | null;
  owner?: string | null;
  lead_id?: string | null;
  published_at?: any | null;
  created_by?: string | null;
  updated_by?: string | null;
}

export interface DocumentInput {
  link: string;
  owner?: string | null;
  origin?: string | null;
  name?: string | null;
  published_at?: any | null;
  created_by?: string | null;
  updated_by?: string | null;
}

export interface EmailReceiverInput {
  to: string;
  name: string;
}

export interface InputID {
  id: string;
}

export interface InputWhere {
  id: string;
  leadId: string;
  owner: string;
}

export interface LeadInput {
  notes?: (string | null)[] | null;
  tags?: (string | null)[] | null;
  person?: string | null;
  stages_history?: (string | null)[] | null;
  preferred_contact_method?: ENUM_LEAD_PREFERRED_CONTACT_METHOD | null;
  preferred_contact_time?: string | null;
  preferred_contact_day?: string | null;
  unread_message_id?: string | null;
  has_unread_message?: boolean | null;
  sources?: (string | null)[] | null;
  archive?: boolean | null;
  legacy?: boolean | null;
  stage_type?: string | null;
  owner?: string | null;
  is_external?: boolean | null;
  reapplication?: boolean | null;
  closed?: boolean | null;
  booked_online?: boolean | null;
  treatment_plan_id?: string | null;
  treatment_plan_price?: number | null;
  practitioner_id?: any | null;
  deposit_paid?: boolean | null;
  payment_taken?: boolean | null;
  waiting_for_clin_check?: boolean | null;
  finance_application?: boolean | null;
  converted?: boolean | null;
  clinic?: string | null;
  dentally_appointment_id?: any | null;
  dentally_practitioner_name?: string | null;
  dentally_referrer_name?: string | null;
  dentally_appointment_state?: string | null;
  dentally_appointment_start_time?: any | null;
  tco_assignee?: string | null;
  dentist_assignee?: string | null;
  cst_assignee?: string | null;
  published_at?: any | null;
  created_by?: string | null;
  updated_by?: string | null;
}

export interface LeadsAnalyticsFilters {
  inquiries?: (string | null)[] | null;
  canceled?: boolean | null;
}

export interface NoteInput {
  text: string;
  type?: ENUM_NOTE_TYPE | null;
  owner?: string | null;
  origin?: string | null;
  stage?: string | null;
  published_at?: any | null;
  created_by?: string | null;
  updated_by?: string | null;
}

export interface PersonInput {
  phone: string;
  email?: string | null;
  first_name: string;
  last_name?: string | null;
  middle_name?: string | null;
  transaction_history?: (string | null)[] | null;
  photos?: (string | null)[] | null;
  dentally_uuid?: string | null;
  dentally_person_id?: string | null;
  dentally_creator_id?: string | null;
  twilio_conversation_sid?: string | null;
  dream_outcome?: string | null;
  treatment_reason?: string | null;
  comes_from?: string | null;
  wizard_step?: any | null;
  created_by?: string | null;
  updated_by?: string | null;
}

export interface SourceInput {
  medium?: string | null;
  source?: string | null;
  campaign?: string | null;
  other_utm?: string | null;
  origin?: string | null;
  created_by?: string | null;
  updated_by?: string | null;
}

export interface StageInput {
  owner?: string | null;
  assignees?: (string | null)[] | null;
  type: ENUM_STAGE_TYPE;
  tx_plan?: string | null;
  payment?: string | null;
  contact_attempts?: (string | null)[] | null;
  alarm?: string | null;
  origin?: string | null;
  inquiries?: (string | null)[] | null;
  consented?: boolean | null;
  failure_notes?: string | null;
  failure_tag?: ENUM_STAGE_FAILURE_TAG | null;
  callback_date?: any | null;
  callback_time?: any | null;
  note?: string | null;
  clinic?: string | null;
  dentally_appointment_state?: string | null;
  dentally_appointment_start_time?: any | null;
  dentally_first_appointment_completed?: boolean | null;
  created_by?: string | null;
  updated_by?: string | null;
}

export interface TransactionInput {
  amount: number;
  type: ENUM_TRANSACTION_TYPE;
  medium?: ENUM_TRANSACTION_MEDIUM | null;
  note?: string | null;
  owner?: string | null;
  origin?: string | null;
  linked_stage?: string | null;
  linked_tx_plan?: string | null;
  published_at?: any | null;
  created_by?: string | null;
  updated_by?: string | null;
  leadId: string;
}

export interface TxAppointmentTimeInput {
  name: string;
  date: any;
  origin?: string | null;
  published_at?: any | null;
  created_by?: string | null;
  updated_by?: string | null;
}

export interface TxPlanInput {
  origin?: string | null;
  tx_plan?: string | null;
  appointment_times?: (string | null)[] | null;
  cost: number;
  type?: string | null;
  created_by?: string | null;
  updated_by?: string | null;
}

export interface UsersPermissionsLoginInput {
  identifier: string;
  password: string;
  provider?: string | null;
}

export interface UsersPermissionsRegisterInput {
  username: string;
  email: string;
  password: string;
}

export interface chatTemplatesInput {
  limit: number;
}

export interface createAlarmInput {
  data?: AlarmInput | null;
}

export interface createAttemptInput {
  data?: AttemptInput | null;
}

export interface createDentallyAppointmentFields {
  start_time: any;
  finish_time: any;
  practitioner_id: number;
  reason: string;
  patient_id: number;
  state?: string | null;
  notes?: string | null;
  force_changes?: boolean | null;
}

export interface createDocumentInput {
  data?: DocumentInput | null;
}

export interface createLeadInput {
  data?: LeadInput | null;
}

export interface createMultiplePractitionerInput {
  lead_id: string;
  practitioner_id?: number | null;
  referrer_id?: number | null;
  practitioner_name?: string | null;
  referrer_name?: string | null;
  current?: boolean | null;
}

export interface createMultiplePractitionersInput {
  practitioners?: (createMultiplePractitionerInput | null)[] | null;
}

export interface createNoteInput {
  data?: NoteInput | null;
}

export interface createPatientFields {
  title: string;
  first_name: string;
  last_name: string;
  date_of_birth: any;
  gender: boolean;
  ethnicity: string;
  address_line_1: string;
  postcode: string;
  payment_plan_id: number;
  active?: boolean | null;
  address_line_2?: string | null;
  county?: string | null;
  dentist_id?: number | null;
  dentist_recall_date?: string | null;
  email_address?: string | null;
  home_phone?: string | null;
  home_phone_country?: string | null;
  hygienist_id?: number | null;
  hygienist_recall_date?: string | null;
  hygienist_recall_interval?: number | null;
  legacy_id?: number | null;
  marketing?: number | null;
  medical_alert?: number | null;
  medical_alert_text?: string | null;
  metadata?: string | null;
  middle_name?: string | null;
  mobile_phone?: string | null;
  mobile_phone_country?: string | null;
  nhs_number?: string | null;
  ni_number?: string | null;
  preferred_name?: string | null;
  preferred_phone_number?: number | null;
  recall_method?: string | null;
  site_id?: string | null;
  town?: string | null;
  use_email?: boolean | null;
  use_sms?: boolean | null;
  work_phone?: string | null;
  work_phone_country?: string | null;
  custom_field_1?: string | null;
  custom_field_2?: string | null;
}

export interface createPersonInput {
  data?: PersonInput | null;
}

export interface createSourceInput {
  data?: SourceInput | null;
}

export interface createStageInput {
  data?: StageInput | null;
}

export interface createTrackerDentallyPatientFields {
  title: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  clinic: number;
  inquiries: (string | null)[];
  assignees: (string | null)[];
  source: string;
  medium?: string | null;
  campaign?: string | null;
  date_of_birth: any;
  gender: boolean;
  ethnicity: string;
  address_line_1: string;
  postcode: string;
  payment_plan_id: number;
  active?: boolean | null;
  address_line_2?: string | null;
  county?: string | null;
  dentist_id?: number | null;
  dentist_recall_date?: string | null;
  home_phone?: string | null;
  home_phone_country?: string | null;
  hygienist_id?: number | null;
  hygienist_recall_date?: string | null;
  hygienist_recall_interval?: number | null;
  legacy_id?: number | null;
  marketing?: number | null;
  medical_alert?: number | null;
  medical_alert_text?: string | null;
  metadata?: string | null;
  middle_name?: string | null;
  mobile_phone_country?: string | null;
  nhs_number?: string | null;
  ni_number?: string | null;
  preferred_name?: string | null;
  preferred_phone_number?: number | null;
  recall_method?: string | null;
  site_id?: string | null;
  town?: string | null;
  use_email?: boolean | null;
  use_sms?: boolean | null;
  work_phone?: string | null;
  work_phone_country?: string | null;
}

export interface createTransactionInput {
  data?: TransactionInput | null;
}

export interface createTxAppointmentTimeInput {
  data?: TxAppointmentTimeInput | null;
}

export interface createTxPlanInput {
  data?: TxPlanInput | null;
}

export interface deleteAlarmInput {
  where?: InputID | null;
}

export interface deleteAttemptInput {
  where?: InputID | null;
}

export interface deleteDocumentInput {
  where?: InputID | null;
}

export interface deleteLeadInput {
  where?: InputID | null;
}

export interface deleteNoteCustomInput {
  where?: InputWhere | null;
}

export interface deleteTxAppointmentTimeInput {
  where?: InputID | null;
}

export interface deleteTxPlanInput {
  where?: InputID | null;
}

export interface editAlarmInput {
  due_date?: any | null;
  note?: string | null;
  origin?: string | null;
  due_time?: any | null;
  lead_id?: string | null;
  published_at?: any | null;
  created_by?: string | null;
  updated_by?: string | null;
}

export interface editClinicInput {
  name?: string | null;
  dentally_site_id?: string | null;
  reception_email?: string | null;
  consultation_email?: string | null;
  cst_email?: string | null;
  np_target?: any | null;
  created_by?: string | null;
  updated_by?: string | null;
}

export interface editLeadInput {
  notes?: (string | null)[] | null;
  tags?: (string | null)[] | null;
  person?: string | null;
  stages_history?: (string | null)[] | null;
  preferred_contact_method?: ENUM_LEAD_PREFERRED_CONTACT_METHOD | null;
  preferred_contact_time?: string | null;
  preferred_contact_day?: string | null;
  unread_message_id?: string | null;
  has_unread_message?: boolean | null;
  sources?: (string | null)[] | null;
  archive?: boolean | null;
  legacy?: boolean | null;
  stage_type?: string | null;
  owner?: string | null;
  is_external?: boolean | null;
  reapplication?: boolean | null;
  closed?: boolean | null;
  booked_online?: boolean | null;
  treatment_plan_id?: string | null;
  treatment_plan_price?: number | null;
  practitioner_id?: any | null;
  deposit_paid?: boolean | null;
  payment_taken?: boolean | null;
  waiting_for_clin_check?: boolean | null;
  finance_application?: boolean | null;
  converted?: boolean | null;
  clinic?: string | null;
  dentally_appointment_id?: any | null;
  dentally_practitioner_name?: string | null;
  dentally_referrer_name?: string | null;
  dentally_appointment_state?: string | null;
  dentally_appointment_start_time?: any | null;
  tco_assignee?: string | null;
  dentist_assignee?: string | null;
  cst_assignee?: string | null;
  published_at?: any | null;
  created_by?: string | null;
  updated_by?: string | null;
}

export interface editNoteInput {
  text?: string | null;
  type?: ENUM_NOTE_TYPE | null;
  owner?: string | null;
  origin?: string | null;
  stage?: string | null;
  published_at?: any | null;
  created_by?: string | null;
  updated_by?: string | null;
}

export interface editPersonInput {
  phone?: string | null;
  email?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  middle_name?: string | null;
  transaction_history?: (string | null)[] | null;
  photos?: (string | null)[] | null;
  dentally_uuid?: string | null;
  dentally_person_id?: string | null;
  dentally_creator_id?: string | null;
  twilio_conversation_sid?: string | null;
  dream_outcome?: string | null;
  treatment_reason?: string | null;
  comes_from?: string | null;
  wizard_step?: any | null;
  created_by?: string | null;
  updated_by?: string | null;
}

export interface editSourceInput {
  medium?: string | null;
  source?: string | null;
  campaign?: string | null;
  other_utm?: string | null;
  origin?: string | null;
  created_by?: string | null;
  updated_by?: string | null;
}

export interface editStageInput {
  owner?: string | null;
  assignees?: (string | null)[] | null;
  type?: ENUM_STAGE_TYPE | null;
  tx_plan?: string | null;
  payment?: string | null;
  contact_attempts?: (string | null)[] | null;
  alarm?: string | null;
  origin?: string | null;
  inquiries?: (string | null)[] | null;
  consented?: boolean | null;
  failure_notes?: string | null;
  failure_tag?: ENUM_STAGE_FAILURE_TAG | null;
  callback_date?: any | null;
  callback_time?: any | null;
  note?: string | null;
  clinic?: string | null;
  dentally_appointment_state?: string | null;
  dentally_appointment_start_time?: any | null;
  dentally_first_appointment_completed?: boolean | null;
  created_by?: string | null;
  updated_by?: string | null;
}

export interface editTxAppointmentTimeInput {
  name?: string | null;
  date?: any | null;
  origin?: string | null;
  published_at?: any | null;
  created_by?: string | null;
  updated_by?: string | null;
}

export interface editTxPlanInput {
  origin?: string | null;
  tx_plan?: string | null;
  appointment_times?: (string | null)[] | null;
  cost?: number | null;
  type?: string | null;
  created_by?: string | null;
  updated_by?: string | null;
}

export interface getAppointmentsAvailabilityParams {
  start_time: string;
  finish_time: string;
  practitioner_ids?: (number | null)[] | null;
  duration?: number | null;
  page?: number | null;
  per_page?: number | null;
}

export interface getAppointmentsParams {
  on?: any | null;
  before?: any | null;
  after?: any | null;
  updated_after?: any | null;
  practitioner_id?: string | null;
  patient_id?: string | null;
  room_id?: string | null;
  site_id?: string | null;
  state?: string | null;
  cancelled?: boolean | null;
}

export interface getPractitionersParams {
  site_id?: string | null;
  created_after?: any | null;
  updated_after?: any | null;
  active?: boolean | null;
}

export interface getTemplateInput {
  templateId: string;
  versionId: string;
}

export interface getTreatmentPlanItemsParams {
  completed_on?: any | null;
  patient_id?: string | null;
  practitioner_id?: number | null;
  treatment_plan_id?: string | null;
  treatment_appointment_id?: string | null;
  updated_after?: any | null;
  updated_before?: any | null;
}

export interface getTreatmentPlansParams {
  completed?: boolean | null;
  created_after?: any | null;
  created_before?: any | null;
  ids?: (string | null)[] | null;
  patient_id?: string | null;
  practitioner_id?: number | null;
  start_date?: any | null;
  updated_since?: any | null;
}

export interface instantCreateInput {
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  clinic: number;
  inquiries: (string | null)[];
  assignees: (string | null)[];
  source: string;
  medium?: string | null;
  campaign?: string | null;
  dentally_uuid?: string | null;
  dentally_person_id?: string | null;
}

export interface matchTrackerDentallyParams {
  email?: string | null;
  mobile_phone?: string | null;
}

export interface newAppointmentReasons {
  name: string;
  reason_id: string;
  enabled: boolean;
  consult_booked: boolean;
  send_email: boolean;
}

export interface personWizardData {
  personId: string;
  dream_outcome?: string | null;
  treatment_reason?: string | null;
  comes_from?: string | null;
  face_photos?: string | null;
  teeth_photos?: (string | null)[] | null;
  wizard_step: number;
}

export interface proposalParams {
  page?: number | null;
  per_page?: number | null;
  type?: number | null;
}

export interface sendChatMessageInput {
  conversationSid: string;
  message: string;
}

export interface sendEmailInput {
  email: EmailReceiverInput;
  template_id?: string | null;
  sender?: string | null;
  subject?: string | null;
  dynamic_template_data?: any | null;
}

export interface sendMessageInput {
  to: string;
  body: string;
  leadId: string;
  owner: string;
}

export interface startChatInput {
  friendlyName: string;
  personId: string;
  contentSid: string;
  contentVariables?: any | null;
}

export interface updateAlarmInput {
  where?: InputID | null;
  data?: editAlarmInput | null;
}

export interface updateClinicInput {
  where?: InputID | null;
  data?: editClinicInput | null;
}

export interface updateLeadInput {
  where?: InputID | null;
  data?: editLeadInput | null;
}

export interface updateNoteInput {
  where?: InputID | null;
  data?: editNoteInput | null;
}

export interface updatePersonInput {
  where?: InputID | null;
  data?: editPersonInput | null;
}

export interface updateSourceInput {
  where?: InputID | null;
  data?: editSourceInput | null;
}

export interface updateStageInput {
  where?: InputID | null;
  data?: editStageInput | null;
}

export interface updateTxAppointmentTimeInput {
  where?: InputID | null;
  data?: editTxAppointmentTimeInput | null;
}

export interface updateTxPlanInput {
  where?: InputID | null;
  data?: editTxPlanInput | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
