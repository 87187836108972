import { Flex, Text } from '@chakra-ui/react'

import React, { FC } from 'react'

import AuditLogsInput from '~/inputs/auditLogs'
import { FindLead_findLead } from '~/views/__generated__/FindLead'

interface LeadJourneyProps {
	leadDetails?: FindLead_findLead
	mode: 'add' | 'edit'
	active: boolean
}

const LeadJourney: FC<LeadJourneyProps> = props => {
	const { leadDetails, mode } = props
	const inEditMode = mode === 'edit'

	return (
		<Flex direction='column'>
			<Flex position='relative' direction='column' width='100%' mb='44px'>
				<Text color='#191C1A' fontSize='1.8em' fontWeight={700} casing='capitalize'>
					Patient Journey
				</Text>
			</Flex>
			<Flex direction='column'>{inEditMode && <AuditLogsInput leadId={leadDetails?.id} />} </Flex>
		</Flex>
	)
}

export default LeadJourney
