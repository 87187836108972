import { gql, useQuery } from '@apollo/client'
import { Box, Divider, Flex, Heading, HStack, Skeleton, Spinner, Tag, Text } from '@chakra-ui/react'
import { navigate } from 'gatsby'
import { Droppable } from 'react-beautiful-dnd'

import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

import ExportCsvBtn from './ExportCsvBtn'
import LeadCard from './LeadCard'
import { LeadsList, LeadsListVariables, LeadsList_leadsList_leads } from './__generated__/LeadsList'
import { FiltersType, getStatusColor, ColumnRefType, ColumnItem } from './helpers'

/***
 *
 * Queries & Mutations
 *
 ***/
export const leadsListQuery = gql`
	query LeadsList($sort: String, $start: Int, $limit: Int, $where: JSON) {
		leadsList(sort: $sort, start: $start, limit: $limit, where: $where) {
			leads {
				id
				inquiries {
					origin
					inquiries {
						id
						name
					}
				}
				created_at
				updated_at
				person {
					id
					first_name
					middle_name
					last_name
					phone
					email
				}
				clinic {
					id
					name
				}
				current_stage {
					id
					type
					possible_next_stages
				}
				assignees {
					assignees {
						id
						username
						email
						role {
							type
						}
					}
				}
				alarm {
					id
					due_date
					due_time
				}
				attempts {
					id
					type
					team
				}
				has_unread_message
				is_callback
				reapplication
				archive
				closed
				deposit_paid
				payment_taken
				waiting_for_clin_check
				finance_application
				treatment_plan_id
				stage_type
				treatment_plan_price
				dentally_appointment_id
				failure_tag
				failure_notes
			}
			total
		}
	}
`
export const downloadLeadsCSVMutation = gql`
	mutation DownloadLeadsCSV($data: JSON!) {
		downloadLeadsCSV(data: $data) {
			success
			blobs
		}
	}
`

/***
 *
 * Interface & Type
 *
 ***/
interface Props extends ColumnItem {
	stageIsLoading: boolean
	filterValue: { sort?: string | null; filters?: FiltersType }
	searchQuery?: string
}

/***
 *
 * Column Component
 *
 ***/
// eslint-disable-next-line react/display-name
const Column = forwardRef((props: Props, ref) => {
	const {
		heading,
		withLink,
		columnId,
		stageTypes,
		leadTeam,
		filterValue,
		searchQuery,
		stageIsLoading,
	} = props

	const paginationLimit = 15

	const calculatedFilters = {
		...filterValue,
		filters: { ...filterValue.filters, search: searchQuery },
	}

	const [scrollTop, setScrollTop] = useState(0)
	const [loadingMore, setLoadingMore] = useState(false)

	const { data, loading, refetch, fetchMore } = useQuery<LeadsList, LeadsListVariables>(
		leadsListQuery,
		{
			notifyOnNetworkStatusChange: true,
			variables: {
				start: 0,
				limit: paginationLimit,
				sort: filterValue.sort || 'created_at:desc',
				where: {
					role: leadTeam,
					types: stageTypes,
					archive: false,
					...calculatedFilters.filters,
				},
			},
		}
	)

	const containerRef = useRef<HTMLDivElement>(null)
	const leadList = (data?.leadsList.leads?.filter(Boolean) as LeadsList_leadsList_leads[]) || []

	const handleLoadMore = async () => {
		const scrollTop = containerRef.current?.scrollTop || 0
		setScrollTop(scrollTop)
		setLoadingMore(true)

		await fetchMore?.({
			variables: {
				start: leadList.length,
			},
			updateQuery: (prev, { fetchMoreResult }) => {
				if (!fetchMoreResult) return prev

				const prevLeads = prev?.leadsList.leads?.filter(Boolean) as LeadsList_leadsList_leads[]
				const newLeads = fetchMoreResult?.leadsList.leads?.filter(
					Boolean
				) as LeadsList_leadsList_leads[]

				setLoadingMore(false)

				return {
					leadsList: {
						...prev.leadsList,
						leads: [...prevLeads, ...newLeads],
					},
				}
			},
		})
	}

	useEffect(() => {
		if (!containerRef.current) return
		containerRef.current.scrollTop = scrollTop
	}, [leadList.length])

	const onScroll = async () => {
		if (containerRef.current && data?.leadsList.leads) {
			const { scrollTop, scrollHeight, clientHeight } = containerRef.current
			const isNearBottom = scrollTop + clientHeight >= scrollHeight

			if (isNearBottom) {
				if (data?.leadsList.total > leadList.length) {
					await handleLoadMore()
				}
			}
		}
	}

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	useEffect(() => {
		if (containerRef.current) {
			containerRef.current.addEventListener('scroll', onScroll)

			// Clean-up
			return () => {
				if (containerRef.current) {
					containerRef.current.removeEventListener('scroll', onScroll)
				}
			}
		}
	}, [data?.leadsList])

	useImperativeHandle(ref, () => {
		const refObject = ref as React.MutableRefObject<ColumnRefType>
		const refetchFunction = async () => await refetch()

		return !refObject.current
			? { [columnId]: refetchFunction }
			: { ...refObject.current, [columnId]: refetchFunction }
	})

	const isLoading = (loading && !leadList.length) || stageIsLoading

	if (isLoading) {
		return (
			<HStack minW='19em' h='35em' spacing='4em'>
				<Skeleton w='full' h='full' rounded='md' />
			</HStack>
		)
	}
	return (
		<Box minW='19em'>
			<Box w='full' shadow='md' rounded='md' bg='white'>
				<Box px={4} py={2}>
					<Flex align='center' justify='center' py={2}>
						<Flex flexGrow={1} gap={3}>
							<Heading
								fontSize='1em'
								color='gray.600'
								sx={{
									'&:hover': {
										cursor: withLink ? 'pointer' : 'default',
										textDecoration: withLink ? 'underline' : 'none',
									},
								}}
								onClick={withLink ? () => navigate(`/column/${columnId}`) : i => i}
							>
								{heading}
							</Heading>
							{isLoading ? (
								<HStack h='1.2em' w='2em'>
									<Skeleton w='full' h='full' rounded='md' />
								</HStack>
							) : (
								<Tag size='sm' colorScheme={getStatusColor(columnId)}>
									{data?.leadsList.total || 0}
								</Tag>
							)}
						</Flex>

						<Flex>
							{isLoading ? (
								<HStack h='1.2em' w='2em'>
									<Skeleton w='full' h='1.8em' rounded='md' />
								</HStack>
							) : (
								<ExportCsvBtn
									columnId={columnId}
									disableDownload={!leadList.length}
									queryVariables={{
										start: 0,
										limit: data?.leadsList.total || 0,
										sort: filterValue.sort || 'created_at:desc',
										where: {
											role: leadTeam,
											types: stageTypes,
											archive: false,
											...calculatedFilters.filters,
										},
									}}
								/>
							)}
						</Flex>
					</Flex>

					<Divider borderWidth={2} borderColor={getStatusColor(columnId, '500')} />
				</Box>
				<Box ref={containerRef} px={3} py={2} maxH='36em' overflowY='auto'>
					<Droppable droppableId={columnId}>
						{provided => (
							<Box
								minH='10em'
								{...provided.droppableProps}
								ref={provided.innerRef}
								position='relative'
							>
								{loadingMore && (
									<Flex
										sx={{
											position: 'absolute',
											width: '100%',
											top: '0',
											left: '0',
											height: '10vh',
										}}
									>
										<Flex
											align='center'
											justify='center'
											py='1em'
											sx={{
												position: 'fixed',
												width: '270px',
												zIndex: '20',
												background: 'rgba(255, 255, 255, 0.9)',
											}}
										>
											<Flex justify='center' gap='1em' p='1em'>
												<Spinner />
												<Text color='gray.500' fontSize='sm'>
													Loading
												</Text>
											</Flex>
										</Flex>
									</Flex>
								)}
								{leadList.map((lead, index) => (
									<LeadCard
										key={lead.id}
										leadTeam={leadTeam}
										lead={lead}
										index={index}
										columnId={columnId}
									/>
								))}
								{provided.placeholder}
							</Box>
						)}
					</Droppable>
				</Box>
			</Box>
		</Box>
	)
})

export default Column
