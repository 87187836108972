import { Avatar, Box, Flex, Heading, HStack, Link, Text } from '@chakra-ui/react'
import { navigate, Link as GatsbyLink } from 'gatsby'
import { FaSignOutAlt } from 'react-icons/fa'

import React, { FC, useContext } from 'react'

import { strMaxLength } from '~/components/helpers'
import { AuthContext } from '~/components/page/context'

import SearchBar from './SearchBar'

const Navbar: FC = () => {
	const currentPath = window.location.pathname
	const isDashboardPage = currentPath === '/'
	const user = useContext(AuthContext)

	const handleLogout = async () => {
		try {
			await fetch('/api/logout', { method: 'POST' })
			navigate('/login')
			localStorage.clear()
		} catch (error) {
			console.error(error)
		}
	}

	const isAdmin = user?.role?.name === 'Admin'

	return (
		<>
			<Box bg='gray.800' px='2em'>
				<Flex h='4.5em' alignItems='center' justifyContent='space-between'>
					<HStack spacing='4em' alignItems='center'>
						<Link to='/' as={GatsbyLink}>
							<img src='/logo.svg' alt='Ruhdental' />
						</Link>
						<HStack as='nav' spacing={4} display={{ base: 'none', md: 'flex' }}>
							<Link to='/' as={GatsbyLink}>
								<Text
									textDecoration='none'
									color={/^\/$/.test(currentPath) ? 'primary' : 'gray.200'}
									_hover={{ color: 'primary' }}
									fontSize='0.9em'
									fontWeight='bold'
								>
									Dashboard
								</Text>
							</Link>
							<Link to='/reception' as={GatsbyLink}>
								<Text
									textDecoration='none'
									color={/\/reception+\/*/.test(currentPath) ? 'primary' : 'gray.200'}
									_hover={{ color: 'primary' }}
									fontSize='0.9em'
									fontWeight='bold'
								>
									Enquiries
								</Text>
							</Link>
							<Link to='/tco' as={GatsbyLink}>
								<Text
									textDecoration='none'
									color={/\/tco+\/*/.test(currentPath) ? 'primary' : 'gray.200'}
									_hover={{ color: 'primary' }}
									fontSize='0.9em'
									fontWeight='bold'
								>
									Leads
								</Text>
							</Link>
							<Link to='/oct' as={GatsbyLink}>
								<Text
									textDecoration='none'
									color={/\/oct+\/*/.test(currentPath) ? 'primary' : 'gray.200'}
									_hover={{ color: 'primary' }}
									fontSize='0.9em'
									fontWeight='bold'
								>
									OCT
								</Text>
							</Link>
							{isAdmin ? (
								<Link to='/settings' as={GatsbyLink}>
									<Text
										textDecoration='none'
										color={/\/settings+\/*/.test(currentPath) ? 'primary' : 'gray.200'}
										_hover={{ color: 'primary' }}
										fontSize='0.9em'
										fontWeight='bold'
									>
										Settings
									</Text>
								</Link>
							) : null}
							{isAdmin ? (
								<Link to='/reports' as={GatsbyLink}>
									<Text
										textDecoration='none'
										color={/\/reports+\/*/.test(currentPath) ? 'primary' : 'gray.200'}
										_hover={{ color: 'primary' }}
										fontSize='0.9em'
										fontWeight='bold'
									>
										Reports
									</Text>
								</Link>
							) : null}
							{isAdmin ? (
								<Link to='/archived' as={GatsbyLink}>
									<Text
										textDecoration='none'
										color={/\/archived+\/*/.test(currentPath) ? 'primary' : 'gray.200'}
										_hover={{ color: 'primary' }}
										fontSize='0.9em'
										fontWeight='bold'
									>
										Archived
									</Text>
								</Link>
							) : null}
						</HStack>
					</HStack>
					{!isDashboardPage && <SearchBar />}
					<HStack spacing={8} alignItems='center'>
						<Flex w='full' alignItems='center'>
							<Avatar
								w='50px'
								h='50px'
								name={user?.username}
								border='4px solid #EEAB7E'
								color='#fff'
							/>
							<Flex w='full' direction='column' ml='1em'>
								<Heading color='#fff' fontSize='0.9em' fontWeight={700} cursor='pointer'>
									{user?.username}
								</Heading>
								<Text fontSize='0.7em' color='#fff'>
									{strMaxLength(user?.email, 26)}
								</Text>
								<Flex
									gap='0.5em'
									mt='2px'
									align='center'
									background='gray.700'
									borderRadius='5px'
									_hover={{ background: 'gray.600' }}
									justify='center'
									cursor='pointer'
									onClick={handleLogout}
								>
									<Text color='gray.200' fontSize='0.7em' fontWeight='bold'>
										logout
									</Text>
									<FaSignOutAlt color='white' />
								</Flex>
							</Flex>
						</Flex>
					</HStack>
				</Flex>
			</Box>
		</>
	)
}

export default Navbar
