import { Textarea, Flex, Spinner, Button } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { FiSend } from 'react-icons/fi'

import React, { FC, useState, useEffect } from 'react'

const StyledButtonSend = styled(Button)`
	width: 36px;
	height: 36px;
	padding: 0;
	background: #191c1a;
	z-index: 222;
	&:hover {
		background: #5a5a5a;
	}
	&:disabled {
		background: #5a5a5a;
	}
`
interface Props {
	onSend: (messageInput: string) => Promise<void>
	isLoading?: boolean
}

const MessageInput: FC<Props> = props => {
	const { onSend, isLoading } = props

	const [rows, setRows] = useState(1)
	const [messageInput, setMessageInput] = useState('')
	const [loading, setLoading] = useState(false)

	const handleOnSend = async () => {
		if (isLoading || loading || !messageInput) return

		setLoading(true)

		await onSend(messageInput)
		setMessageInput('')

		setLoading(false)
	}

	useEffect(() => {
		const rowlen = messageInput.split('\n')
		rowlen.length > 1 ? setRows(rowlen.length) : setRows(1)
	}, [messageInput])

	return (
		<Flex gap={6} position='relative'>
			<Textarea
				rows={rows}
				size='sm'
				color='#64554B'
				disabled={loading || isLoading}
				_placeholder={{ color: '#64554B', opacity: '0.4' }}
				placeholder='Type something here...'
				rounded='md'
				padding='10px 60px 10px 10px'
				bg='white'
				height='76px'
				value={messageInput}
				onChange={evt => setMessageInput(evt.target.value)}
			/>
			<Flex mr={3} alignSelf='end' py={1} position='absolute' right='5px' bottom='15px'>
				{loading ? (
					<Spinner color='green.700' />
				) : (
					<StyledButtonSend
						onClick={handleOnSend}
						cursor={loading || !messageInput ? 'not-allowed' : 'pointer'}
					>
						<FiSend fontSize='18px' color='#fff' />
					</StyledButtonSend>
				)}
			</Flex>
		</Flex>
	)
}

export default MessageInput
