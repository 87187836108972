import { Inquiries_inquiries } from '~/inputs/__generated__/Inquiries'

import { ENUM_STAGE_TYPE } from '../../../__generated__/globalTypes'

export type ColumnId =
	| 'new'
	| 'contacted'
	| 'failed_to_attend'
	| 'failed_to_book'
	| 'consult_booked'
	| 'follow_up'
	| 'treatment_booked'
	| 'tx_plan_needed'
	| 'fta_appointment'
	| 'appointment_visited'
	| 'not_converted'
	| 'cancelled'
	| 'appointment_required'
	| 'appointment_booked'
	| 'fta_cancelled'
	| 'closed'

export type LeadTeam = 'reception' | 'tco' | 'oct'

export type ColumnItem = {
	columnId: ColumnId
	withLink?: boolean
	heading: string
	leadTeam?: LeadTeam
	stageTypes: ENUM_STAGE_TYPE[]
}

export type FiltersType = {
	alarms?: string | null
	clinic?: number | null
	tags?: string[] | null
	inquiries?: string[] | null
	dentist?: string | null
	search?: string
	person_name?: string
	source?: string
	created_date_range?: [string, string] | null
	digital_consults?: boolean
	unread_message?: boolean
	assignees?: string[] | null
	dentally_appointment_id?: boolean
	dentist_assigned?: boolean
	tco_assigned?: boolean
}

export type StoredFilters = {
	sort?: string | null
	filters?: FiltersType
}

export type StoredReportFilters = {
	inquiries?: string[] | null
	canceled?: boolean
}

export type ColumnRefType = { [index: string]: () => Promise<void> }

export const receptionColumnStatusTypes: { [index: string]: ENUM_STAGE_TYPE[] } = {
	new: [ENUM_STAGE_TYPE.created],
	contacted: [ENUM_STAGE_TYPE.contacted],
	consult_booked: [ENUM_STAGE_TYPE.consult_booked],
	failed_to_book: [ENUM_STAGE_TYPE.consult_failed_to_book],
	cancelled: [ENUM_STAGE_TYPE.cancelled, ENUM_STAGE_TYPE.consult_failed_to_attend],
}

export const tcoColumnStatusTypes: { [index: string]: ENUM_STAGE_TYPE[] } = {
	consult_booked: [ENUM_STAGE_TYPE.consult_booked],
	tx_plan_needed: [ENUM_STAGE_TYPE.consult_attended],
	follow_up: [ENUM_STAGE_TYPE.TX_given, ENUM_STAGE_TYPE.appointment_pending],
	treatment_booked: [ENUM_STAGE_TYPE.treatment_booked],
	appointment_visited: [ENUM_STAGE_TYPE.appointment_attended],
	fta_appointment: [
		ENUM_STAGE_TYPE.appointment_cancelled,
		ENUM_STAGE_TYPE.appointment_failed_to_attend,
	],
	not_converted: [ENUM_STAGE_TYPE.failed_to_convert],
}

export const octColumnStatusTypes: { [index: string]: ENUM_STAGE_TYPE[] } = {
	appointment_required: [ENUM_STAGE_TYPE.appointment_attended],
	appointment_booked: [ENUM_STAGE_TYPE.oct_appointment_booked],
	fta_cancelled: [
		ENUM_STAGE_TYPE.oct_appointment_failed_to_attend,
		ENUM_STAGE_TYPE.oct_appointment_cancelled,
	],
	closed: [ENUM_STAGE_TYPE.TX_done, ENUM_STAGE_TYPE.TX_incomplete],
}

export const getStatusColor = (status: ColumnId, shade?: string): string => {
	const colorGraph = {
		new: 'gray',
		contacted: 'blue',
		appointment_required: 'blue',
		appointment_booked: 'purple',
		failed_to_attend: 'red',
		fta_cancelled: 'red',
		failed_to_book: 'red',
		consult_booked: 'purple',
		tx_plan_needed: 'blue',
		follow_up: 'orange',
		treatment_booked: 'teal',
		fta_appointment: 'red',
		appointment_visited: 'green',
		not_converted: 'red',
		cancelled: 'red',
		closed: 'red',
	}

	return shade ? `${colorGraph[status]}.${shade}` : colorGraph[status]
}

export const getColumnById = (columnId: string): ColumnItem | undefined => {
	const leadTeams: LeadTeam[] = ['reception', 'tco', 'oct']
	let result: ColumnItem | undefined = undefined
	for (const leadTeam of leadTeams) {
		const columnList = generateColumns(leadTeam)
		const column = columnList.find(column => column.columnId === columnId)
		if (column) {
			result = column
		}
	}
	return result
}

export const generateColumns = (leadTeam?: LeadTeam): ColumnItem[] => {
	const receptionColumnList: ColumnItem[] = [
		{
			columnId: 'new',
			heading: 'New',
			leadTeam,
			stageTypes: receptionColumnStatusTypes.new,
		},
		{
			columnId: 'contacted',
			heading: 'Contacted',
			leadTeam,
			stageTypes: receptionColumnStatusTypes.contacted,
		},
		{
			columnId: 'consult_booked',
			heading: 'Consult Booked',
			leadTeam,
			stageTypes: receptionColumnStatusTypes.consult_booked,
		},
		{
			columnId: 'failed_to_book',
			heading: 'Failed to book in',
			leadTeam,
			stageTypes: receptionColumnStatusTypes.failed_to_book,
		},
		{
			columnId: 'cancelled',
			heading: 'FTA / Cancelled',
			leadTeam,
			stageTypes: receptionColumnStatusTypes.cancelled,
		},
	]

	const tcoColumnList: ColumnItem[] = [
		{
			columnId: 'consult_booked',
			heading: 'Consult Booked',
			leadTeam,
			stageTypes: tcoColumnStatusTypes.consult_booked,
		},
		{
			columnId: 'tx_plan_needed',
			heading: 'TX plan needed',
			leadTeam,
			stageTypes: tcoColumnStatusTypes.tx_plan_needed,
		},
		{
			columnId: 'follow_up',
			heading: 'Follow Up',
			withLink: true,
			leadTeam,
			stageTypes: tcoColumnStatusTypes.follow_up,
		},
		{
			columnId: 'treatment_booked',
			heading: 'Treatment Booked',
			leadTeam,
			stageTypes: tcoColumnStatusTypes.treatment_booked,
		},
		{
			columnId: 'appointment_visited',
			heading: 'Treatment Started',
			leadTeam,
			stageTypes: tcoColumnStatusTypes.appointment_visited,
		},
		{
			columnId: 'fta_appointment',
			heading: 'FTA/Cancelled',
			leadTeam,
			stageTypes: tcoColumnStatusTypes.fta_appointment,
		},
		{
			columnId: 'not_converted',
			heading: 'Not Converted',
			leadTeam,
			stageTypes: tcoColumnStatusTypes.not_converted,
		},
	]

	const octColumnList: ColumnItem[] = [
		{
			columnId: 'appointment_required',
			heading: 'Appointment Required',
			leadTeam,
			stageTypes: octColumnStatusTypes.appointment_required,
		},
		{
			columnId: 'appointment_booked',
			heading: 'Appointment Booked',
			leadTeam,
			stageTypes: octColumnStatusTypes.appointment_booked,
		},
		{
			columnId: 'fta_cancelled',
			heading: 'FTA/Cancelled',
			leadTeam,
			stageTypes: octColumnStatusTypes.fta_cancelled,
		},
		{
			columnId: 'closed',
			heading: 'Closed',
			leadTeam,
			stageTypes: octColumnStatusTypes.closed,
		},
	]

	switch (leadTeam) {
		case 'reception':
		default:
			return receptionColumnList
		case 'tco':
			return tcoColumnList
		case 'oct':
			return octColumnList
	}
}
export const getLocalStorageFilters = (leadTeam?: LeadTeam): StoredFilters =>
	JSON.parse(localStorage.getItem(`${leadTeam}_filters`) ?? '{}') as StoredFilters

export const getLocalStorageReportFilters = (): StoredReportFilters =>
	JSON.parse(localStorage.getItem('report_filters') ?? '{}') as StoredReportFilters

export const isLeadReadyToMove = (newStage: string, txPlanId: string | null): boolean => {
	return !!(
		[
			'created',
			'appointment_pending',
			'contacted',
			'consult_booked',
			'consult_attended',
			'TX_done',
			'consult_failed_to_attend',
			'appointment_attended',
			'appointment_cancelled',
			'oct_appointment_cancelled',
			'oct_appointment_booked',
			'cancelled',
			'fta_cancelled',
		].includes(newStage) ||
		(['TX_given', 'TX_updated', 'treatment_booked'].includes(newStage) && txPlanId)
	)
}

export const getEnquiriesOptions = (enquiries: Inquiries_inquiries[]): Inquiries_inquiries[] => {
	if (enquiries && enquiries.length) {
		return [{ id: 'None', name: 'No enquiries', __typename: 'Inquiry' }, ...enquiries]
	}
	return []
}
