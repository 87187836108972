import { Box, Text, Flex, Grid, GridItem, Tag } from '@chakra-ui/react'
import { ENUM_STAGE_TYPE } from '__generated__/globalTypes'
import { formatDistance } from 'date-fns'
import { FaRegClock, FaUserCircle } from 'react-icons/fa'

import { FC } from 'react'

import { StageStatusValues } from '~/constants'
import { AllStages_stages } from '~/inputs/__generated__/AllStages'

interface Props {
	stages: AllStages_stages[]
	onEditStage: (id: string, type: ENUM_STAGE_TYPE) => void
}

const Timeline: FC<Props> = props => {
	const { stages, onEditStage } = props

	return (
		<Flex direction='column'>
			<Box maxH='30em' overflowY='auto'>
				<Grid
					gridTemplateColumns='1.2em 1fr'
					gridAutoRows='auto'
					py='1em'
					pr='4em'
					rowGap='3em'
					columnGap='0.8em'
					maxH='30em'
					overflowY='auto'
				>
					{stages?.map((stage, index) => (
						<>
							<GridItem
								position='relative'
								_after={{
									display: stages.length === index + 1 ? 'none' : 'block',
									content: '""',
									position: 'absolute',
									width: '0.2em',
									backgroundColor: 'green.300',
									top: '1.2em',
									left: 0,
									right: 0,
									bottom: 'calc(3em * -1)', // CSS:IMPORTANT -> Value of 3em should be equal to the value of "rowGap" property of parent grid.
									margin: '0 auto',
								}}
							>
								<Flex
									align='center'
									justify='center'
									bg='green.400'
									w='100%'
									h='1.2em' // CSS:IMPORTANT -> Height must be equal to the value of "top" property of parent's _after element.
									shadow='sm'
									rounded='full'
								/>
							</GridItem>

							<GridItem cursor='pointer' onClick={() => onEditStage(stage.id, stage.type)}>
								<Flex
									direction='column'
									justify='center'
									gap='1em'
									shadow='sm'
									rounded='md'
									border='1px'
									borderColor='gray.200'
									p='0.7em'
								>
									<Flex w='full' rounded='md'>
										<Text fontSize='1em' fontWeight='bold' color='gray.600' className='tag' mb={2}>
											{StageStatusValues[stage.type]}
										</Text>
									</Flex>

									<Flex align='center' gap='1em'>
										{stage.owner && (
											<Flex align='center' gap='0.5em'>
												<Tag textTransform='capitalize' gap='0.4em' size='sm' colorScheme='orange'>
													<FaUserCircle />
													{stage.owner?.username}
												</Tag>
											</Flex>
										)}
										<Flex align='center' gap='0.3em'>
											<Tag gap='0.4em' size='sm' colorScheme='gray'>
												<FaRegClock />
												{`${formatDistance(new Date(stage.created_at), new Date())} ago`}
											</Tag>
										</Flex>
									</Flex>
								</Flex>
							</GridItem>
						</>
					))}
				</Grid>
			</Box>
		</Flex>
	)
}

export default Timeline
