import { useQuery } from '@apollo/client'
import { MinusIcon, TriangleDownIcon } from '@chakra-ui/icons'
import {
	Checkbox,
	Flex,
	FormControl,
	FormLabel,
	Switch,
	Text,
	VStack,
	Collapse,
	HStack,
	Skeleton,
	Tag,
	Input,
	InputGroup,
	useRadio,
	Box,
	useRadioGroup,
	Tooltip,
} from '@chakra-ui/react'
import { MultiValue, SingleValue } from 'react-select'

import React, { FC, useContext, useEffect, useRef, useState } from 'react'

import { DateRangePicker } from '~/components/DatePicker'
import { clinicsListQuery } from '~/components/kanban/Board'
import { ClinicsList } from '~/components/kanban/__generated__/ClinicsList'
import { AuthContext } from '~/components/page/context'
import { AssigneeTypes, SearchFieldPlaceholders, SortByOptions } from '~/constants'
import { createClinicOptions, createSelectOptions, createSettledOptions } from '~/helpers'
import { Inquiries, Inquiries_inquiries } from '~/inputs/__generated__/Inquiries'
import { ListUsers, ListUsersVariables, ListUsers_users } from '~/inputs/__generated__/ListUsers'
import { listUsersQuery } from '~/inputs/assignees'
import { FindLead_findLead_tags } from '~/views/__generated__/FindLead'

import { ENUM_ATTEMPT_TEAM } from '../../../__generated__/globalTypes'
import { inquiryListQuery } from '../../inputs/inquiries'
import { onError } from '../helpers'
import { allTags } from '../lead/LeadFormNew'
import { AllTags } from '../lead/__generated__/AllTags'
import Button from '../ui/Button'
import SelectDropdown, { CustomValueContainer, PeopleOptionFactory } from '../ui/SelectDropdown'
import useToast from '../ui/Toast'
import { FiltersType, LeadTeam } from './helpers'

const RadioCard = props => {
	const { getInputProps, getRadioProps } = useRadio(props)

	const input = getInputProps()
	const checkbox = getRadioProps()

	return (
		<Box as='label'>
			<input {...input} />
			<Box
				{...checkbox}
				cursor='pointer'
				boxShadow='none'
				fontSize='0.8em'
				color='#000'
				border='none'
				bgColor='#fff'
				borderWidth='1px'
				borderRadius='md'
				_checked={{
					bg: 'teal.600',
					color: 'white',
					borderColor: 'teal.600',
				}}
				_focus={{
					boxShadow: 'outline',
				}}
				px={5}
				py={2}
			>
				{props.children}
			</Box>
		</Box>
	)
}

/***
 *
 * Filter component
 *
 ***/
interface Props {
	onChange: (args: { sort?: string | null; filters?: FiltersType }) => void
	refreshingLeads: boolean
	onRefresh: () => void
	activeEvents: string[]
	isDisabled?: boolean
	leadTeam?: LeadTeam
	filterValue?: { sort?: string | null; filters?: FiltersType } | null
}

const Filters: FC<Props> = props => {
	const { isDisabled, onChange, filterValue } = props
	const toast = useToast()
	const timeoutPerson = useRef<null | NodeJS.Timeout>(null)
	const timeoutSource = useRef<null | NodeJS.Timeout>(null)
	const currentUser = useContext(AuthContext)

	const [showAllFilters, setShowAllFilters] = useState(false)
	const [searchValue, setSearchValue] = useState<string>(filterValue?.filters?.person_name ?? '')
	const [searchValueSource, setSearchValueSource] = useState<string>(
		filterValue?.filters?.source ?? ''
	)

	const isDentist = currentUser?.role?.name === 'Dentist'

	const { data: tagsData, loading: loadingTags } = useQuery<AllTags>(allTags, {
		onError: error => onError(error, toast),
	})

	const inquiriesListQueryResult = useQuery<Inquiries>(inquiryListQuery, {
		onError: error => onError(error, toast),
	})

	const assigneesQueryResult = useQuery<ListUsers, ListUsersVariables>(listUsersQuery, {
		onError: error => onError(error, toast),
		variables: { json: { role: { name: AssigneeTypes } } },
	})

	const clinicsList = useQuery<ClinicsList>(clinicsListQuery)

	useEffect(() => {
		timeoutPerson.current = setTimeout(
			() => onChange({ filters: { person_name: searchValue } }),
			500
		)
		return () => clearTimeout(timeoutPerson.current!)
	}, [searchValue])

	useEffect(() => {
		timeoutSource.current = setTimeout(
			() => onChange({ filters: { source: searchValueSource } }),
			500
		)
		return () => clearTimeout(timeoutSource.current!)
	}, [searchValueSource])

	const tagsDefaultValue = tagsData?.tags
		?.filter(Boolean)
		.filter(tag => filterValue?.filters?.tags?.includes(tag?.id ?? '')) as FindLead_findLead_tags[]

	const dateRangeDefaultValue = [
		filterValue?.filters?.created_date_range?.[0],
		filterValue?.filters?.created_date_range?.[1],
	]

	const getSort = () => filterValue?.sort?.split(':') ?? []

	const [sortValue, setSortValue] = useState<string | null>(() => {
		const [value] = getSort()

		return value
	})

	const [sortOrder, setSortOrder] = useState<string>(() => {
		const [, order] = getSort()

		return order ?? 'asc'
	})

	const onAlarmChange = (type: string) => {
		const getType = () => {
			if (type === 'All') return null
			if (type === 'Not Set') return 'inactive'
			if (type === 'Overdue') return 'overdue'
			return 'All'
		}
		onChange({ filters: { alarms: getType() } })
	}

	const onDateRangeChange = (range: [string | null, string | null]) => {
		if (!range) {
			onChange({ filters: { created_date_range: null } })
			return
		}

		const [startDate, endDate] = range

		if (startDate && endDate) {
			onChange({ filters: { created_date_range: [startDate, endDate] } })
			return
		}

		onChange({ filters: { created_date_range: null } })
	}

	const clinicOptions = createClinicOptions(clinicsList?.data)

	const alarmOptions = ['All', 'Not Set', 'Overdue']

	const { getRootProps, getRadioProps } = useRadioGroup({
		name: 'alarms',
		defaultValue: 'All',
		onChange: type => onAlarmChange(type),
	})

	const group = getRootProps()
	const settledOptions = createSettledOptions()
	const booleanOptions = createSelectOptions()

	return (
		<VStack w='full' align='start' spacing={showAllFilters ? 5 : 0}>
			<Flex gap='0.5em' flexWrap='wrap' alignItems='center'>
				<Flex gap='0.5em' flexWrap='wrap' alignItems='center'>
					{props.activeEvents.includes('new_info') && (
						<Tooltip shouldWrapChildren hasArrow label='New info available.'>
							<Button
								size='sm'
								colorScheme='red'
								onClick={props.onRefresh}
								isDisabled={props.refreshingLeads}
								isLoading={props.refreshingLeads}
							>
								Refresh
							</Button>
						</Tooltip>
					)}
				</Flex>
				<Text fontSize='0.8em'>Alarms:</Text>
				<HStack {...group}>
					{alarmOptions.map(value => {
						const radio = getRadioProps({ value })
						return (
							<RadioCard key={value} {...radio}>
								{value}
							</RadioCard>
						)
					})}
				</HStack>
				<Flex w='10em' bg='white' align='center' px={4} py='0.5em' rounded='md' shadow='sm'>
					<Checkbox
						isDisabled={isDisabled}
						borderColor='blue.500'
						colorScheme='blue'
						defaultChecked={filterValue?.filters?.digital_consults}
						onChange={evt => onChange({ filters: { digital_consults: evt.target.checked } })}
					>
						<Text fontSize='0.8em'>Digital consults</Text>
					</Checkbox>
				</Flex>
				<Flex w='10em' bg='white' align='center' px={4} py='0.5em' rounded='md' shadow='sm'>
					<Checkbox
						isDisabled={isDisabled}
						borderColor='purple.500'
						colorScheme='purple'
						defaultChecked={filterValue?.filters?.unread_message}
						onChange={evt => onChange({ filters: { unread_message: evt.target.checked } })}
					>
						<Text fontSize='0.8em'>Unread message</Text>
					</Checkbox>
				</Flex>
				<Flex alignSelf='center'>
					<Button
						rightIcon={showAllFilters ? <MinusIcon /> : <TriangleDownIcon />}
						size='sm'
						bg='gray.700'
						_hover={{ bg: 'gray.700' }}
						color='gray.200'
						opacity={isDisabled ? '0.3' : '1'}
						cursor={isDisabled ? 'not-allowed' : 'pointer'}
						onClick={() => !isDisabled && setShowAllFilters(!showAllFilters)}
					>
						{showAllFilters ? 'Hide' : 'All filters'}
					</Button>
				</Flex>
			</Flex>
			<Collapse animateOpacity in={showAllFilters}>
				<Flex w='full' flexWrap='wrap' columnGap='0.8em' rowGap='0.5em' justify='flex-start'>
					{!isDentist ? (
						<FormControl w='15em'>
							<FormLabel color='gray.600' mb='0.2em' fontSize='0.8em'>
								Assignees:
							</FormLabel>
							{assigneesQueryResult.loading ? (
								<HStack w='11em' h='2.3em'>
									<Skeleton w='full' h='full' rounded='md' />
								</HStack>
							) : (
								<SelectDropdown
									placeholder='Assignees'
									hideSelectedOptions={false}
									variant='small'
									isClearable
									isMulti
									options={assigneesQueryResult.data?.users as ListUsers_users[]}
									defaultValue={
										assigneesQueryResult.data?.users?.filter(assginees =>
											filterValue?.filters?.assignees?.includes(assginees?.id || '')
										) as ListUsers_users[]
									}
									getOptionLabel={opt => opt.username}
									getOptionValue={opt => opt.id}
									getOptionTag={opt => (
										<Tag
											colorScheme='green'
											size='sm'
											textTransform={
												opt.role?.type === ENUM_ATTEMPT_TEAM.tco ? 'uppercase' : 'capitalize'
											}
										>
											{opt.role?.type}
										</Tag>
									)}
									components={{
										Option: PeopleOptionFactory<ListUsers_users>({}),
										ValueContainer: CustomValueContainer({
											selectedLabel: ['assignees', 'assignee'],
										}),
									}}
									onChange={selected => {
										const values = selected as MultiValue<ListUsers_users>

										if (!values.length) {
											onChange({ filters: { assignees: null } })
											return
										}

										onChange({ filters: { assignees: values.map(val => val.id) } })
									}}
								/>
							)}
						</FormControl>
					) : null}

					<FormControl w='15em'>
						<FormLabel color='gray.600' mb='0.2em' fontSize='0.8em'>
							Person name:
						</FormLabel>
						<InputGroup w='15em'>
							<Input
								borderColor='white'
								height='38px'
								fontSize='0.8em'
								border='1px solid #E2E8F0'
								type='text'
								placeholder={SearchFieldPlaceholders.name}
								bg='white'
								value={searchValue}
								onChange={evt => setSearchValue(evt.target.value)}
							/>
						</InputGroup>
					</FormControl>

					<FormControl w='15em'>
						<FormLabel color='gray.600' mb='0.2em' fontSize='0.8em'>
							Source:
						</FormLabel>
						<InputGroup w='15em'>
							<Input
								borderColor='white'
								height='38px'
								fontSize='0.8em'
								border='1px solid #E2E8F0'
								type='text'
								placeholder={SearchFieldPlaceholders.source}
								bg='white'
								value={searchValueSource}
								onChange={evt => setSearchValueSource(evt.target.value)}
							/>
						</InputGroup>
					</FormControl>

					<FormControl w='11em'>
						<FormLabel color='gray.600' mb='0.2em' fontSize='0.8em'>
							Clinic:
						</FormLabel>
						<SelectDropdown
							options={clinicOptions}
							defaultValue={clinicOptions.find(opt => opt.value === filterValue?.filters?.clinic)}
							placeholder='Clinic'
							variant='small'
							isClearable
							onChange={selected => {
								const option = selected as SingleValue<{ value: number }>

								if (!option) {
									onChange({ filters: { clinic: null } })
									return
								}

								onChange({ filters: { clinic: option?.value } })
							}}
						/>
					</FormControl>

					<FormControl w='12em'>
						<FormLabel color='gray.600' mb='0.2em' fontSize='0.8em'>
							Enquiries:
						</FormLabel>
						{inquiriesListQueryResult.loading ? (
							<HStack w='11em' h='2.3em'>
								<Skeleton w='full' h='full' rounded='md' />
							</HStack>
						) : (
							<SelectDropdown
								placeholder='Enquiries'
								hideSelectedOptions={false}
								variant='small'
								isClearable
								isMulti
								options={inquiriesListQueryResult.data?.inquiries as Inquiries_inquiries[]}
								defaultValue={
									inquiriesListQueryResult.data?.inquiries?.filter(inquiry =>
										filterValue?.filters?.inquiries?.includes(inquiry?.id || '')
									) as Inquiries_inquiries[]
								}
								getOptionLabel={opt => opt.name as string}
								getOptionValue={opt => opt.id}
								components={{
									ValueContainer: CustomValueContainer({
										selectedLabel: ['inquiries', 'inquiry'],
									}),
								}}
								onChange={selected => {
									const values = selected as MultiValue<Inquiries_inquiries>

									if (!values.length) {
										onChange({ filters: { inquiries: null } })
										return
									}

									onChange({ filters: { inquiries: values.map(val => val.id) } })
								}}
							/>
						)}
					</FormControl>

					<FormControl w='11em'>
						<FormLabel color='gray.600' mb='0.2em' fontSize='0.8em'>
							Appointment ID:
						</FormLabel>
						<SelectDropdown
							options={settledOptions}
							defaultValue={settledOptions.find(
								opt => opt.value === filterValue?.filters?.dentally_appointment_id
							)}
							placeholder='Appointment id'
							variant='small'
							isClearable
							onChange={selected => {
								const option = selected as SingleValue<{ value: boolean }>

								if (option === undefined) {
									onChange({ filters: { dentally_appointment_id: undefined } })
									return
								}

								onChange({ filters: { dentally_appointment_id: option?.value } })
							}}
						/>
					</FormControl>

					<FormControl w='11em'>
						<FormLabel color='gray.600' mb='0.2em' fontSize='0.8em'>
							TCO Assigned:
						</FormLabel>
						<SelectDropdown
							options={booleanOptions}
							defaultValue={booleanOptions.find(
								opt => opt.value === filterValue?.filters?.tco_assigned
							)}
							placeholder='TCO Assigned'
							variant='small'
							isClearable
							onChange={selected => {
								const option = selected as SingleValue<{ value: boolean }>

								if (option === undefined) {
									onChange({ filters: { tco_assigned: undefined } })
									return
								}

								onChange({ filters: { tco_assigned: option?.value } })
							}}
						/>
					</FormControl>

					<FormControl w='11em'>
						<FormLabel color='gray.600' mb='0.2em' fontSize='0.8em'>
							Dentist Assigned:
						</FormLabel>
						<SelectDropdown
							options={booleanOptions}
							defaultValue={booleanOptions.find(
								opt => opt.value === filterValue?.filters?.dentist_assigned
							)}
							placeholder='Dentist Assigned'
							variant='small'
							isClearable
							onChange={selected => {
								const option = selected as SingleValue<{ value: boolean }>

								if (option === undefined) {
									onChange({ filters: { dentist_assigned: undefined } })
									return
								}

								onChange({ filters: { dentist_assigned: option?.value } })
							}}
						/>
					</FormControl>

					<FormControl w='10em'>
						<FormLabel color='gray.600' mb='0.2em' fontSize='0.8em'>
							Tags:
						</FormLabel>
						{loadingTags ? (
							<HStack w='11em' h='2.3em'>
								<Skeleton w='full' h='full' rounded='md' />
							</HStack>
						) : (
							<SelectDropdown
								variant='small'
								isClearable
								isMulti
								hideSelectedOptions={false}
								components={{
									ValueContainer: CustomValueContainer({ selectedLabel: ['tags', 'tag'] }),
								}}
								isLoading={loadingTags}
								options={tagsData?.tags as FindLead_findLead_tags[]}
								defaultValue={tagsDefaultValue}
								getOptionLabel={option => option.text}
								getOptionValue={option => option.id}
								placeholder='Tags'
								onChange={selected => {
									const values = selected as MultiValue<FindLead_findLead_tags>

									if (!values) {
										onChange({ filters: { tags: null } })
										return
									}

									onChange({ filters: { tags: values.map(val => val.id) } })
								}}
							/>
						)}
					</FormControl>

					<FormControl w='15em'>
						<FormLabel color='gray.600' mb='0.2em' fontSize='0.8em'>
							Creation date:
						</FormLabel>
						<DateRangePicker defaultValue={dateRangeDefaultValue} onChange={onDateRangeChange} />
					</FormControl>

					<FormControl w='11em'>
						<FormLabel color='gray.600' mb='0.2em' fontSize='0.8em'>
							Sort value:
						</FormLabel>
						<SelectDropdown
							placeholder='Sort by..'
							variant='small'
							isClearable
							defaultValue={SortByOptions.find(opt => opt.value === sortValue)}
							options={SortByOptions}
							onChange={selected => {
								const option = selected as SingleValue<{ value: string }>

								if (!option) {
									onChange({ sort: null })
									setSortValue(null)
									return
								}

								setSortValue(option?.value)
								onChange({ sort: `${option?.value}:${sortOrder}` })
							}}
						/>
					</FormControl>

					<FormControl w='9em'>
						<FormLabel color='gray.600' mb='0.2em' fontSize='0.8em'>
							Sort order:
						</FormLabel>
						<Flex bg='white' px={4} py={2} rounded='md' shadow='sm' align='center' gap={2}>
							<Text fontSize='0.8em'>Asc</Text>
							<Switch
								isDisabled={!sortValue}
								onChange={evt => {
									const order = evt.target.checked ? 'desc' : 'asc'
									if (!sortValue) return

									onChange({ sort: `${sortValue}:${order}` })
									setSortOrder(sortOrder)
								}}
								colorScheme='twitter'
								defaultChecked={sortOrder === 'desc'}
							/>
							<Text fontSize='0.8em'>Desc</Text>
						</Flex>
					</FormControl>
				</Flex>
			</Collapse>
		</VStack>
	)
}

export default Filters
