import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	Box,
	StatArrow,
	Flex,
	Text,
} from '@chakra-ui/react'
import { formatDistance } from 'date-fns'
import { FaRegClock, FaWallet } from 'react-icons/fa'

import { FC } from 'react'

import { TransactionTypeText } from '~/constants'
import { PersonDetails_person_transaction_history } from '~/inputs/__generated__/PersonDetails'

import { ENUM_TRANSACTION_TYPE } from '../../__generated__/globalTypes'

/***
 *
 * Interface & Type
 *
 ***/
interface Props {
	isOpen: boolean
	onClose: () => void
	transactions: PersonDetails_person_transaction_history[]
}

/***
 *
 * Transactions View Component
 *
 ***/
const TransactionsView: FC<Props> = props => {
	const { isOpen, onClose, transactions } = props

	return (
		<Modal isOpen={isOpen} onClose={onClose} size='xl'>
			<ModalOverlay />
			<ModalContent as='form'>
				<ModalHeader>Transactions</ModalHeader>
				<ModalCloseButton />
				<ModalBody pt='1em' pb='2em' px='2.5em' overflowY='auto' maxH='40em'>
					{transactions.map(transaction => {
						const showIncrease = transaction.type === ENUM_TRANSACTION_TYPE.top_up

						return (
							<Flex
								key={transaction.id}
								rounded='lg'
								shadow='md'
								px='1em'
								py='0.5em'
								mb='1.5em'
								align='stretch'
							>
								<Flex align='start'>
									<Flex
										rounded='full'
										bg={showIncrease ? 'green.200' : 'red.200'}
										h='2.5em'
										w='2.5em'
										mr='1em'
										justify='center'
										align='center'
									>
										<FaWallet />
									</Flex>
								</Flex>

								<Box flex='1'>
									<Flex>
										<Flex align='baseline' gap='0.5em' mb='0.3em'>
											<Text fontWeight='medium'>{TransactionTypeText[transaction.type]}</Text>
											{transaction.medium && (
												<Text fontSize='0.85em' color='gray.400'>
													by {transaction.medium.replaceAll('_', ' ')}
												</Text>
											)}
										</Flex>

										<Flex ml='auto' align='center' gap='0.5em'>
											<StatArrow type={showIncrease ? 'increase' : 'decrease'} />
											<Text fontWeight='bold'>£ {transaction.amount / 100}</Text>
										</Flex>
									</Flex>

									{transaction.note && (
										<Flex>
											<Text color='gray.400'>{transaction.note}</Text>
										</Flex>
									)}

									<Box mt='0.5em'>
										<Flex
											fontSize='smaller'
											color='gray.400'
											align='center'
											justify='end'
											gap='0.3em'
										>
											<FaRegClock />
											{`${formatDistance(new Date(transaction.created_at), new Date())} ago`}
										</Flex>
									</Box>
								</Box>
							</Flex>
						)
					})}
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}

export default TransactionsView
