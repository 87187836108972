import { Box, Text } from '@chakra-ui/react'

import { MatchTrackerDentallyPatient_matchTrackerDentallyPatient_dentally } from '~/components/forms/CreatePatientDentally/__generated__/MatchTrackerDentallyPatient'

interface SelectDentallyPatientProps {
	dentallyData: MatchTrackerDentallyPatient_matchTrackerDentallyPatient_dentally[]
	setIsDisabled: (disabled: boolean) => void
	setDentallyPatient: (
		dentallyPatient: MatchTrackerDentallyPatient_matchTrackerDentallyPatient_dentally | null
	) => void
	dentallyPatient: MatchTrackerDentallyPatient_matchTrackerDentallyPatient_dentally | null
}

const SelectDentallyPatient: React.FC<SelectDentallyPatientProps> = ({
	dentallyData,
	setIsDisabled,
	setDentallyPatient,
	dentallyPatient,
}) => {
	const onSelect = (patient: MatchTrackerDentallyPatient_matchTrackerDentallyPatient_dentally) => {
		if (dentallyPatient?.uuid === patient.uuid) {
			setDentallyPatient(null)
			setIsDisabled(true)
		} else {
			setIsDisabled(false)
			setDentallyPatient(patient)
		}
	}

	if (!dentallyData.length) {
		return null
	}
	return (
		<Box sx={{ borderTop: '1px solid #E8E8E8', padding: '20px 30px 40px 30px' }}>
			<Text fontWeight='bold'>Select Dentally patient</Text>
			<Box>
				{dentallyData.map(patient => {
					return (
						<Box
							key={patient.id}
							onClick={() => onSelect(patient)}
							sx={{
								display: 'flex',
								borderRadius: '8px',
								marginTop: '20px',
								flexDirection: 'column',
								border: '1px solid #E8E8E8',
								marginBottom: '10px',
								cursor: 'pointer',
								padding: '10px 15px',
								background: dentallyPatient?.uuid === patient.uuid ? 'rgb(232, 240, 254)' : '#fff',
								'&:hover': {
									border: '1px solid #aeaeae',
								},
							}}
						>
							<Text
								sx={{
									span: {
										fontWeight: 'bold',
									},
								}}
							>
								<span>Name:</span> {`${patient.first_name} ${patient.last_name}`}
							</Text>
							<Text
								sx={{
									span: {
										fontWeight: 'bold',
									},
								}}
							>
								<span>Date of birth:</span> {patient.date_of_birth}
							</Text>
							<Text
								sx={{
									span: {
										fontWeight: 'bold',
									},
								}}
							>
								<span>Email:</span> {patient.email_address}
							</Text>
							<Text
								sx={{
									span: {
										fontWeight: 'bold',
									},
								}}
							>
								<span>Mobile phone:</span> {patient.mobile_phone}
							</Text>
							<Text
								sx={{
									span: {
										fontWeight: 'bold',
									},
								}}
							>
								<span>Address:</span> {patient.address_line_1}
							</Text>
							<Text
								sx={{
									span: {
										fontWeight: 'bold',
									},
								}}
							>
								<span>Dentist:</span> {patient.dentist_name}
							</Text>
							<Text
								sx={{
									span: {
										fontWeight: 'bold',
									},
								}}
							>
								<span>Site:</span> {patient.site_name}
							</Text>
						</Box>
					)
				})}
			</Box>
		</Box>
	)
}

export default SelectDentallyPatient
