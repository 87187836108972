import { Box, Flex, Tag, Text } from '@chakra-ui/react'
import { FaInfo } from 'react-icons/fa'
import {
	default as ReactSelect,
	StylesConfig,
	Props,
	OptionProps,
	ValueContainerProps,
	GroupBase,
	ControlProps,
	SingleValueProps,
	components,
} from 'react-select'

import { PropsWithChildren } from 'react'

import Button from '~/components/ui/Button'

type StyleProps = {
	variant?: 'small'
	error?: boolean
	styles?: StylesConfig
}

type StyleGroupControlProps = ControlProps<unknown, boolean, GroupBase<unknown>>

declare module 'react-select/dist/declarations/src/Select' {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	export interface Props<Option, IsMulti extends boolean, Group extends GroupBase<Option>> {
		getOptionTag?: (option: Option) => JSX.Element | null | undefined
		getOptionSublabel?: (option: Option) => string | null | undefined
	}
}

/***
 *
 * CUSTOM SELECT COMPONENT
 *
 ***/
const customStyles = <T,>(props: StyleProps): StylesConfig<T> => {
	const { variant, error, styles } = props

	return {
		container: baseStyles => ({
			...baseStyles,
			...styles?.container?.({}, {} as StyleGroupControlProps),
		}),

		menu: baseStyles => ({ ...baseStyles, zIndex: 100 }),

		control: baseStyles => ({
			...baseStyles,
			borderRadius: '0.375rem',
			borderColor: error ? 'red' : '#E2E8F0',
			cursor: 'pointer',
			outline: 'none',
			...styles?.control,

			'&:focus': {
				borderColor: error ? 'red' : '',
			},

			'&:hover': {
				borderColor: error ? 'red' : '',
			},

			...styles?.control?.({}, {} as StyleGroupControlProps),
		}),

		dropdownIndicator: baseStyles => ({
			...baseStyles,
			...styles?.dropdownIndicator?.({}, {} as StyleGroupControlProps),
		}),

		indicatorSeparator: baseStyles => ({
			...baseStyles,
			...styles?.indicatorSeparator?.({}, {} as StyleGroupControlProps),
		}),

		valueContainer: baseStyles => ({
			...baseStyles,
			minHeight: variant === 'small' ? '30px' : '40px',
			fontSize: variant === 'small' ? '0.8em' : '1em',
		}),

		singleValue: baseStyles => ({
			...baseStyles,
			...styles?.singleValue?.({}, {} as SingleValueProps<unknown, boolean, GroupBase<unknown>>),
		}),

		placeholder: baseStyles => ({ ...baseStyles, fontSize: variant === 'small' ? '0.9em' : '1em' }),

		option: (baseStyles, state) => ({
			...baseStyles,
			cursor: 'pointer',
			fontSize: variant === 'small' ? '0.8em' : '1em',
			backgroundColor: state.isSelected ? '#efccb5' : '',
			color: state.isSelected ? '#1A202C' : '',

			'&:hover': {
				backgroundColor: !state.isSelected ? '#faf1ea' : '',
			},
		}),
	}
}

const SelectDropdown = <T,>(props: PropsWithChildren<Props<T> & StyleProps>): JSX.Element => {
	const styleProps: StyleProps = {
		variant: props.variant,
		error: props.error,
		styles: props.styles,
	}

	return <ReactSelect {...props} styles={customStyles<T>(styleProps)} />
}

export default SelectDropdown

/***
 *
 * Custom Option Component
 *
 ***/
interface PeopleFactoryProps {
	onView?: (id: string) => void
}

export const PeopleOptionFactory = <T,>(props: PeopleFactoryProps) =>
	function CustomOption(optionProps: OptionProps<T>): JSX.Element {
		const { innerProps, innerRef } = optionProps
		const btnBgColor = optionProps.isSelected ? 'gray.600' : 'primary'
		const btnTextColor = optionProps.isSelected ? 'white' : 'gray.600'

		return (
			<Flex
				align='center'
				justify='space-between'
				_hover={{ bg: !optionProps.isSelected ? '#faf1ea' : '', cursor: 'pointer' }}
				bg={optionProps.isSelected ? '#efccb5' : ''}
				color={optionProps.isSelected ? 'gray.600' : 'gray.700'}
			>
				<Flex py='0.5em' px='0.8em' flex={1} alignSelf='stretch' ref={innerRef} {...innerProps}>
					<Flex align='center' gap='0.5em'>
						<Flex direction='column' gap='0.1em' justify='center'>
							<Text fontSize='0.85em' color='gray.700'>
								{optionProps.label}
							</Text>
							{optionProps.selectProps?.getOptionSublabel && (
								<Text fontSize='0.6em' color='gray.600'>
									{optionProps.selectProps?.getOptionSublabel?.(optionProps.data)}
								</Text>
							)}
						</Flex>
						{optionProps.selectProps?.getOptionTag?.(optionProps.data)}
					</Flex>
				</Flex>
				{props?.onView ? (
					<Box py='0.5em' px='0.8em'>
						<Button
							title='View'
							size='sm'
							fontSize='0.6em'
							bg={btnBgColor}
							color={btnTextColor}
							_hover={{ bg: btnBgColor, color: btnTextColor }}
							onClick={() =>
								props?.onView?.(optionProps.selectProps.getOptionValue(optionProps.data))
							}
							leftIcon={<FaInfo />}
						/>
					</Box>
				) : null}
			</Flex>
		)
	}

/***
 *
 * Custom Value Component
 *
 ***/
interface CustomValueContainerProps {
	selectedLabel?: [string, string]
}

export const CustomValueContainer = <T,>(props: CustomValueContainerProps) =>
	function ValueContainer(valueProps: ValueContainerProps<T>): JSX.Element {
		const { selectedLabel } = props
		const [singularLabel, pluralLabel] = selectedLabel || ['selected', 'selected']

		const getChildElement = () => {
			const children = valueProps?.children as [[JSX.Element[]], JSX.Element[]]
			const selectedElmsLength = children[0].length
			const textMessage = selectedElmsLength > 1 ? singularLabel : pluralLabel

			return (
				<Flex>
					<Tag size='sm'>{`${selectedElmsLength} ${textMessage}`}</Tag>
					{children[1]}
				</Flex>
			)
		}

		return (
			<components.ValueContainer {...valueProps}>
				<Flex fontSize='0.9em' align='center' px={2}>
					{!valueProps.hasValue ? valueProps.children : getChildElement()}
				</Flex>
			</components.ValueContainer>
		)
	}
