import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Flex,
	HStack,
	Skeleton,
} from '@chakra-ui/react'
import { ENUM_STAGE_TYPE } from '__generated__/globalTypes'

import { FC } from 'react'

import Button from '~/components/ui/Button'
import { StageStatusValues } from '~/constants'

/***
 *
 * Interface & Type
 *
 ***/
interface Props {
	isOpen: boolean
	onClose: () => void
	possibleNextStages: ENUM_STAGE_TYPE[]
	onSelect: (stage: ENUM_STAGE_TYPE) => void
	leadId: string
	isLoading?: boolean
}

/***
 *
 * Status Dialog  Component
 *
 ***/
const StatusConfirmDialog: FC<Props> = props => {
	const { isOpen, onClose, possibleNextStages, onSelect, isLoading } = props

	const possibleStageBtn = possibleNextStages.map((stage, index) => {
		return (
			<Button key={index} size='sm' colorScheme='yellow' onClick={() => onSelect(stage)}>
				{StageStatusValues[stage]}
			</Button>
		)
	})

	return (
		<Modal isOpen={isOpen} onClose={onClose} size='xl'>
			<ModalOverlay backdropBlur='xl' />
			<ModalContent as='form'>
				<ModalHeader>Please select the status.</ModalHeader>
				<ModalCloseButton />
				<ModalBody pb={6}>
					{isLoading ? (
						<HStack w='full' h='4em' spacing='4em'>
							<Skeleton w='full' h='full' rounded='md' />
						</HStack>
					) : (
						<Flex gap={5} flexWrap='wrap'>
							{possibleStageBtn}
						</Flex>
					)}
				</ModalBody>
				<ModalFooter>
					<Button onClick={onClose} mr={3}>
						Close
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default StatusConfirmDialog
