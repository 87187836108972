import { useQuery } from '@apollo/client'
import {
	Avatar,
	Flex,
	FormControl,
	FormLabel,
	Skeleton,
	Stack,
	Text,
	useDisclosure,
} from '@chakra-ui/react'
import { BiEdit } from 'react-icons/bi'

import React, { FC } from 'react'

import { onError } from '~/components/helpers'
import Button from '~/components/ui/Button'
import useToast from '~/components/ui/Toast'
import { AssigneeTypes } from '~/constants'
import { ListUsers, ListUsers_users, ListUsersVariables } from '~/inputs/__generated__/ListUsers'
import { listUsersQuery } from '~/inputs/assignees'
import { FindLead_findLead } from '~/views/__generated__/FindLead'
import AssigneesRoleView from '~/views/assigneesRole'

/***
 *
 * Queries & Mutations
 *
 ***/

export type AssigneesViewType = 'all' | 'tco' | 'dentist' | 'cst'

/***
 *
 * Interface & Type
 *
 ***/
interface Props {
	viewType: AssigneesViewType
	leadDetails?: FindLead_findLead
}

/***
 *
 * Assignees Input Component
 *
 ***/
const AssigneesRoleInput: FC<Props> = props => {
	const toast = useToast()
	const { data, loading } = useQuery<ListUsers, ListUsersVariables>(listUsersQuery, {
		variables: {
			json: { role: { name: AssigneeTypes.filter(type => type.toLowerCase() === props.viewType) } },
		},
		onError: error => onError(error, toast),
	})

	const { isOpen, onOpen, onClose } = useDisclosure()

	const getTitle = () => {
		switch (props.viewType) {
			case 'tco':
				return 'TCO'
			case 'dentist':
				return 'Dentist'
			case 'cst':
				return 'CST'
			default:
				return 'Assignees'
		}
	}
	const userList = data?.users?.filter(Boolean) as ListUsers_users[]

	const renderPicked = () => {
		if (props.leadDetails?.[`${props.viewType}_assignee`]) {
			const assignee = props.leadDetails?.[`${props.viewType}_assignee`]
			return (
				<Flex flexWrap='wrap' gap={5} mt='1em'>
					<Flex align='center' justify='start' key={assignee.id}>
						<Avatar size='sm' name={assignee.username} />
						<Text fontSize='1em' color='#64554B' ml={2}>
							{assignee.username}
						</Text>
					</Flex>
				</Flex>
			)
		}
		return (
			<Text mt='1em' fontSize='0.9em' color='gray.400'>
				No assignees added.
			</Text>
		)
	}

	if (loading) {
		return (
			<Stack spacing={10} w='full'>
				<Skeleton height='5em' rounded='md' />
			</Stack>
		)
	}

	return (
		<>
			<FormControl>
				<Flex align='center' justify='space-between'>
					<FormLabel
						fontSize='1em'
						color='#191C1A'
						fontWeight='bold'
						textTransform='uppercase'
						requiredIndicator={<span />}
						m={0}
					>
						{getTitle()}
					</FormLabel>
					<Button
						title='Edit'
						aria-label='Edit'
						colorScheme='transparent'
						p={0}
						size='sm'
						onClick={onOpen}
						leftIcon={<BiEdit color='#0000004d' fontSize={20} />}
					/>
				</Flex>
				{renderPicked()}
			</FormControl>
			{isOpen && userList?.length > 0 && (
				<AssigneesRoleView
					leadId={props.leadDetails?.id}
					viewType={props.viewType}
					isOpen={isOpen}
					onClose={onClose}
					userList={userList}
					defaultValue={props.leadDetails?.[`${props.viewType}_assignee`]?.id}
				/>
			)}
		</>
	)
}

export default AssigneesRoleInput
