import { gql, useLazyQuery, useMutation } from '@apollo/client'
import { FormControl, Flex, Stack, Skeleton } from '@chakra-ui/react'

import { FC, useContext, useEffect } from 'react'

import AttemptsGroup, { AttemptConfigState } from '~/components/AttemptsGroup'
import { onError } from '~/components/helpers'
import { AuthContext } from '~/components/page/context'
import useToast from '~/components/ui/Toast'
import { setKanbanRefetchVal } from '~/helpers'
import { Attempts, Attempts_attempts, AttemptsVariables } from '~/inputs/__generated__/Attempts'
import { FindLead_findLead } from '~/views/__generated__/FindLead'

import {
	AttemptInput as AttemptInputType,
	ENUM_ATTEMPT_TEAM,
} from '../../__generated__/globalTypes'
import { CreateAttempt, CreateAttemptVariables } from './__generated__/CreateAttempt'
import { DeleteAttempt, DeleteAttemptVariables } from './__generated__/DeleteAttempt'

/***
 *
 * Queries & Mutations
 *
 ***/
export const attemptsQuery = gql`
	query Attempts($json: JSON) {
		attempts(where: $json) {
			id
			type
			team
		}
	}
`

export const createAttemptMutation = gql`
	mutation CreateAttempt($input: createAttemptInput!) {
		createAttempt(input: $input) {
			attempt {
				id
				type
				team
			}
		}
	}
`

const deleteAttemptMutation = gql`
	mutation DeleteAttempt($input: deleteAttemptInput!) {
		deleteAttempt(input: $input) {
			attempt {
				id
				type
				team
			}
		}
	}
`

/***
 *
 * Interface & Type
 *
 ***/
interface Props {
	leadDetails?: FindLead_findLead
}

/***
 *
 * Attempt Input Component
 *
 ***/
const AttemptInput: FC<Props> = props => {
	const toast = useToast()
	const user = useContext(AuthContext)
	const { leadDetails } = props

	const [fetchAttempts, { data, loading, refetch }] = useLazyQuery<Attempts, AttemptsVariables>(
		attemptsQuery
	)

	const [createAttempt] = useMutation<CreateAttempt, CreateAttemptVariables>(
		createAttemptMutation,
		{
			onError: error => onError(error, toast),
		}
	)

	const [deleteAttempt] = useMutation<DeleteAttempt, DeleteAttemptVariables>(
		deleteAttemptMutation,
		{
			onError: error => onError(error, toast),
		}
	)

	const createAttemptHandler = async (attemptConfig: AttemptConfigState) => {
		const attemptData: AttemptInputType = {
			team: attemptConfig.team,
			lead_id: leadDetails?.id,
			type: attemptConfig.type,
			owner: user.id,
		}

		const { data, errors } = await createAttempt({
			variables: { input: { data: attemptData } },
		})

		if (!errors && data?.createAttempt?.attempt?.id) {
			await refetch?.({ json: { lead_id: leadDetails?.id } })
			toast({ title: 'Attempt created.', status: 'success', position: 'top-right' })
		}
	}

	const onConfirmHandler = async (attemptConfig: AttemptConfigState) => {
		setKanbanRefetchVal('true')

		if (attemptConfig.create) {
			await createAttemptHandler(attemptConfig)
			return
		}

		const { errors } = await deleteAttempt({
			variables: { input: { where: { id: attemptConfig?.id as string } } },
		})

		if (!errors) {
			await refetch?.({ json: { lead_id: leadDetails?.id } })
			toast({ title: 'Attempt removed.', status: 'info', position: 'top-right' })
		}
	}

	useEffect(() => {
		const fetcher = async () =>
			await fetchAttempts({ variables: { json: { lead_id: leadDetails?.id } } })
		fetcher()
	}, [])

	if (loading)
		return (
			<Flex width='100%'>
				<Stack spacing={10} w='full' mr={2}>
					<Skeleton height='8em' rounded='md' />
				</Stack>
				<Stack spacing={10} w='full' ml={2}>
					<Skeleton height='8em' rounded='md' />
				</Stack>
			</Flex>
		)
	return (
		<>
			<FormControl>
				<Flex width='100%'>
					<AttemptsGroup
						leadId={leadDetails?.id as string}
						team={ENUM_ATTEMPT_TEAM.reception}
						attempts={data?.attempts as Attempts_attempts[]}
						onConfirm={onConfirmHandler}
					/>
					<AttemptsGroup
						leadId={leadDetails?.id as string}
						team={ENUM_ATTEMPT_TEAM.tco}
						attempts={data?.attempts as Attempts_attempts[]}
						onConfirm={onConfirmHandler}
					/>
				</Flex>
			</FormControl>
		</>
	)
}

export default AttemptInput
