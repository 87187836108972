import {
	Box,
	Step,
	StepDescription,
	StepIcon,
	StepIndicator,
	StepNumber,
	Stepper,
	StepSeparator,
	StepStatus,
	StepTitle,
	useSteps,
} from '@chakra-ui/react'

import React, { useState } from 'react'

import CreateDentallyPatientConfirmation from '~/components/forms/CreatePatientDentally/CreateDentallyPatientConfirmation'
import CreateDentallyPatientFormEmail from '~/components/forms/CreatePatientDentally/CreateDentallyPatientEmailForm'
import CreateDentallyPatientForm from '~/components/forms/CreatePatientDentally/CreateDentallyPatientForm'
import { MatchTrackerDentallyPatient_matchTrackerDentallyPatient_dentally } from '~/components/forms/CreatePatientDentally/__generated__/MatchTrackerDentallyPatient'
import CreatePatientForm from '~/components/forms/CreatePatientForm'
import CreatePatientTracker from '~/components/forms/CreatePatientTracker'

const steps = [
	{ title: 'Step 01', description: 'Email Info' },
	{ title: 'Step 02', description: 'Additional Info' },
	{ title: 'Step 03', description: 'Confirmation' },
]

export enum FormToRender {
	trackerPatientForm = 'trackerPatientForm',
	dentallyPatientForm = 'dentallyPatientForm',
	fullPatientForm = 'fullPatientForm',
	default = 'fullPatientForm',
}

interface CreatePatientDentallyProps {
	onClose: () => void
}

export interface CreatePatientFormValues {
	first_name?: string
	last_name?: string
	email?: string | null
	phone?: string | null
}

const CreatePatientFormWrapper: React.FC<CreatePatientDentallyProps> = ({ onClose }) => {
	const { activeStep, goToNext, goToPrevious } = useSteps({
		index: 0,
		count: steps.length,
	})
	const [formToRender, setFormToRender] = useState<FormToRender>(FormToRender.default)
	const [dentallyPatient, setDentallyPatient] =
		useState<MatchTrackerDentallyPatient_matchTrackerDentallyPatient_dentally | null>(null)
	const [trackerPatientId, setTrackerPatientId] = useState<string | null>(null)
	const [defaultFormValues, setDefaultFormValues] = useState<CreatePatientFormValues | null>(null)

	const renderForm = () => {
		if (activeStep === 1) {
			switch (formToRender) {
				case FormToRender.trackerPatientForm:
					return (
						<CreatePatientTracker
							onClose={onClose}
							dentallyPatient={dentallyPatient}
							defaultFormValues={defaultFormValues}
						/>
					)
				case FormToRender.dentallyPatientForm:
					return (
						<CreateDentallyPatientForm
							goToPrevious={goToPrevious}
							defaultFormValues={defaultFormValues}
							trackerPatientId={trackerPatientId}
						/>
					)
				case FormToRender.fullPatientForm:
				default:
					return (
						<CreatePatientForm
							goToNext={goToNext}
							goToPrevious={goToPrevious}
							defaultFormValues={defaultFormValues}
						/>
					)
			}
		}
		return null
	}
	return (
		<>
			<Stepper index={activeStep} sx={{ padding: '30px 40px', borderBottom: '1px solid #E8E8E8' }}>
				{steps.map((step, index) => (
					<Step key={index}>
						<StepIndicator>
							<StepStatus
								complete={<StepIcon />}
								incomplete={<StepNumber />}
								active={<StepNumber />}
							/>
						</StepIndicator>

						<Box flexShrink={0}>
							<StepTitle>{step.title}</StepTitle>
							<StepDescription>{step.description}</StepDescription>
						</Box>
						<StepSeparator />
					</Step>
				))}
			</Stepper>
			{activeStep === 0 && (
				<CreateDentallyPatientFormEmail
					onClose={onClose}
					setDefaultFormValues={setDefaultFormValues}
					goToNext={goToNext}
					setFormToRender={setFormToRender}
					setDentallyPatient={setDentallyPatient}
					setTrackerPatientId={setTrackerPatientId}
					dentallyPatient={dentallyPatient}
				/>
			)}
			{renderForm()}
			{activeStep === 2 && <CreateDentallyPatientConfirmation onClose={onClose} />}
		</>
	)
}

export default CreatePatientFormWrapper
