import { Flex, FormLabel, Text } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { UseFormReturn } from 'react-hook-form'

import React, { FC } from 'react'

import AlarmInput from '~/inputs/alarm'
import AttemptInput from '~/inputs/attempt'
import CallbackInfoInput from '~/inputs/callbackInfo'
import NotesInput from '~/inputs/notes'
import { FindLead_findLead } from '~/views/__generated__/FindLead'

import {
	ENUM_STAGE_TYPE,
	StageInput as StageDataInput,
} from '../../../../__generated__/globalTypes'

interface ContactAttemptsTabProps {
	leadDetails?: FindLead_findLead
	mode: 'add' | 'edit'
	createStageForm: UseFormReturn<StageDataInput, unknown>
	onRefetch: () => Promise<void>
}

const StyledCallbackInfoWrapper = styled(Flex)`
	flex-direction: column;
	width: 100%;
	border: 1px solid rgba(100, 85, 75, 0.2);
	border-radius: 8px;
	padding: 18px;
	margin-left: 10px;
`

const ContactAttemptsTab: FC<ContactAttemptsTabProps> = props => {
	const { leadDetails, mode, onRefetch } = props
	const inEditMode = mode === 'edit'
	const currentStageType = leadDetails?.current_stage?.type as ENUM_STAGE_TYPE

	return (
		<Flex direction='column'>
			<Flex>
				<AttemptInput leadDetails={leadDetails} />
			</Flex>
			<Flex margin='20px 0'>
				<AlarmInput leadDetails={leadDetails} onRefetchLead={onRefetch} />
				{[ENUM_STAGE_TYPE.contacted, ENUM_STAGE_TYPE.TX_given].includes(currentStageType) ? (
					<CallbackInfoInput editMode={inEditMode} stageId={leadDetails?.current_stage?.id} />
				) : (
					<StyledCallbackInfoWrapper>
						<FormLabel
							fontSize='sm'
							color='#64554B'
							fontWeight={400}
							textTransform='capitalize'
							marginBottom='7px'
						>
							Callback Info
						</FormLabel>
						<Text textAlign='left' color='#64554B' fontWeight={700} fontSize='1em'>
							Info can be set on callback stage only.
						</Text>
					</StyledCallbackInfoWrapper>
				)}
			</Flex>
			<Flex direction='column'>
				<NotesInput leadId={leadDetails?.id} notes={leadDetails?.notes} onRefetch={onRefetch} />
			</Flex>
		</Flex>
	)
}

export default ContactAttemptsTab
