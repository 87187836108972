import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Text,
	FormLabel,
	FormControl,
	Textarea,
} from '@chakra-ui/react'

import { FC, useState } from 'react'

import Button from '~/components/ui/Button'

interface Props {
	title?: string
	message?: string | JSX.Element
	variant?: 'add' | 'delete'
	withNote?: boolean
	placeholder?: string
	isOpen: boolean
	onClose: () => void
	onConfirm: (note?: string) => Promise<void>
}

const ConfirmPopup: FC<Props> = props => {
	const { isOpen, onClose, onConfirm, title, message, variant, withNote, placeholder } = props

	const [loading, setLoading] = useState(false)
	const [note, setNote] = useState('')

	const btnColorScheme = variant === 'add' ? 'green' : 'red'

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>{title ?? 'Are you sure ?'}</ModalHeader>
				<ModalCloseButton isDisabled={loading} />
				<ModalBody>
					{message && (
						<Text color='gray.600' fontWeight='bold' mb='1rem'>
							{message}
						</Text>
					)}

					{withNote && (
						<FormControl mt='2em'>
							<FormLabel>Note</FormLabel>
							<Textarea
								rows={3}
								value={note}
								placeholder={placeholder || 'Enter your note'}
								maxLength={255}
								onChange={evt => setNote(evt.target.value)}
							/>
						</FormControl>
					)}
				</ModalBody>

				<ModalFooter>
					<Button
						colorScheme='gray'
						mr={3}
						onClick={() => {
							setNote('')
							onClose()
						}}
						isDisabled={loading}
					>
						Cancel
					</Button>
					<Button
						colorScheme={btnColorScheme}
						isDisabled={loading}
						isLoading={loading}
						onClick={async () => {
							setLoading(true)
							await onConfirm(note)
							setLoading(false)
						}}
					>
						Confirm
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default ConfirmPopup
