import { gql, useMutation } from '@apollo/client'
import {
	FormControl,
	FormLabel,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Textarea,
} from '@chakra-ui/react'
import { editStageInput, ENUM_STAGE_FAILURE_TAG } from '__generated__/globalTypes'
import { SingleValue } from 'react-select'

import { FC, useState } from 'react'

import { onError } from '~/components/helpers'
import Button from '~/components/ui/Button'
import SelectDropdown from '~/components/ui/SelectDropdown'
import useToast from '~/components/ui/Toast'
import { FailureTagsOptions } from '~/constants'

import {
	UpdateFailureReason,
	UpdateFailureReasonVariables,
} from './__generated__/UpdateFailureReason'

/***
 *
 * Queries & Mutations
 *
 ***/
const updateFailureReasonMutation = gql`
	mutation UpdateFailureReason($input: updateStageInput!) {
		updateStage(input: $input) {
			stage {
				id
				failure_tag
				failure_notes
			}
		}
	}
`

/***
 *
 * Interface & Type
 *
 ***/
interface Props {
	stageId?: string
	isOpen: boolean
	onClose: () => void
	failureTag?: ENUM_STAGE_FAILURE_TAG | null
	failureNotes?: string | null
	onAdd?: (param: FailureReason) => void
	editMode?: boolean
}

export type FailureReason = {
	failure_tag?: ENUM_STAGE_FAILURE_TAG | null
	failure_notes?: string | null
}

/***
 *
 * Failed Reason View Component
 *
 ***/
const FailedReasonView: FC<Props> = props => {
	const { isOpen, onClose, failureTag, failureNotes, onAdd, editMode, stageId } = props
	const toast = useToast()

	const [failureReason, setFailureReason] = useState<editStageInput>({
		failure_tag: failureTag,
		failure_notes: failureNotes,
	})

	const [updateFailureReason] = useMutation<UpdateFailureReason, UpdateFailureReasonVariables>(
		updateFailureReasonMutation,
		{
			onError: error => onError(error, toast),
		}
	)

	const handleSubmit = async () => {
		if (editMode && stageId) {
			await updateFailureReason({
				variables: { input: { where: { id: stageId }, data: { ...failureReason } } },
			})

			onClose()
			toast({ title: 'Failed reason updated.', status: 'success', position: 'top-right' })
			return
		}

		onAdd?.(failureReason)
	}

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay backdropBlur='xl' />
			<ModalContent>
				<ModalHeader>{editMode ? 'Edit' : 'Add'} Failed reason</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<FormControl isRequired mb='1em'>
						<FormLabel fontSize='sm' color='gray.500'>
							Tags
						</FormLabel>
						<SelectDropdown
							variant='small'
							options={FailureTagsOptions}
							defaultValue={FailureTagsOptions.find(opt => opt.value === failureTag)}
							placeholder='Select failed tags'
							onChange={selected => {
								const option = selected as SingleValue<{ value: ENUM_STAGE_FAILURE_TAG }>
								setFailureReason({ ...failureReason, failure_tag: option?.value })
							}}
						/>
					</FormControl>

					<FormControl>
						<FormLabel fontSize='sm' color='gray.500'>
							Notes
						</FormLabel>
						<Textarea
							mt='0.5em'
							rows={3}
							placeholder='Failure notes'
							defaultValue={failureNotes ?? ''}
							maxLength={255}
							onChange={evt =>
								setFailureReason({ ...failureReason, failure_notes: evt?.target.value })
							}
						/>
					</FormControl>
				</ModalBody>
				<ModalFooter mt={2}>
					<Button onClick={onClose} mr={3}>
						Cancel
					</Button>
					<Button colorScheme='green' type='button' onClick={handleSubmit}>
						Save
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default FailedReasonView
