import { gql, useMutation } from '@apollo/client'
import { Box, Flex, FormControl, FormLabel, Input, Text } from '@chakra-ui/react'
import { useFormik } from 'formik'
import { navigate } from 'gatsby'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import * as Yup from 'yup'

import React, { useState } from 'react'

import {
	CreatePatientFormValues,
	FormToRender,
} from '~/components/forms/CreatePatientDentally/CreatePatientFormWrapper'
import FormPicker from '~/components/forms/CreatePatientDentally/FormPicker'
import SelectDentallyPatient from '~/components/forms/CreatePatientDentally/SelectDentallyPatient'
import {
	MatchTrackerDentallyPatient,
	MatchTrackerDentallyPatient_matchTrackerDentallyPatient_dentally,
	MatchTrackerDentallyPatient_matchTrackerDentallyPatient_tracker,
	MatchTrackerDentallyPatientVariables,
} from '~/components/forms/CreatePatientDentally/__generated__/MatchTrackerDentallyPatient'
import { onError } from '~/components/helpers'
import { UpdatePerson, UpdatePersonVariables } from '~/views/__generated__/UpdatePerson'
import { updatePersonMutation } from '~/views/person'

import Button from '../../ui/Button'
import useToast from '../../ui/Toast'

export const matchTrackerDentallyPatientMutation = gql`
	mutation MatchTrackerDentallyPatient($params: matchTrackerDentallyParams!) {
		matchTrackerDentallyPatient(params: $params) {
			tracker {
				id
				created_at
				updated_at
				phone
				email
				first_name
				last_name
				middle_name
				dentally_uuid
				dentally_person_id
			}
			dentally {
				id
				date_of_birth
				first_name
				last_name
				email_address
				mobile_phone
				uuid
				address_line_1
				site_name
				dentist_name
			}
		}
	}
`

enum RedirectType {
	CREATE_TRACKER_PATIENT = 'CREATE_TRACKER_PATIENT',
	REDIRECT_TO_TRACKER_PATIENT = 'REDIRECT_TO_TRACKER_PATIENT',
}

interface CreateDentallyPatientFormEmailProps {
	onClose: () => void
	setFormToRender: (form: FormToRender) => void
	goToNext: () => void
	setDentallyPatient: (
		dentallyPatient: MatchTrackerDentallyPatient_matchTrackerDentallyPatient_dentally | null
	) => void
	dentallyPatient: MatchTrackerDentallyPatient_matchTrackerDentallyPatient_dentally | null
	setTrackerPatientId: (trackerPatientId: string | null) => void
	setDefaultFormValues: (defaultFormValues: CreatePatientFormValues | null) => void
}

const CreateDentallyPatientFormEmail: React.FC<CreateDentallyPatientFormEmailProps> = ({
	onClose,
	setFormToRender,
	goToNext,
	setDentallyPatient,
	dentallyPatient,
	setTrackerPatientId,
	setDefaultFormValues,
}) => {
	const [dentallyPatients, setDentallyPatients] = useState<
		MatchTrackerDentallyPatient_matchTrackerDentallyPatient_dentally[]
	>([])
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [isDisabled, setIsDisabled] = useState<boolean>(false)
	const [validationError, setValidationError] = useState<string | null>(null)
	const [selectableRedirectType, setSelectableRedirectType] = useState<RedirectType | null>(null)
	const [trackerPatient, setTrackerPatient] =
		useState<MatchTrackerDentallyPatient_matchTrackerDentallyPatient_tracker | null>(null)
	const [showFormPicker, setShowFormPicker] = useState<boolean>(false)

	const [matchPatients] = useMutation<
		MatchTrackerDentallyPatient,
		MatchTrackerDentallyPatientVariables
	>(matchTrackerDentallyPatientMutation, {
		onError: error => onError(error, toast),
	})

	const [updatePerson] = useMutation<UpdatePerson, UpdatePersonVariables>(updatePersonMutation, {
		onError: error => onError(error, toast),
	})

	const dentallyPatientEmailValidationSchema = Yup.object().shape({
		email: Yup.string().email('Invalid email address'),
		mobile_phone: Yup.string(),
	})

	const formik = useFormik({
		initialValues: {
			email: '',
			mobile_phone: '',
		},
		validateOnChange: false,
		validationSchema: dentallyPatientEmailValidationSchema,
		onSubmit: (values: { email: string; mobile_phone: string }) => {
			submitHandler(values)
		},
	})

	const submitHandler = async (formData: { email: string; mobile_phone: string }) => {
		if (Object.values(formData).every(isEmpty)) {
			setValidationError('Please fill in at least one field')
			return
		}
		setShowFormPicker(false)
		setIsLoading(true)
		const { data } = await matchPatients({
			variables: {
				params: {
					email: formData.email,
					mobile_phone: formData.mobile_phone,
				},
			},
		})
		if (data?.matchTrackerDentallyPatient) {
			const trackerData = data?.matchTrackerDentallyPatient?.tracker
			const dentallyData = data?.matchTrackerDentallyPatient?.dentally
			if (!trackerData.length && !dentallyData.length) {
				setIsLoading(false)
				console.log('CREATE TRACKER AND DENTALLY PATIENT')
				setDefaultFormValues({
					email: formData.email,
					phone: formData.mobile_phone,
				})
				return setShowFormPicker(true)
			}
			if (!trackerData.length && dentallyData.length === 1) {
				setIsLoading(false)
				console.log('CREATE TRACKER PATIENT')
				dentallyData[0] && setDentallyPatient(dentallyData[0])
				setDefaultFormValues({
					first_name: dentallyData[0]?.first_name,
					last_name: dentallyData[0]?.last_name,
					email: dentallyData[0]?.email_address,
					phone: dentallyData[0]?.mobile_phone,
				})
				setFormToRender(FormToRender.trackerPatientForm)
				return goToNext()
			}
			if (!trackerData.length && dentallyData.length > 1) {
				setIsLoading(false)
				setIsDisabled(false)
				setDentallyPatients(dentallyData)
				setSelectableRedirectType(RedirectType.CREATE_TRACKER_PATIENT)
				return console.log('SELECT DENTALLY PATIENT AND CREATE TRACKER PATIENT')
			}
			if (trackerData.length && !dentallyData.length) {
				setIsLoading(false)
				console.log('CREATE DENTALLY PATIENT')
				setTrackerPatientId(trackerData[0].id)
				setDefaultFormValues({
					first_name: trackerData[0]?.first_name,
					last_name: trackerData[0]?.last_name || '',
					email: trackerData[0]?.email,
					phone: trackerData[0]?.phone,
				})
				setFormToRender(FormToRender.dentallyPatientForm)
				return goToNext()
			}
			if (trackerData.length && trackerData[0].dentally_uuid && dentallyData.length === 1) {
				setIsLoading(false)
				console.log('REDIRECT TO TRACKER PERSON')
				return navigate(`/person/${trackerData[0].id}`)
			}
			if (trackerData.length && trackerData[0].dentally_uuid && dentallyData.length > 1) {
				setIsLoading(false)
				setIsDisabled(false)
				setDentallyPatients(dentallyData)
				setTrackerPatientId(trackerData[0].id)
				setTrackerPatient(trackerData[0])
				setSelectableRedirectType(RedirectType.REDIRECT_TO_TRACKER_PATIENT)
				return console.log('SELECT DENTALLY PATIENT')
			}
			if (trackerData.length && !trackerData[0].dentally_uuid && dentallyData.length === 1) {
				console.log('ADD DENTALLY UUID TO TRACKER AND REDIRECT TO TRACKER PERSON')
				setIsLoading(true)
				setIsDisabled(true)
				const { errors } = await updatePerson({
					variables: {
						personData: {
							where: { id: trackerData[0].id },
							data: {
								...omit(trackerData[0], ['id', 'created_at', 'updated_at', '__typename']),
								dentally_uuid: dentallyData[0].uuid,
								dentally_person_id: dentallyData[0].id.toString(),
							},
						},
					},
				})
				if (!errors) {
					setIsDisabled(true)
					setIsLoading(false)
					return navigate(`/person/${trackerData[0].id}`)
				}
			}
			if (trackerData.length && !trackerData[0].dentally_uuid && dentallyData.length > 1) {
				setIsLoading(false)
				setIsDisabled(false)
				setDentallyPatients(dentallyData)
				setTrackerPatientId(trackerData[0].id)
				setTrackerPatient(trackerData[0])
				setSelectableRedirectType(RedirectType.REDIRECT_TO_TRACKER_PATIENT)
				return console.log('SELECT DENTALLY PATIENT')
			}
		}
	}

	const handleCancel = () => {
		setShowFormPicker(false)
		onClose()
	}

	const toast = useToast()

	const showTrackerFormSelectable =
		selectableRedirectType === RedirectType.CREATE_TRACKER_PATIENT &&
		dentallyPatients.length &&
		dentallyPatient

	const redirectToTrackerPatientSelectable =
		selectableRedirectType === RedirectType.REDIRECT_TO_TRACKER_PATIENT &&
		dentallyPatients.length &&
		dentallyPatient

	const onSubmit = async () => {
		if (showTrackerFormSelectable) {
			setDefaultFormValues({
				first_name: dentallyPatient?.first_name,
				last_name: dentallyPatient?.last_name,
				email: dentallyPatient?.email_address,
				phone: dentallyPatient?.mobile_phone,
			})
			setFormToRender(FormToRender.trackerPatientForm)
			return goToNext()
		}
		if (redirectToTrackerPatientSelectable && trackerPatient) {
			setIsLoading(true)
			setIsDisabled(true)
			const { errors } = await updatePerson({
				variables: {
					personData: {
						where: { id: trackerPatient.id },
						data: {
							...omit(trackerPatient, ['id', 'created_at', 'updated_at', '__typename']),
							dentally_uuid: dentallyPatient?.uuid,
							dentally_person_id: dentallyPatient?.id.toString(),
						},
					},
				},
			})
			if (!errors) {
				setIsDisabled(true)
				setIsLoading(false)
				return navigate(`/person/${trackerPatient.id}`)
			}
		}
		return submitHandler(formik.values)
	}

	const handleRenderFullPatientForm = () => {
		setFormToRender(FormToRender.fullPatientForm)
		return goToNext()
	}

	const handleRenderTrackerPatientForm = () => {
		setFormToRender(FormToRender.trackerPatientForm)
		return goToNext()
	}

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				height: 'calc(100% - 106px)',
				form: {
					display: 'flex',
					justifyContent: 'space-between',
					flexDirection: 'column',
					height: '100%',
				},
			}}
		>
			<form
				onSubmit={e => {
					e.preventDefault()
					onSubmit()
				}}
			>
				<Box>
					<Flex
						align='center'
						justify='space-between'
						gap='1em'
						flexWrap='wrap'
						mb={3}
						sx={{ padding: '40px 30px' }}
					>
						<FormControl w='18em' isInvalid={Boolean(formik?.errors?.email)}>
							<FormLabel>Email Address</FormLabel>
							<Input
								type='email'
								name='email'
								required={true}
								value={formik.values.email}
								onChange={event => {
									setValidationError(null)
									formik.setFieldValue('email', event.target.value)
								}}
								placeholder='Type here'
							/>
						</FormControl>
						<FormControl w='18em' isInvalid={Boolean(formik?.errors?.mobile_phone)}>
							<FormLabel>Mobile Phone</FormLabel>
							<Input
								type='number'
								name='mobile_phone'
								required={true}
								value={formik.values.mobile_phone}
								onChange={event => {
									setValidationError(null)
									formik.setFieldValue('mobile_phone', event.target.value)
								}}
								placeholder='Mobile phone'
							/>
						</FormControl>
						{validationError ? (
							<Text fontSize='sm' color='red.400' mt='0.5em'>
								{validationError}
							</Text>
						) : null}
					</Flex>
					{showFormPicker && (
						<FormPicker
							handleRenderFullPatientForm={handleRenderFullPatientForm}
							handleRenderTrackerPatientForm={handleRenderTrackerPatientForm}
						/>
					)}
					<SelectDentallyPatient
						dentallyData={dentallyPatients}
						setIsDisabled={setIsDisabled}
						setDentallyPatient={setDentallyPatient}
						dentallyPatient={dentallyPatient}
					/>
				</Box>
				<Box
					sx={{
						background: '#FFF8F3',
						padding: '20px 40px',
						color: '#fff',
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<Button
						type='button'
						onClick={onSubmit}
						isLoading={isLoading}
						sx={{
							background: '#2D3748',
							color: '#fff',
							fontSize: '17px',
							height: '40px',
							marginRight: '15px',
							width: '160px',
							textTransform: 'uppercase',
							borderRadius: '6px',
							_hover: {
								background: '#47546b',
							},
						}}
						isDisabled={isDisabled || isLoading}
					>
						Search
					</Button>
					<Button
						type='button'
						sx={{
							background: 'transparent',
							color: '#FF3A53',
							border: '2px solid #FF3A53',
							fontSize: '17px',
							height: '40px',
							width: '160px',
							textTransform: 'uppercase',
							borderRadius: '6px',
							_hover: {
								background: 'rgb(241 46 71 / 13%)',
							},
						}}
						onClick={handleCancel}
					>
						Cancel
					</Button>
				</Box>
			</form>
		</Box>
	)
}

export default CreateDentallyPatientFormEmail
