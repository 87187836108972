import { gql, useMutation } from '@apollo/client'
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	FormControl,
	FormLabel,
	Input,
	Textarea,
	Flex,
	InputGroup,
	InputLeftAddon,
} from '@chakra-ui/react'
import { ENUM_TRANSACTION_MEDIUM, ENUM_TRANSACTION_TYPE } from '__generated__/globalTypes'
import { useForm, Controller } from 'react-hook-form'
import { SingleValue } from 'react-select'

import { FC, useContext } from 'react'

import { TransactionMediumOptions } from '~/constants'

import { AuthContext } from '../page/context'
import Button from '../ui/Button'
import SelectDropdown from '../ui/SelectDropdown'
import useToast from '../ui/Toast'
import { CreateTransaction, CreateTransactionVariables } from './__generated__/CreateTransaction'

/***
 *
 * Queries & Mutations
 *
 ***/
export const createTransactionMutation = gql`
	mutation CreateTransaction($input: createTransactionInput!) {
		createTransaction(input: $input) {
			transaction {
				id
			}
		}
	}
`

/***
 *
 * Interface & Type
 *
 ***/
interface Props {
	isOpen: boolean
	onClose: () => void
	personId: string
	mode?: 'top_up' | 'refund'
	leadId: string
	onRefetch: () => Promise<void>
}

type FormType = { amount: number; medium: string; note?: string }

/***
 *
 * BalanceForm
 *
 ***/
const BalanceForm: FC<Props> = props => {
	const { isOpen, onClose, personId, onRefetch, mode = 'top_up', leadId } = props
	const isTopUpForm = mode === 'top_up'
	const user = useContext(AuthContext)
	const toast = useToast()

	const [createTransaction] = useMutation<CreateTransaction, CreateTransactionVariables>(
		createTransactionMutation
	)

	const { control, register, handleSubmit, formState } = useForm<FormType>({ mode: 'onChange' })

	const submitHandler = async (formData: FormType) => {
		const amountInPennies = formData.amount * 100

		const inputData = {
			amount: amountInPennies,
			...(isTopUpForm && { medium: formData.medium as ENUM_TRANSACTION_MEDIUM }),
			type: mode as ENUM_TRANSACTION_TYPE,
			owner: user.id,
			note: formData.note,
			origin: personId,
			leadId,
		}

		await createTransaction({ variables: { input: { data: inputData } } })
		await onRefetch()

		onClose()
		toast({
			title: isTopUpForm ? 'Funds added' : 'Amount succesfully refunded',
			status: 'success',
			position: 'top-right',
		})
	}

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay backdropBlur='xl' />

			<ModalContent as='form'>
				<ModalHeader>{isTopUpForm ? 'Add Funds' : 'Refund'}</ModalHeader>
				<ModalCloseButton isDisabled={formState.isSubmitting} />
				<ModalBody>
					<Flex mb='1em' align='center' gap='1em'>
						<FormControl isRequired>
							<FormLabel>Amount</FormLabel>
							<InputGroup
								borderColor={formState.errors.amount ? 'red' : 'gray.200'}
								w={isTopUpForm ? 'full' : '15em'}
							>
								<InputLeftAddon bg='brown.100' h='2.4em' pointerEvents='none'>
									£
								</InputLeftAddon>
								<Input
									_hover={{ borderColor: 'none' }}
									_focus={{ borderColor: 'none' }}
									type='number'
									{...register('amount', { required: true, valueAsNumber: true })}
									h='2.4em'
								/>
							</InputGroup>
						</FormControl>
						{isTopUpForm && (
							<FormControl isRequired>
								<FormLabel>Medium</FormLabel>
								<Controller
									name='medium'
									control={control}
									rules={{ required: true }}
									render={({ field: { onChange } }) => (
										<SelectDropdown
											variant='small'
											options={TransactionMediumOptions}
											onChange={selected => {
												const option = selected as SingleValue<{ label: string; value: string }>
												onChange(option?.value)
											}}
											error={formState?.errors?.medium ? true : false}
										/>
									)}
								/>
							</FormControl>
						)}
					</Flex>
					<FormControl>
						<FormLabel>Note</FormLabel>
						<Textarea {...register('note')} />
					</FormControl>
				</ModalBody>

				<ModalFooter>
					<Button onClick={onClose} mr={3} isDisabled={formState.isSubmitting}>
						Cancel
					</Button>
					<Button
						colorScheme='green'
						type='button'
						isLoading={formState.isSubmitting}
						isDisabled={formState.isSubmitting}
						onClick={handleSubmit(submitHandler)} // Using handleSubmit on button to avoid event bubbling, as this is a nested form.
					>
						Confirm
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default BalanceForm
