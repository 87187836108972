import { gql, useQuery } from '@apollo/client'
import { PhoneIcon } from '@chakra-ui/icons'
import { Stack, Skeleton, Flex, GridItem, Tag, Grid, Text, Box } from '@chakra-ui/react'
import { ENUM_AUDIT_ENTITY } from '__generated__/globalTypes'
import { format } from 'date-fns'
import {
	FaRegClock,
	FaEnvelope,
	FaRegStickyNote,
	FaCodeBranch,
	FaRegUserCircle,
	FaMoneyCheckAlt,
	FaUserAlt,
} from 'react-icons/fa'

import React, { FC } from 'react'

import { AuditLogs, AuditLogsVariables, AuditLogs_audits } from './__generated__/AuditLogs'

/***
 *
 * Queries & Mutations
 *
 ***/
const auditLogsQuery = gql`
	query AuditLogs($leadId: ID!) {
		audits(sort: "created_at:desc", where: { origin: $leadId }) {
			id
			origin {
				id
			}
			owner {
				id
				username
			}
			text
			entity
			action
			created_at
		}
	}
`

/***
 *
 * Interface & Type
 *
 ***/
interface Props {
	leadId: string
}

/***
 *
 * Audit logs View Component
 *
 ***/
const AuditLogsView: FC<Props> = props => {
	const { leadId } = props

	const { data, loading } = useQuery<AuditLogs, AuditLogsVariables>(auditLogsQuery, {
		variables: { leadId },
		fetchPolicy: 'network-only',
	})

	const allLogs = (data?.audits as AuditLogs_audits[]) ?? []

	console.log(allLogs)

	const loadingFragment = (
		<Stack spacing={10} w='full'>
			<Skeleton height='3em' rounded='md' />
			<Skeleton height='3em' rounded='md' />
			<Skeleton height='3em' rounded='md' />
			<Skeleton height='3em' rounded='md' />
		</Stack>
	)

	return (
		<Flex direction='column'>
			{loading ? (
				<Box py='1em' pr='2.5em'>
					{loadingFragment}
				</Box>
			) : (
				<Grid
					gridTemplateColumns='4em 1fr'
					gridAutoRows='auto'
					py='1em'
					pr='2.5em'
					rowGap='3em'
					columnGap='0.8em'
					maxH='30em'
					overflowY='auto'
				>
					{allLogs?.map((log, index) => (
						<React.Fragment key={index}>
							<GridItem
								position='relative'
								_after={{
									display: allLogs.length === index + 1 ? 'none' : 'block',
									content: '""',
									position: 'absolute',
									width: '0.2em',
									backgroundColor: '#AF9179',
									top: '2em',
									left: 0,
									right: 0,
									bottom: 'calc(3em * -1)',
									margin: '0 auto',
								}}
							>
								<Flex
									align='center'
									justify='center'
									bg='#AF9179'
									w='62px'
									h='62px' // CSS:IMPORTANT -> Height be equal to the value of "top" property of parent's _after element.
									shadow='sm'
									rounded='full'
									sx={{
										'& svg': {
											zIndex: 5,
										},
									}}
								>
									{getIcon(log.entity)}
								</Flex>
							</GridItem>
							<GridItem>
								<Flex
									direction='column'
									justify='center'
									gap='1em'
									background={'#fff'}
									p='0.7em'
									borderRadius='8px'
									boxShadow='0px 0px 4px rgba(0, 0, 0, 0.06), 0px 4px 14px rgba(0, 0, 0, 0.04)'
								>
									<Flex align='center' gap='0.5em'>
										{log.owner && (
											<Tag textTransform='capitalize' gap='0.4em' size='sm' colorScheme='orange'>
												<FaRegUserCircle />
												{log.owner.username}
											</Tag>
										)}

										{log.created_at && (
											<Tag gap='0.4em' size='sm' colorScheme='gray'>
												<FaRegClock />
												{format(new Date(log.created_at), 'LLL dd y, HH:mm')}
											</Tag>
										)}
									</Flex>
									<Flex w='full' bg='gray.100' p='0.8em' rounded='md'>
										<Text fontSize='0.8em' color='gray.600'>
											{log.text}
										</Text>
									</Flex>
								</Flex>
							</GridItem>
						</React.Fragment>
					))}
				</Grid>
			)}
		</Flex>
	)
}

/***
 *
 * Helpers
 *
 ***/
const getIcon = (type: ENUM_AUDIT_ENTITY) => {
	const iconConfig: { [key in ENUM_AUDIT_ENTITY]: JSX.Element } = {
		message: <FaEnvelope color='white' fontSize='20px' />,
		note: <FaRegStickyNote color='white' fontSize='20px' />,
		attempt: <PhoneIcon color='white' fontSize='20px' />,
		stage: <FaCodeBranch color='white' fontSize='20px' />,
		transaction: <FaMoneyCheckAlt color='white' fontSize='20px' />,
		assignees: <FaUserAlt color='white' fontSize='20px' />,
	}

	return iconConfig[type]
}

export default AuditLogsView
