import { gql, useMutation } from '@apollo/client'
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	FormControl,
	FormLabel,
	Flex,
	Stack,
	Skeleton,
	Input,
} from '@chakra-ui/react'
import { SourceInput } from '__generated__/globalTypes'
import { useForm } from 'react-hook-form'

import { FC } from 'react'

import { onError } from '~/components/helpers'
import Button from '~/components/ui/Button'
import useToast from '~/components/ui/Toast'
import { LeadSources_lead_sources } from '~/inputs/__generated__/LeadSources'

import { SourceCreate, SourceCreateVariables } from './__generated__/SourceCreate'
import { SourceUpdate, SourceUpdateVariables } from './__generated__/SourceUpdate'

/***
 *
 * Queries & Mutations
 *
 ***/
const createSourceMutation = gql`
	mutation SourceCreate($sourceData: createSourceInput!) {
		createSource(input: $sourceData) {
			source {
				id
			}
		}
	}
`

const updateSourceMutation = gql`
	mutation SourceUpdate($sourceData: updateSourceInput!) {
		updateSource(input: $sourceData) {
			source {
				id
			}
		}
	}
`

export const listPeopleQuery = gql`
	query ListPeople($json: JSON) {
		people(where: $json) {
			id
			first_name
			middle_name
			last_name
			email
			phone
		}
	}
`

/***
 *
 * Interface & Type
 *
 ***/
interface Props {
	source?: LeadSources_lead_sources | null
	leadId?: string
	isOpen: boolean
	onClose: () => void
	onAddSource?: (id: string) => void
	onRefetch: (id: string) => Promise<void>
}

/***
 *
 * Source View Component
 *
 ***/
const SourceView: FC<Props> = props => {
	const toast = useToast()

	const { source, leadId, isOpen, onClose, onAddSource, onRefetch } = props

	const inEditMode = source?.id ? true : false

	const { register, handleSubmit, formState } = useForm<SourceInput>({
		mode: 'onChange',
		defaultValues: {
			source: source?.source,
			medium: source?.medium,
			campaign: source?.campaign,
		},
	})

	const [createSource] = useMutation<SourceCreate, SourceCreateVariables>(createSourceMutation, {
		onError: error => onError(error, toast),
	})

	const [updateSource] = useMutation<SourceUpdate, SourceUpdateVariables>(updateSourceMutation, {
		onError: error => onError(error, toast),
	})

	const createSourceHandler = async (formData: SourceInput) => {
		const { data, errors } = await createSource({
			variables: { sourceData: { data: { ...formData, origin: leadId } } },
		})

		if (!errors && data?.createSource?.source?.id) {
			const createdSourceId = data?.createSource?.source?.id

			onAddSource?.(createdSourceId)
			await onRefetch(createdSourceId)
			onClose()
			toast({ title: 'Source created.', position: 'top-right' })
		}
	}

	const submitHandler = async (formData: SourceInput) => {
		if (!inEditMode && !source?.id) {
			await createSourceHandler(formData)
			return
		}

		const { errors } = await updateSource({
			variables: { sourceData: { where: { id: source?.id as string }, data: formData } },
		})

		if (!errors) {
			source?.id && (await onRefetch(source?.id))
			onClose()
			toast({ title: 'Source updated.', status: 'success', position: 'top-right' })
		}
	}

	const loadingSource = false

	return (
		<Modal isOpen={isOpen} onClose={onClose} size='2xl'>
			<ModalOverlay backdropBlur='xl' />
			<ModalContent as='form'>
				<ModalHeader>Source</ModalHeader>
				<ModalCloseButton />
				<ModalBody pb={6}>
					{loadingSource ? (
						<Stack spacing={10} w='full' mt={10}>
							<Skeleton height='4em' rounded='md' />
						</Stack>
					) : (
						<Flex align='center' justify='space-between' gap='2em' flexWrap='wrap'>
							<FormControl w='18em'>
								<FormLabel>Source name</FormLabel>
								<Input {...register('source')} />
							</FormControl>

							<FormControl w='18em'>
								<FormLabel>Type</FormLabel>
								<Input {...register('medium')} />
							</FormControl>

							<FormControl w='18em'>
								<FormLabel>Campaign</FormLabel>
								<Input {...register('campaign')} />
							</FormControl>
						</Flex>
					)}
				</ModalBody>

				<ModalFooter>
					<Button onClick={onClose} mr={3} isDisabled={formState.isSubmitting}>
						Cancel
					</Button>
					<Button
						colorScheme='green'
						type='button'
						onClick={handleSubmit(submitHandler)}
						isLoading={formState.isSubmitting}
						isDisabled={formState.isSubmitting}
					>
						Save
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default SourceView
