import { gql, useMutation, useQuery } from '@apollo/client'
import {
	Input,
	FormControl,
	FormLabel,
	Box,
	Flex,
	Tag,
	useDisclosure,
	FormErrorMessage,
	Text,
} from '@chakra-ui/react'
import { navigate } from 'gatsby'
import { Controller, useForm } from 'react-hook-form'
import { MultiValue, SingleValue } from 'react-select'

import React, { useState } from 'react'

import { CreatePatientFormValues } from '~/components/forms/CreatePatientDentally/CreatePatientFormWrapper'
import { MatchTrackerDentallyPatient_matchTrackerDentallyPatient_dentally } from '~/components/forms/CreatePatientDentally/__generated__/MatchTrackerDentallyPatient'
import {
	InstantCreate,
	InstantCreateVariables,
} from '~/components/forms/__generated__/InstantCreate'
import { onError } from '~/components/helpers'
import { clinicsListQuery } from '~/components/kanban/Board'
import { ClinicsList } from '~/components/kanban/__generated__/ClinicsList'
import SelectDropdown, { PeopleOptionFactory } from '~/components/ui/SelectDropdown'
import { AssigneeTypes } from '~/constants'
import { createClinicOptions } from '~/helpers'
import { Inquiries, Inquiries_inquiries } from '~/inputs/__generated__/Inquiries'
import { ListUsers, ListUsers_users, ListUsersVariables } from '~/inputs/__generated__/ListUsers'
import { listUsersQuery } from '~/inputs/assignees'
import { inquiryListQuery } from '~/inputs/inquiries'
import UserView from '~/views/user'

import { instantCreateInput } from '../../../__generated__/globalTypes'
import Button from '../ui/Button'
import useToast from '../ui/Toast'

export const instantCreateMutation = gql`
	mutation InstantCreate($data: instantCreateInput!) {
		instantCreate(data: $data) {
			id
			person {
				id
			}
		}
	}
`

interface CreatePatientTrackerProps {
	onClose: () => void
	defaultFormValues: CreatePatientFormValues | null
	dentallyPatient: MatchTrackerDentallyPatient_matchTrackerDentallyPatient_dentally | null
	onlyLead?: boolean
}

/***
 *
 * Appointment Form Component
 *
 ***/
const CreatePatientTracker: React.FC<CreatePatientTrackerProps> = ({
	onClose,
	onlyLead,
	defaultFormValues,
	dentallyPatient,
}) => {
	const [instantCreateLead] = useMutation<InstantCreate, InstantCreateVariables>(
		instantCreateMutation,
		{
			onError: error => onError(error, toast),
			refetchQueries: onlyLead ? ['PersonDetails', 'LeadsList'] : undefined,
		}
	)

	const clinicsList = useQuery<ClinicsList>(clinicsListQuery)

	const { data: inquiryData } = useQuery<Inquiries>(inquiryListQuery)

	const { data: users, loading: usersLoading } = useQuery<ListUsers, ListUsersVariables>(
		listUsersQuery,
		{
			variables: { json: { role: { name: AssigneeTypes } } },
			onError: error => onError(error, toast),
		}
	)

	const [userId, setUserId] = useState('')
	const [phoneError, setPhoneError] = useState(false)

	const userViewModal = useDisclosure()
	const toast = useToast()

	const { register, handleSubmit, control, formState } = useForm<instantCreateInput>({
		mode: 'onChange',
		defaultValues: {
			first_name: defaultFormValues?.first_name || '',
			last_name: defaultFormValues?.last_name || '',
			email: defaultFormValues?.email || '',
			phone: defaultFormValues?.phone || '',
			clinic: 1,
			inquiries: [],
			assignees: [],
			source: '',
			medium: '',
			campaign: '',
		},
	})

	const submitHandler = async (formData: instantCreateInput) => {
		if (!formData.phone.length) {
			setPhoneError(true)
			return
		}
		const model = { ...formData }
		if (dentallyPatient) {
			model.dentally_uuid = dentallyPatient.uuid
			model.dentally_person_id = dentallyPatient.id.toString()
		}
		const { data, errors } = await instantCreateLead({
			variables: { data: model },
		})

		if (errors?.length) {
			toast({ title: 'Something went wrong', status: 'error', position: 'top-right' })
		}

		if (data?.instantCreate?.person?.id) {
			if (onlyLead) {
				return onClose()
			}
			return navigate(`/person/${data?.instantCreate?.person?.id}`)
		}
	}

	const onView = (id: string) => {
		setUserId(id)
		userViewModal.onOpen()
	}

	const userList = users?.users?.filter(Boolean) as ListUsers_users[]
	const clinicOptions = createClinicOptions(clinicsList?.data)
	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'space-between',
				flexDirection: 'column',
				height: 'calc(100vh - 180px)',
				overflow: 'auto',
			}}
		>
			<Box sx={{ padding: '40px 30px' }}>
				{!onlyLead && (
					<>
						<Flex align='center' justify='space-between' gap='1em' flexWrap='wrap' mb={3}>
							<FormControl isRequired w='18em' isInvalid={Boolean(formState?.errors?.first_name)}>
								<FormLabel>First Name</FormLabel>
								<Input {...register('first_name', { required: true })} placeholder='Type here' />
								<FormErrorMessage>First name is required</FormErrorMessage>
							</FormControl>
							<FormControl isRequired w='18em' isInvalid={Boolean(formState?.errors?.last_name)}>
								<FormLabel>Last Name</FormLabel>
								<Input {...register('last_name', { required: true })} placeholder='Type here' />
								<FormErrorMessage>Last Name is required</FormErrorMessage>
							</FormControl>
						</Flex>
						<Flex align='center' justify='space-between' gap='1em' flexWrap='wrap' mb={3}>
							<FormControl isRequired w='18em' isInvalid={Boolean(formState?.errors?.email)}>
								<FormLabel>Email Address</FormLabel>
								<Input
									type='email'
									{...register('email', { required: true })}
									placeholder='Type here'
								/>
								<FormErrorMessage>Email is required</FormErrorMessage>
							</FormControl>
							<FormControl w='18em' isRequired isInvalid={Boolean(formState?.errors?.phone)}>
								<FormLabel>Phone</FormLabel>
								<Input
									type='number'
									{...register('phone', { required: true })}
									placeholder='Mobile phone'
								/>
								{phoneError && (
									<Text color='#E53E3E' fontSize='0.875rem'>
										Phone is required
									</Text>
								)}
							</FormControl>
						</Flex>
					</>
				)}
				<Flex align='center' justify='space-between' gap='1em' flexWrap='wrap' mb={3}>
					<FormControl isRequired w='18em' isInvalid={Boolean(formState?.errors?.clinic)}>
						<FormLabel>Clinic</FormLabel>
						<Controller
							name='clinic'
							control={control}
							rules={{ required: true }}
							defaultValue={1}
							render={({ field: { value, onChange } }) => (
								<SelectDropdown
									variant='small'
									options={clinicOptions}
									placeholder='Select Clinic'
									defaultValue={clinicOptions.find(opt => opt.value === value)}
									onChange={selected => {
										const newOption = selected as SingleValue<{ value: number }>
										onChange(newOption?.value)
									}}
								/>
							)}
						/>
						<FormErrorMessage>Clinic is required</FormErrorMessage>
					</FormControl>
					<FormControl isRequired w='18em' isInvalid={Boolean(formState?.errors?.inquiries)}>
						<FormLabel>Inquiry Type</FormLabel>
						<Controller
							name='inquiries'
							control={control}
							rules={{ required: true }}
							defaultValue={[]}
							render={({ field: { onChange } }) => (
								<SelectDropdown<Inquiries_inquiries>
									error={Boolean(formState?.errors?.inquiries)}
									isMulti
									variant='small'
									options={inquiryData?.inquiries as Inquiries_inquiries[]}
									getOptionLabel={option => option.name as string}
									getOptionValue={option => option.id}
									placeholder='Select Enquiries'
									defaultValue={[]}
									onChange={selected => {
										const values = selected as MultiValue<Inquiries_inquiries>
										onChange(values.length ? values?.map(val => val.id) : [])
									}}
								/>
							)}
						/>
						<FormErrorMessage>At least one inquiry is required</FormErrorMessage>
					</FormControl>
				</Flex>
				<Flex mb={3}>
					<FormControl isRequired isInvalid={Boolean(formState?.errors?.assignees)}>
						<FormLabel>Assignees</FormLabel>
						<Controller
							name='assignees'
							control={control}
							rules={{ required: true }}
							defaultValue={[]}
							render={({ field: { onChange } }) => (
								<SelectDropdown
									variant='small'
									isMulti
									options={userList}
									isDisabled={usersLoading}
									placeholder='Choose Assignees'
									getOptionLabel={opt => opt.username}
									getOptionValue={opt => opt.id}
									getOptionTag={opt => (
										<Tag
											colorScheme='green'
											size='sm'
											textTransform={
												['tco', 'cst'].includes(opt.role?.type || '') ? 'uppercase' : 'capitalize'
											}
										>
											{opt.role?.type}
										</Tag>
									)}
									defaultValue={[]}
									components={{
										Option: PeopleOptionFactory<ListUsers_users>({ onView }),
									}}
									onChange={value => {
										const selectedValues = value as MultiValue<ListUsers_users>
										const ids = selectedValues.map(item => item.id)
										onChange(ids)
									}}
								/>
							)}
						/>
						<FormErrorMessage>At least one assignee is required</FormErrorMessage>
					</FormControl>
				</Flex>
				<Flex align='center' justify='space-between' gap='1em' flexWrap='wrap'>
					<FormControl w='18em' isRequired isInvalid={Boolean(formState?.errors?.source)}>
						<FormLabel>Source name</FormLabel>
						<Input {...register('source', { required: true })} placeholder='Type here' />
						<FormErrorMessage>Source name is required</FormErrorMessage>
					</FormControl>

					<FormControl w='18em'>
						<FormLabel>Type</FormLabel>
						<Input {...register('medium')} placeholder='Type here' />
					</FormControl>

					<FormControl w='18em'>
						<FormLabel>Campaign</FormLabel>
						<Input {...register('campaign')} placeholder='Type here' />
					</FormControl>
				</Flex>
			</Box>
			<Box
				sx={{
					background: '#FFF8F3',
					padding: '20px 40px',
					color: '#fff',
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<Button
					type='button'
					isLoading={formState.isSubmitting}
					sx={{
						background: '#2D3748',
						color: '#fff',
						fontSize: '17px',
						height: '40px',
						marginRight: '15px',
						width: '160px',
						textTransform: 'uppercase',
						borderRadius: '6px',
						_hover: {
							background: '#47546b',
						},
					}}
					onClick={handleSubmit(submitHandler)} // Using handleSubmit on button to avoid event bubbling, as this is a nested form.
				>
					Submit
				</Button>
				<Button
					type='button'
					sx={{
						background: 'transparent',
						color: '#FF3A53',
						border: '2px solid #FF3A53',
						fontSize: '17px',
						height: '40px',
						width: '160px',
						textTransform: 'uppercase',
						borderRadius: '6px',
						_hover: {
							background: 'rgb(241 46 71 / 13%)',
						},
					}}
					onClick={onClose}
				>
					Cancel
				</Button>
			</Box>
			{userViewModal.isOpen && (
				<UserView userId={userId} isOpen={userViewModal.isOpen} onClose={userViewModal.onClose} />
			)}
		</Box>
	)
}

export default CreatePatientTracker
