import { gql, useQuery, useLazyQuery } from '@apollo/client'
import {
	Avatar,
	Flex,
	FormControl,
	FormLabel,
	Skeleton,
	Stack,
	Text,
	useDisclosure,
} from '@chakra-ui/react'
import { ENUM_STAGE_TYPE } from '__generated__/globalTypes'
import { BiEdit } from 'react-icons/bi'

import React, { FC, useContext, useEffect, useState } from 'react'

import { onError } from '~/components/helpers'
import { AuthContext } from '~/components/page/context'
import Button from '~/components/ui/Button'
import useToast from '~/components/ui/Toast'
import { AssigneeTypes } from '~/constants'
import AssigneesView from '~/views/assignees'

import { ListUsers, ListUsers_users, ListUsersVariables } from './__generated__/ListUsers'
import { StageAssignees, StageAssigneesVariables } from './__generated__/StageAssignees'

/***
 *
 * Queries & Mutations
 *
 ***/
export const listUsersQuery = gql`
	query ListUsers($json: JSON) {
		users(where: $json) {
			id
			username
			email
			role {
				id
				type
			}
		}
	}
`

const stageAssigneesQuery = gql`
	query StageAssignees($id: ID!) {
		stage(id: $id) {
			id
			assignees {
				id
				username
				email
				role {
					id
					type
				}
			}
		}
	}
`

export type AssigneesViewType = 'all' | 'TCO' | 'Dentist' | 'CST'

/***
 *
 * Interface & Type
 *
 ***/
interface Props {
	stageId?: string
	onAdd?: (selected: { id: string; type?: string | null }[]) => void
	editMode?: boolean
	stageView?: boolean
	leadsCurrentStageInfo?: {
		id: string
		type: ENUM_STAGE_TYPE
	}
	viewType?: AssigneesViewType
}

/***
 *
 * Assignees Input Component
 *
 ***/
const AssigneesInput: FC<Props> = props => {
	const { stageId, onAdd, editMode, stageView, leadsCurrentStageInfo, viewType } = props

	const currentUser = useContext(AuthContext)
	const toast = useToast()
	const { isOpen, onOpen, onClose } = useDisclosure()
	const [selectedAssignees, setSelectedAssignees] = useState<ListUsers_users[]>([])

	const { data, loading } = useQuery<ListUsers, ListUsersVariables>(listUsersQuery, {
		variables: { json: { role: { name: AssigneeTypes } } },
		onError: error => onError(error, toast),
	})

	const [fetchStageAssignees, stageAssignees] = useLazyQuery<
		StageAssignees,
		StageAssigneesVariables
	>(stageAssigneesQuery)

	const [fetchCurrentStageAssignee] = useLazyQuery<StageAssignees, StageAssigneesVariables>(
		stageAssigneesQuery,
		{
			fetchPolicy: 'network-only',
			onCompleted: data => {
				const assignees = data?.stage?.assignees?.filter(Boolean) as ListUsers_users[]

				// If previous stage has assignees, then pre-select them for next stage.
				if (assignees.length > 0) {
					onAddHandler(assignees)
					return
				}

				// If previous stage does not have assignees, then add the valid mover as the assignee.
				if (AssigneeTypes.includes(currentUser.role?.name || '')) {
					const user = {
						id: currentUser.id,
						email: currentUser.email,
						username: currentUser.username,
					} as ListUsers_users

					onAddHandler([user])
				}
			},
		}
	)

	const userList = data?.users?.filter(Boolean) as ListUsers_users[]

	const assigneesList =
		editMode && stageId ? stageAssignees?.data?.stage?.assignees : selectedAssignees

	const defaultAssignees =
		editMode && stageId
			? (stageAssignees.data?.stage?.assignees?.map(item => ({
					id: item?.id,
					type: item?.role?.type,
			  })) as { id: string; type?: string | null }[])
			: selectedAssignees.map(item => ({ id: item.id, type: item.role?.type }))

	const onRefetchHandler = async () => {
		if (stageAssignees.refetch) {
			await stageAssignees.refetch()
		}
	}

	const onAddHandler = (selected: ListUsers_users[]) => {
		setSelectedAssignees(selected)

		const addedAssignees = selected.map(item => ({ id: item.id, type: item.role?.type }))
		onAdd?.(addedAssignees)
	}

	// Fetch stage assignees in edit mode
	useEffect(() => {
		if (editMode && stageId) {
			fetchStageAssignees({ variables: { id: stageId } })
		}
	}, [stageId])

	// Fetch current stage assignees to prefill, when not in edit mode.
	useEffect(() => {
		if (stageView && !editMode && leadsCurrentStageInfo?.id) {
			fetchCurrentStageAssignee({ variables: { id: leadsCurrentStageInfo.id } })
		}
	}, [leadsCurrentStageInfo?.id])

	const getTitle = () => {
		switch (viewType) {
			case 'TCO':
				return 'TCO'
			case 'Dentist':
				return 'Dentist'
			case 'CST':
				return 'CST'
			default:
				return 'Assignees'
		}
	}

	if (loading || (stageId && !stageAssignees.data))
		return (
			<Stack spacing={10} w='full'>
				<Skeleton height='5em' rounded='md' />
			</Stack>
		)

	return (
		<>
			<FormControl isRequired={stageView}>
				<Flex align='center' justify='space-between'>
					<FormLabel
						fontSize='1em'
						color='#191C1A'
						fontWeight='bold'
						textTransform='uppercase'
						requiredIndicator={<span />}
						m={0}
					>
						{getTitle()}
					</FormLabel>
					<Button
						title={assigneesList?.length ? 'Edit' : 'Add'}
						aria-label={assigneesList?.length ? 'Edit' : 'Add'}
						colorScheme='transparent'
						p={0}
						size='sm'
						onClick={onOpen}
						leftIcon={<BiEdit color='#0000004d' fontSize={20} />}
					/>
				</Flex>

				{assigneesList?.length ? (
					<Flex flexWrap='wrap' gap={5} mt='1em'>
						{assigneesList.map(assignee => (
							<Flex align='center' justify='start' key={assignee?.id}>
								<Avatar size='sm' name={assignee?.username} />
								<Text fontSize='1em' color='#64554B' ml={2}>
									{assignee?.username}
								</Text>
							</Flex>
						))}
					</Flex>
				) : (
					<></>
				)}

				{editMode && (assigneesList?.length || 0) == 0 && (
					<Text mt='1em' fontSize='0.9em' color='gray.400'>
						No assignees added.
					</Text>
				)}
			</FormControl>

			{isOpen && userList?.length > 0 && (
				<AssigneesView
					stageId={stageId}
					isOpen={isOpen}
					onClose={onClose}
					userList={userList}
					defaultValues={defaultAssignees}
					onAdd={onAddHandler}
					onRefetch={onRefetchHandler}
				/>
			)}
		</>
	)
}

export default AssigneesInput
