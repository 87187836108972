import { gql, useMutation, useLazyQuery } from '@apollo/client'
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	FormControl,
	FormLabel,
	Textarea,
	Stack,
	Skeleton,
} from '@chakra-ui/react'
import { NoteInput } from '__generated__/globalTypes'
import { useForm } from 'react-hook-form'

import { FC, useContext, useEffect } from 'react'

import { onError } from '~/components/helpers'
import { AuthContext } from '~/components/page/context'
import Button from '~/components/ui/Button'
import useToast from '~/components/ui/Toast'

import { CreateNote, CreateNoteVariables } from './__generated__/CreateNote'
import { NoteDetail, NoteDetailVariables } from './__generated__/NoteDetail'
import { UpdateNote, UpdateNoteVariables } from './__generated__/UpdateNote'

/***
 *
 * Queries & Mutations
 *
 ***/
export const createNoteMutation = gql`
	mutation CreateNote($noteData: createNoteInput!) {
		createNote(input: $noteData) {
			note {
				id
			}
		}
	}
`

export const updateNoteMutation = gql`
	mutation UpdateNote($noteData: updateNoteInput!) {
		updateNote(input: $noteData) {
			note {
				id
			}
		}
	}
`

const noteDetailQuery = gql`
	query NoteDetail($noteId: ID!) {
		note(id: $noteId) {
			id
			text
			type
		}
	}
`

/***
 *
 * Interface & Type
 *
 ***/
interface Props {
	noteId?: string
	leadId: string
	isOpen: boolean
	onClose: () => void
	onRefetch: () => Promise<void>
}

/***
 *
 * Notes Form Component
 *
 ***/
const NotesForm: FC<Props> = props => {
	const { isOpen, onClose, leadId, onRefetch, noteId } = props
	const user = useContext(AuthContext)
	const toast = useToast()

	const [fetchNoteDetails, { data: noteDetails, loading: loadingNoteDetails }] = useLazyQuery<
		NoteDetail,
		NoteDetailVariables
	>(noteDetailQuery)

	const [createNote] = useMutation<CreateNote, CreateNoteVariables>(createNoteMutation, {
		onError: error => onError(error, toast),
	})
	const [updateNote] = useMutation<UpdateNote, UpdateNoteVariables>(updateNoteMutation, {
		onError: error => onError(error, toast),
	})

	const { register, handleSubmit, formState } = useForm<NoteInput>({ mode: 'onChange' })

	const submitHandler = async (formData: NoteInput) => {
		if (noteId) {
			const { errors } = await updateNote({
				variables: { noteData: { where: { id: noteId }, data: formData } },
			})

			if (!errors) {
				await onRefetch()
				onClose()
				toast({ title: 'Note updated.', position: 'top-right' })
			}

			return
		}

		const { errors } = await createNote({
			variables: { noteData: { data: { ...formData, owner: user.id, origin: leadId } } },
		})

		if (!errors) {
			await onRefetch()
			onClose()
			toast({ title: 'Note created.', position: 'top-right' })
		}
	}

	useEffect(() => {
		noteId && fetchNoteDetails({ variables: { noteId } })
	}, [noteId])

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay backdropBlur='xl' />

			<ModalContent as='form'>
				<ModalHeader>{noteId ? 'Edit Note' : 'Add Note'}</ModalHeader>
				<ModalCloseButton />
				<ModalBody pb={6}>
					{noteId && (loadingNoteDetails || !noteDetails?.note?.id) ? (
						<Stack spacing={10} mt={10}>
							<Skeleton height='4em' rounded='md' />
							<Skeleton height='4em' rounded='md' />
						</Stack>
					) : (
						<>
							<FormControl isRequired>
								<FormLabel>Text</FormLabel>
								<Textarea
									{...register('text', { required: true })}
									borderColor={formState?.errors?.text ? 'red' : 'gray.200'}
									_hover={{ borderColor: formState?.errors?.text ? 'red' : 'gray.200' }}
									_focus={{ borderColor: formState?.errors?.text ? 'red' : 'gray.200' }}
									rows={4}
									placeholder='Enter note text'
									defaultValue={noteDetails?.note?.text}
								/>
							</FormControl>
						</>
					)}
				</ModalBody>

				<ModalFooter>
					<Button onClick={onClose} mr={3} disabled={formState.isSubmitting}>
						Cancel
					</Button>
					<Button
						colorScheme='green'
						type='button'
						onClick={handleSubmit(submitHandler)} // Using handleSubmit on button to avoid event bubbling, as this is a nested form.
						isLoading={formState.isSubmitting}
						disabled={formState.isSubmitting}
					>
						{noteId ? 'Save' : 'Add'}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default NotesForm
