import { gql, useMutation, useLazyQuery, useQuery } from '@apollo/client'
import {
	FormControl,
	FormLabel,
	Heading,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Stack,
	Skeleton,
	Flex,
} from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'
import { BiEdit } from 'react-icons/bi'
import { SlLocationPin } from 'react-icons/sl'
import { SingleValue } from 'react-select'

import React, { FC, useEffect, useState } from 'react'

import { onError } from '~/components/helpers'
import { clinicsListQuery } from '~/components/kanban/Board'
import { ClinicsList } from '~/components/kanban/__generated__/ClinicsList'
import Button from '~/components/ui/Button'
import SelectDropdown from '~/components/ui/SelectDropdown'
import useToast from '~/components/ui/Toast'
import { createClinicOptions, setKanbanRefetchVal } from '~/helpers'

import { ENUM_STAGE_TYPE } from '../../__generated__/globalTypes'
import { ClinicDetails, ClinicDetailsVariables } from './__generated__/ClinicDetails'
import { PreferredClinic, PreferredClinicVariables } from './__generated__/PreferredClinic'
import { UpdateStage, UpdateStageVariables } from './__generated__/UpdateStage'

/***
 *
 * Queries & Mutations
 *
 ***/
export const updateStageMutation = gql`
	mutation UpdateStage($stageData: updateStageInput!) {
		updateStage(input: $stageData) {
			stage {
				id
			}
		}
	}
`

const clinicDetailsQuery = gql`
	query ClinicDetails($id: ID!) {
		stage(id: $id) {
			id
			clinic {
				id
				name
			}
		}
	}
`

const preferredClinicQuery = gql`
	query PreferredClinic($id: ID!) {
		lead(id: $id) {
			id
			clinic {
				id
				name
			}
		}
	}
`

/***
 *
 * Interface & Type
 *
 ***/
interface Props {
	stageId?: string
	leadId?: string
	title?: string
	editMode?: boolean
	onCreate?: (clinicId: number) => Promise<void> | void
	leadsCurrentStageInfo?: {
		id: string
		type: ENUM_STAGE_TYPE
	}
	isPage?: boolean
}

type Clinic = number | null

/***
 *
 * Clinic Input Component
 *
 ***/
const ClinicInput: FC<Props> = props => {
	const { stageId, leadId, title, editMode, onCreate, leadsCurrentStageInfo, isPage } = props
	const toast = useToast()
	const { isOpen, onClose, onOpen } = useDisclosure()
	const inEditMode = !!(stageId || (leadId && editMode))

	const [addedClinic, setAddedClinic] = useState<Clinic>(null)

	const clinicsList = useQuery<ClinicsList>(clinicsListQuery)

	const [fetchClinicDetails, bookedClinicResult] = useLazyQuery<
		ClinicDetails,
		ClinicDetailsVariables
	>(clinicDetailsQuery)

	const [fetchPreferredClinic, preferredClinicResult] = useLazyQuery<
		PreferredClinic,
		PreferredClinicVariables
	>(preferredClinicQuery)

	const [fetchCurrentStageClinic] = useLazyQuery<ClinicDetails, ClinicDetailsVariables>(
		clinicDetailsQuery,
		{
			fetchPolicy: 'network-only',
			onCompleted: async data => {
				const clinicId = Number(data?.stage?.clinic?.id) || 1

				await onCreate?.(clinicId)
				setAddedClinic(clinicId)
			},
		}
	)

	const savedClinicId =
		bookedClinicResult?.data?.stage?.clinic?.id || preferredClinicResult?.data?.lead?.clinic?.id

	const clinic = savedClinicId ? Number(savedClinicId) : null

	const { control, handleSubmit, formState } = useForm<{ clinic?: Clinic }>({
		defaultValues: { clinic: clinic },
	})

	// console.log(control)

	const [updateClinic] = useMutation<UpdateStage, UpdateStageVariables>(updateStageMutation, {
		onError: error => onError(error, toast),
	})

	const clinicValue = inEditMode ? clinic : addedClinic

	const submitHandler = async ({ clinic }: { clinic?: Clinic }) => {
		setKanbanRefetchVal('true')

		if (!inEditMode && onCreate && clinic) {
			setAddedClinic(clinic)
			onCreate(clinic)
			onClose()
			return
		}

		if (leadId && onCreate && clinic) {
			setAddedClinic(clinic)
			await onCreate(clinic)
			preferredClinicResult.refetch?.()
			onClose()
			return
		}

		const { errors } = await updateClinic({
			variables: {
				stageData: {
					where: { id: stageId as string },
					data: { clinic: clinic?.toString() },
				},
			},
		})

		if (!errors) {
			await bookedClinicResult.refetch?.()
			onClose()
			toast({ title: 'Clinic updated.', status: 'success', position: 'top-right' })
		}
	}

	useEffect(() => {
		// Fetch clinic details in edit mode if booked clinic input
		stageId && fetchClinicDetails({ variables: { id: stageId } })

		// Fetch clinic details in edit mode if preffered clinic input
		leadId && inEditMode && fetchPreferredClinic({ variables: { id: leadId } })
	}, [stageId, leadId])

	// Fetch & set current stage clinic info to when moving to appointment attended from appointment booked.
	useEffect(() => {
		const inAppointmentBooked = leadsCurrentStageInfo?.type == ENUM_STAGE_TYPE.treatment_booked

		if (!editMode && inAppointmentBooked && leadsCurrentStageInfo?.id) {
			fetchCurrentStageClinic({ variables: { id: leadsCurrentStageInfo.id } })
		}
	}, [leadsCurrentStageInfo?.id])

	if (bookedClinicResult.loading || preferredClinicResult.loading)
		return (
			<Stack spacing={10} w='full'>
				<Skeleton height='4em' rounded='md' />
			</Stack>
		)

	const wrapperProps = isPage
		? {
				border: '1px solid rgba(100, 85, 75, 0.2)',
				borderRadius: '8px',
				background: '#fff',
				padding: '15px 20px',
		  }
		: {}

	const clinicOptions = createClinicOptions(clinicsList?.data)
	return (
		<>
			<FormControl isRequired={!!((!clinic && !leadId) || leadId)} {...wrapperProps}>
				<Flex align='center' justify='space-between'>
					<FormLabel
						fontSize={isPage ? '0.9em' : '1em'}
						color={isPage ? '#64554B' : '#191C1A'}
						fontWeight={isPage ? '400' : 'bold'}
						textTransform={isPage ? 'capitalize' : 'uppercase'}
						requiredIndicator={<span />}
						m={0}
					>
						{title || 'Clinic'}
					</FormLabel>
					<Button
						title={inEditMode || clinicValue ? 'Edit' : 'Add'}
						aria-label={inEditMode || clinicValue ? 'Edit' : 'Add'}
						colorScheme='transparent'
						p={0}
						size='sm'
						leftIcon={<BiEdit color='#0000004d' fontSize={20} />}
						onClick={onOpen}
					/>
				</Flex>

				<Flex justify='start' align='center' mt={isPage ? '0' : '1em'}>
					{clinicValue && (
						<>
							{!isPage && <SlLocationPin color='#EEAB7E' fontSize={20} />}
							<Heading
								color='#64554B'
								fontWeight={isPage ? 'bold' : 400}
								fontSize='1em'
								ml={isPage ? '0' : '0.5em'}
							>
								{clinicOptions.find(opt => opt.value === clinicValue)?.label}
							</Heading>
						</>
					)}
				</Flex>
			</FormControl>

			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay backdropBlur='xl' />
				<ModalContent as='form'>
					<ModalHeader>Choose Clinic</ModalHeader>
					<ModalCloseButton />
					<ModalBody pb={6}>
						<FormControl>
							<Controller
								name='clinic'
								control={control}
								rules={{ required: true }}
								render={({ field: { value, onChange } }) => {
									return (
										<SelectDropdown
											variant='small'
											options={clinicOptions}
											placeholder='Select Clinic'
											defaultValue={clinicOptions.find(opt => opt.value === Number(value))}
											onChange={selected => {
												const newOption = selected as SingleValue<{ value: number }>
												onChange(newOption?.value)
											}}
										/>
									)
								}}
							/>
						</FormControl>
					</ModalBody>

					<ModalFooter>
						<Button mr={3} onClick={onClose} disabled={formState.isSubmitting}>
							Cancel
						</Button>
						<Button
							colorScheme='green'
							type='button'
							isLoading={formState.isSubmitting}
							disabled={formState.isSubmitting}
							onClick={handleSubmit(submitHandler)} // Using handleSubmit on button to avoid event bubbling, as this is a nested form.
						>
							Save
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}

export default ClinicInput
