import { gql, useMutation } from '@apollo/client'
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	FormControl,
	FormLabel,
	Flex,
	Checkbox,
	Alert,
	AlertIcon,
	AlertTitle,
} from '@chakra-ui/react'
import { editStageInput } from '__generated__/globalTypes'
import { setHours, setMinutes } from 'date-fns'
import { Controller, useForm } from 'react-hook-form'

import { FC, useState } from 'react'

import DatePickerInput, { TimePicker } from '~/components/DatePicker'
import { onError } from '~/components/helpers'
import useToast from '~/components/ui/Toast'
import { setKanbanRefetchVal } from '~/helpers'

import { UpdateCallbackInfo, UpdateCallbackInfoVariables } from './__generated__/UpdateCallbackInfo'

/***
 *
 * Queries & Mutations
 *
 ***/
const updateCallbackInfoMutation = gql`
	mutation UpdateCallbackInfo($input: updateStageInput!) {
		updateStage(input: $input) {
			stage {
				id
				callback_date
				callback_time
			}
		}
	}
`

/***
 *
 * Interface & Type
 *
 ***/
interface Props {
	stageId?: string
	isOpen: boolean
	onClose: () => void
	editMode?: boolean
	defaultValues: { callback_date?: string; callback_time?: string }
	onAdd?: (data: editStageInput) => void
}

/***
 *
 * Callback Info Input Component
 *
 ***/
const CallbackInfoView: FC<Props> = props => {
	const { isOpen, onClose, editMode, defaultValues, stageId, onAdd } = props
	const toast = useToast()

	const { control, handleSubmit, formState, setValue } = useForm<editStageInput>()

	const [showTimeSelect, setShowTimeSelect] = useState(() =>
		defaultValues.callback_time ? true : false
	)

	const [removeInfo, setRemoveInfo] = useState(false)

	const excludeTimes = [0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23]
		.map(hour => [
			setHours(setMinutes(new Date(), 0), hour),
			setHours(setMinutes(new Date(), 30), hour),
		])
		.flat()

	const [updateCallbackInfo] = useMutation<UpdateCallbackInfo, UpdateCallbackInfoVariables>(
		updateCallbackInfoMutation,
		{
			onError: error => onError(error, toast),
			refetchQueries: ['LeadsList'],
		}
	)

	const submitHandler = async (data: editStageInput) => {
		if (!stageId) {
			onAdd?.(data)
			return
		}

		await updateCallbackInfo({
			variables: { input: { where: { id: stageId }, data } },
		})

		onClose()
		toast({ title: 'Callback info updated.', status: 'success', position: 'top-right' })
		setKanbanRefetchVal('true')

		return
	}

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay backdropBlur='xl' />
			<ModalContent as='form'>
				<ModalHeader>{`${editMode ? 'Edit' : 'Add'} Callback Info`}</ModalHeader>
				<ModalCloseButton />
				<ModalBody pb={6}>
					{removeInfo && (
						<Alert status='warning' rounded='md'>
							<AlertIcon />
							<AlertTitle fontSize='0.85em'>Lead will be removed from callback state!</AlertTitle>
						</Alert>
					)}

					{!removeInfo && (
						<Flex align='center' gap='1.5em'>
							<FormControl isRequired mb={5}>
								<FormLabel>Date</FormLabel>
								<Controller
									control={control}
									name='callback_date'
									rules={{ required: true }}
									render={({ field: { onChange } }) => (
										<DatePickerInput
											defaultValue={defaultValues?.callback_date}
											onChange={onChange}
											minDate={new Date()}
										/>
									)}
								/>
							</FormControl>

							{showTimeSelect && (
								<FormControl mb={5}>
									<FormLabel>Time</FormLabel>
									<Controller
										control={control}
										name='callback_time'
										render={({ field: { onChange } }) => (
											<TimePicker
												defaultValue={defaultValues?.callback_time}
												onChange={onChange}
												excludeTimes={excludeTimes}
											/>
										)}
									/>
								</FormControl>
							)}
						</Flex>
					)}

					<Flex>
						{!removeInfo && (
							<FormControl mt='2em'>
								<Checkbox
									defaultChecked={showTimeSelect}
									colorScheme='green'
									onChange={evt => {
										setValue('callback_time', null)
										setShowTimeSelect(evt.target.checked)
									}}
								>
									Select time
								</Checkbox>
							</FormControl>
						)}

						<FormControl mt='2em'>
							<Checkbox
								defaultChecked={removeInfo}
								colorScheme='green'
								onChange={evt => {
									setValue('callback_date', null)
									setValue('callback_time', null)
									setRemoveInfo(evt.target.checked)
								}}
							>
								Remove callback info
							</Checkbox>
						</FormControl>
					</Flex>
				</ModalBody>

				<ModalFooter>
					<Button onClick={onClose} mr={3}>
						Cancel
					</Button>
					<Button
						colorScheme='green'
						type='button'
						onClick={handleSubmit(submitHandler)} // Using handleSubmit on button to avoid event bubbling, as this is a nested form.
						isLoading={formState.isSubmitting}
						isDisabled={formState.isSubmitting}
					>
						Save
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default CallbackInfoView
