import { ENUM_STAGE_TYPE, ENUM_TRANSACTION_TYPE } from './../__generated__/globalTypes'

export const TransactionMediumOptions = [
	{ label: 'Cash', value: 'cash' },
	{ label: 'Card', value: 'card' },
	{ label: 'Bank', value: 'bank' },
	{ label: 'Go cardless', value: 'go_cardless' },
	{ label: 'Tabeo', value: 'tabeo' },
	{ label: 'Pay as you go', value: 'pay_as_you_go' },
]

export const SenderEmailOptions = [
	{ label: 'TCO', value: 'consultation_email' },
	{ label: 'Reception', value: 'reception_email' },
	{ label: 'CST', value: 'cst_email' },
]

export const SortByOptions = [
	{ label: 'Created at', value: 'created_at' },
	{ label: 'Updated at', value: 'updated_at' },
	{ label: 'Contact attempts', value: 'contact_attempts' },
	{ label: 'Alarm', value: 'alarm' },
]

export const SearchFieldOptions = [
	{ label: 'Name', value: 'name' },
	{ label: 'Email', value: 'email' },
	{ label: 'Phone', value: 'phone' },
]

export const ContactMethodOptions = [
	{ label: 'Phone Call', value: 'phone_call' },
	{ label: 'Whatsapp', value: 'whatsapp' },
	{ label: 'Facebok DM', value: 'facebook_dm' },
	{ label: 'Instagram', value: 'instagram' },
	{ label: 'Email', value: 'email' },
]

export const ContactTimeOptions = [
	{ label: 'ASAP', value: 'asap' },
	{ label: '8:00 AM', value: '8:00 AM' },
	{ label: '9:00 AM', value: '9:00 AM' },
	{ label: '10:00 AM', value: '10:00 AM' },
	{ label: '11:00 AM', value: '11:00 AM' },
	{ label: '12:00 PM', value: '12:00 PM' },
	{ label: '1:00 PM', value: '1:00 PM' },
	{ label: '2:00 PM', value: '2:00 PM' },
	{ label: '3:00 PM', value: '3:00 PM' },
	{ label: '4:00 PM', value: '4:00 PM' },
	{ label: '5:00 PM', value: '5:00 PM' },
	{ label: '6:00 PM', value: '6:00 PM' },
	{ label: '7:00 PM', value: '7:00 PM' },
	{ label: '8:00 PM', value: '8:00 PM' },
]

export const ContactDayOptions = [
	{ label: 'Monday', value: 'monday' },
	{ label: 'Tuesday', value: 'tuesday' },
	{ label: 'Wednesday', value: 'wednesday' },
	{ label: 'Thursday', value: 'thursday' },
	{ label: 'Friday', value: 'friday' },
]

export const FailureTagsOptions = [
	{ label: 'Failed to contact us', value: 'failed_to_contact_us' },
	{ label: 'Gone elsewhere', value: 'gone_elsewhere' },
	{ label: 'Too expensive', value: 'too_expensive' },
	{ label: 'Shopping around', value: 'shopping_around' },
	{ label: 'Not interested', value: 'not_interested' },
	{ label: 'FTA', value: 'fta' },
	{ label: 'Duplicate', value: 'duplicate' },
	{ label: 'Patient cancelled appointment', value: 'patient_cancelled_appointment' },
	{ label: 'Other', value: 'other' },
]

export const SearchFieldPlaceholders: { [index: string]: string } = {
	name: 'Enter person name',
	email: 'Enter person email',
	phone: 'Enter person phone',
	inquiry: 'Enter inquiry name',
	notes: 'Enter note keyword',
	source: 'Enter source',
}

export const StageStatusValues = {
	created: 'Created',
	contacted: 'Contacted',
	consult_failed_to_book: 'Consult failed to book',
	consult_booked: 'Consult booked',
	consult_attended: 'Consult attended',
	consult_failed_to_attend: 'Consult failed to attend',
	TX_given: 'Treatment plan given',
	TX_updated: 'Treatment plan updated',
	finance_declined: 'Finance declined',
	appointment_pending: 'Appointment pending',
	failed_to_convert: 'Failed to convert',
	treatment_booked: 'Treatment booked',
	appointment_cancelled: 'Appointment cancelled',
	appointment_attended: 'Appointment attended',
	appointment_failed_to_attend: 'Appointment failed to attend',
	TX_done: 'Treatment done',
	TX_incomplete: 'Treatment incomplete',
	cancelled: 'Cancelled',
	oct_appointment_booked: 'OCT appointment booked',
	oct_appointment_failed_to_attend: 'OCT appointment failed to attend',
	oct_appointment_cancelled: 'OCT appointment cancelled',
}

export const StageTypeReqFields: { [index in ENUM_STAGE_TYPE]: string[] } = {
	failed_to_convert: ['failure_tag', 'failure_notes'],
	consult_failed_to_book: ['failure_tag', 'failure_notes'],
	appointment_failed_to_attend: ['failure_tag', 'failure_notes'],
	oct_appointment_failed_to_attend: ['failure_tag', 'failure_notes'],
	created: [],
	contacted: [],
	consult_booked: [],
	consult_attended: [],
	consult_failed_to_attend: [],
	TX_given: [],
	TX_updated: [],
	appointment_pending: [],
	treatment_booked: [],
	appointment_cancelled: [],
	appointment_attended: [],
	TX_done: [],
	TX_incomplete: [],
	cancelled: [],
	oct_appointment_booked: [],
	oct_appointment_cancelled: [],
}

export const TransactionTypeText: { [index in ENUM_TRANSACTION_TYPE]: string } = {
	top_up: 'Top Up',
	deposit: 'Deposit',
	payment: 'Payment',
	refund: 'Refund',
}

export const TreatmentTypeOptions = [
	{ value: 'invisalign_braces', label: 'Invisalign Braces' },
	{ value: 'composite_bonding', label: 'Composite Bonding' },
	{ value: 'porcelain_veneers', label: 'Porcelain Veneers' },
	{ value: 'implants', label: 'Implants' },
	{ value: 'whitening', label: 'Whitening' },
	{ value: 'hygiene', label: 'Hygiene' },
]

export const EmailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/

export const AssigneeTypes = ['Reception', 'TCO', 'Dentist', 'Hygienist', 'CST']
