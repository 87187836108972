import { Input } from '@chakra-ui/react'
import { format } from 'date-fns'
import { default as ReactDatePicker, ReactDatePickerProps } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import React, { FC, useState, useEffect, LegacyRef } from 'react'

/***
 *
 * Date Picker Component
 *
 ***/
interface DatePickerProps extends Omit<ReactDatePickerProps, 'onChange'> {
	defaultValue?: string
	onChange: (date: string) => void
}

const DatePicker: FC<DatePickerProps> = props => {
	const { defaultValue, onChange, ...restProps } = props

	const [startDate, setStartDate] = useState<Date | null>(
		defaultValue ? new Date(defaultValue) : new Date()
	)

	useEffect(() => {
		startDate && onChange(format(startDate, 'yyyy-MM-dd'))
	}, [])

	return (
		<ReactDatePicker
			selected={startDate}
			dateFormat='LLL d, yyyy'
			customInput={React.createElement(CustomInput)}
			{...restProps}
			onChange={date => {
				setStartDate(date)
				onChange(date ? format(date, 'yyyy-MM-dd') : '')
			}}
		/>
	)
}

export default DatePicker

/***
 *
 * Time Picker Component
 *
 ***/
interface DatePickerProps extends Omit<ReactDatePickerProps, 'onChange'> {
	defaultValue?: string
	onChange: (date: string) => void
}

export const TimePicker: FC<DatePickerProps> = props => {
	const { defaultValue, onChange, ...restProps } = props

	const [timeValue, setTimeValue] = useState<Date | null>(
		defaultValue ? new Date(defaultValue) : null
	)

	useEffect(() => {
		timeValue && onChange(format(timeValue, 'HH:mm:ss'))
	}, [])

	return (
		<ReactDatePicker
			showTimeSelect
			showTimeSelectOnly
			timeIntervals={30}
			timeCaption='Time'
			dateFormat='h:mm a'
			placeholderText='Select'
			selected={timeValue}
			customInput={React.createElement(CustomInput)}
			{...restProps}
			onChange={date => {
				setTimeValue(date)
				onChange(date ? format(date, 'HH:mm:ss') : '')
			}}
		/>
	)
}

/***
 *
 * Range Picker Component
 *
 ***/
interface RangePickerProps extends Omit<ReactDatePickerProps, 'onChange'> {
	defaultValue?: (string | undefined)[]
	onChange: (range: [string | null, string | null]) => void
}

type DateRange = [Date | null, Date | null]

export const DateRangePicker: FC<RangePickerProps> = props => {
	const { defaultValue, onChange, ...restProps } = props

	const [dateRange, setDateRange] = useState<DateRange>(() => {
		if (!defaultValue) return [null, null]

		const [start, end] = defaultValue

		if (start && end) {
			return [new Date(start), new Date(end)]
		}

		return [null, null]
	})

	const [startDate, endDate] = dateRange

	const handleChange = (range: DateRange) => {
		setDateRange(range)

		const [start, end] = range

		if (!start && !end) {
			onChange([null, null])
		}

		if (start && end) {
			onChange([format(start, 'yyyy-MM-dd'), format(end, 'yyyy-MM-dd')])
		}
	}

	return (
		<ReactDatePicker
			isClearable={true}
			selectsRange={true}
			selected={startDate}
			dateFormat='LLL d, yyyy'
			customInput={React.createElement(CustomRangeInput)}
			placeholderText='Select date range'
			{...restProps}
			onChange={handleChange}
			startDate={startDate}
			endDate={endDate}
		/>
	)
}

/***
 *
 * Custom Inputs
 *
 ***/
const CustomInput: FC<React.ForwardRefExoticComponent<React.RefAttributes<unknown>>> =
	React.forwardRef((props, ref) => {
		return (
			<Input
				w='9em'
				bg='gray.200'
				border='gray.200'
				cursor='pointer'
				{...props}
				ref={ref as LegacyRef<HTMLInputElement>}
			/>
		)
	})

CustomInput.displayName = 'CustomInput'

const CustomRangeInput: FC<React.ForwardRefExoticComponent<React.RefAttributes<unknown>>> =
	React.forwardRef((props, ref) => {
		return (
			<Input
				bg='white'
				border='white'
				cursor='pointer'
				fontSize='0.8em'
				h='3em'
				{...props}
				ref={ref as LegacyRef<HTMLInputElement>}
			/>
		)
	})

CustomRangeInput.displayName = 'CustomRangeInput'
