export interface DentallyEthnicityType {
	code: string
	description: string
}

export enum DentallyTitleEnum {
	Mr = 'Mr',
	Mrs = 'Mrs',
	Miss = 'Miss',
	Ms = 'Ms',
	Dr = 'Dr',
	Master = 'Master',
	Prof = 'Prof',
	Hon = 'Hon',
	Rev = 'Rev',
	Sir = 'Sir',
	Lady = 'Lady',
	Lord = 'Lord',
	Earl = 'Earl',
	Judge = 'Judge',
	Dame = 'Dame',
}

export const DentallyReasonsArray = [
	'Exam',
	'Scale & Polish',
	'Exam + Scale & Polish',
	'Continuing Treatment',
	'Emergency',
	'Review',
	'Other',
]

export const DentallyStatesArray = [
	'Pending',
	'Confirmed',
	'Arrived',
	'In surgery',
	'Completed',
	'Cancelled',
	'Did not attend',
]

export const DentallyAppointmentDurationArray = [
	{ value: 15, label: '15 minutes' },
	{ value: 30, label: '30 minutes' },
	{ value: 45, label: '45 minutes' },
	{ value: 60, label: '1 hour' },
	{ value: 75, label: '1 hour 15 minutes' },
	{ value: 90, label: '1 hour 30 minutes' },
	{ value: 105, label: '1 hour 45 minutes' },
	{ value: 120, label: '2 hours' },
	{ value: 135, label: '2 hours 15 minutes' },
	{ value: 150, label: '2 hours 30 minutes' },
]

export const DentallyTitleEnumArray: { value: DentallyTitleEnum; label: DentallyTitleEnum }[] = [
	{ value: DentallyTitleEnum.Mr, label: DentallyTitleEnum.Mr },
	{ value: DentallyTitleEnum.Mrs, label: DentallyTitleEnum.Mrs },
	{ value: DentallyTitleEnum.Miss, label: DentallyTitleEnum.Miss },
	{ value: DentallyTitleEnum.Ms, label: DentallyTitleEnum.Ms },
	{ value: DentallyTitleEnum.Dr, label: DentallyTitleEnum.Dr },
	{ value: DentallyTitleEnum.Master, label: DentallyTitleEnum.Master },
	{ value: DentallyTitleEnum.Prof, label: DentallyTitleEnum.Prof },
	{ value: DentallyTitleEnum.Hon, label: DentallyTitleEnum.Hon },
	{ value: DentallyTitleEnum.Rev, label: DentallyTitleEnum.Rev },
	{ value: DentallyTitleEnum.Sir, label: DentallyTitleEnum.Sir },
	{ value: DentallyTitleEnum.Lady, label: DentallyTitleEnum.Lady },
	{ value: DentallyTitleEnum.Lord, label: DentallyTitleEnum.Lord },
	{ value: DentallyTitleEnum.Earl, label: DentallyTitleEnum.Earl },
	{ value: DentallyTitleEnum.Judge, label: DentallyTitleEnum.Judge },
	{ value: DentallyTitleEnum.Dame, label: DentallyTitleEnum.Dame },
]

export const DentallyPreferredPhoneArray: { value: number; label: string }[] = [
	{ value: 1, label: 'Home' },
	{ value: 2, label: 'Work' },
	{ value: 3, label: 'Mobile' },
]

export const MarketingConsentArray: { value: number; label: string }[] = [
	{ value: 0, label: 'No' },
	{ value: 1, label: 'Yes' },
]

export const DentallyGenderEnum = {
	male: true,
	female: false,
}

export const dataToSelectOptions = (
	data: DentallyEthnicityType[],
	value: 'code',
	label: 'description'
): { value: string; label: string }[] => {
	return data.map(item => {
		return {
			value: item[value],
			label: item[label],
		}
	})
}

export const DentallyEthnicityTypes: DentallyEthnicityType[] = [
	{
		code: '01',
		description: 'White British',
	},
	{
		code: '02',
		description: 'White Irish',
	},
	{
		code: '03',
		description: 'White Other',
	},
	{
		code: '04',
		description: 'White and Black Caribbean',
	},
	{
		code: '05',
		description: 'White and Black African',
	},
	{
		code: '06',
		description: 'White and Asian',
	},
	{
		code: '07',
		description: 'Other Mixed Background',
	},
	{
		code: '08',
		description: 'Asian or Asian British Indian',
	},
	{
		code: '09',
		description: 'Asian or Asian British Pakistani',
	},
	{
		code: '10',
		description: 'Asian or Asian British Bangladeshi',
	},
	{
		code: '11',
		description: 'Other Asian Background',
	},
	{
		code: '12',
		description: 'Black or Black British Caribbean',
	},
	{
		code: '13',
		description: 'Black or Black British African',
	},
	{
		code: '14',
		description: 'Other Black Background',
	},
	{
		code: '15',
		description: 'Chinese',
	},
	{
		code: '16',
		description: 'Any Other Ethnic Group',
	},
	{
		code: '99',
		description: 'Patient declined\n',
	},
]
