import { FormControl, FormLabel, useDisclosure, Flex, Heading } from '@chakra-ui/react'
import { BiEdit, BiTimeFive } from 'react-icons/bi'
import { BsChat } from 'react-icons/bs'
import { FiCalendar } from 'react-icons/fi'

import React, { FC, useState } from 'react'

import Button from '~/components/ui/Button'
import { ContactMethodOptions } from '~/constants'
import PrefContactInfoView, { PreferredContactInfo } from '~/views/prefContactInfo'

/***
 *
 * Interface & Type
 *
 ***/
interface Props {
	editMode?: boolean
	defaultValue?: PreferredContactInfo
	onSubmit: (data: PreferredContactInfo) => Promise<void>
}

/***
 *
 * Preferred Contact Input Component
 *
 ***/
const PrefContactInfoInput: FC<Props> = props => {
	const { editMode, defaultValue, onSubmit } = props
	const { isOpen, onOpen, onClose } = useDisclosure()

	const [addedContactInfo, setAddedContacInfo] = useState<PreferredContactInfo>()

	const contactMethod = editMode
		? defaultValue?.preferred_contact_method
		: addedContactInfo?.preferred_contact_method

	const contactTime = editMode
		? defaultValue?.preferred_contact_time
		: addedContactInfo?.preferred_contact_time

	const contactDay = editMode
		? defaultValue?.preferred_contact_day
		: addedContactInfo?.preferred_contact_day

	return (
		<>
			<FormControl>
				<Flex align='center' justify='space-between'>
					<FormLabel
						fontSize='1em'
						color='#191C1A'
						fontWeight='bold'
						textTransform='uppercase'
						requiredIndicator={<span />}
						m={0}
					>
						Preferred Contact Info
					</FormLabel>
					<Button
						title={editMode ? 'Edit' : 'Add'}
						aria-label={editMode ? 'Edit' : 'Add'}
						colorScheme='transparent'
						p={0}
						size='sm'
						leftIcon={<BiEdit color='#0000004d' fontSize={20} />}
						onClick={onOpen}
					/>
				</Flex>

				{contactTime && contactMethod && (
					<Flex direction='column' gap='1em' mt='1em'>
						<Flex align='center' gap='0.5em'>
							<BsChat color='#EEAB7E' fontSize={20} />
							<Heading color='#64554B' fontWeight={400} fontSize='1em'>
								{ContactMethodOptions.find(opt => opt.value === contactMethod)?.label}
							</Heading>
						</Flex>

						<Flex align='center' gap='0.5em'>
							<BiTimeFive color='#EEAB7E' fontSize={20} />
							<Heading color='#64554B' fontWeight={400} fontSize='1em'>
								{contactTime}
							</Heading>
						</Flex>

						<Flex align='center' gap='0.5em'>
							<FiCalendar color='#EEAB7E' fontSize={20} />
							<Heading color='#64554B' fontWeight={400} fontSize='1em' textTransform='capitalize'>
								{contactDay}
							</Heading>
						</Flex>
					</Flex>
				)}
			</FormControl>

			{isOpen && (
				<PrefContactInfoView
					isOpen={isOpen}
					onClose={onClose}
					defaultValue={defaultValue}
					onSubmit={async data => {
						!editMode && setAddedContacInfo(data)
						await onSubmit(data)
						onClose()
					}}
				/>
			)}
		</>
	)
}

export default PrefContactInfoInput
