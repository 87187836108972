import { useLazyQuery } from '@apollo/client'
import { DownloadIcon } from '@chakra-ui/icons'

import { FC } from 'react'

import Button from '../ui/Button'
import { leadsListQuery } from './Column'
import { LeadsList, LeadsListVariables, LeadsList_leadsList_leads } from './__generated__/LeadsList'
import { ColumnId } from './helpers'

/***
 *
 * Interface & Type
 *
 ***/
interface Props {
	columnId: ColumnId
	queryVariables?: LeadsListVariables
	disableDownload?: boolean
}

/***
 *
 * Column Component
 *
 ***/
const ExportCsvBtn: FC<Props> = props => {
	const { columnId, queryVariables, disableDownload } = props

	const [fetchAllLeads, allLeadsDetails] = useLazyQuery<LeadsList, LeadsListVariables>(
		leadsListQuery,
		{
			notifyOnNetworkStatusChange: true,
			fetchPolicy: 'network-only',
			variables: queryVariables,
			onCompleted: data => {
				const leadList =
					(data?.leadsList?.leads?.filter(Boolean) as LeadsList_leadsList_leads[]) || []

				if (leadList.length > 0) {
					exportToCsv(leadList)
				}
			},
		}
	)

	const downloadFile = (data: string, fileName: string, fileType: string) => {
		const blob = new Blob([data], { type: fileType })

		const a = document.createElement('a')
		a.download = fileName
		a.href = window.URL.createObjectURL(blob)

		const clickEvt = new MouseEvent('click', {
			view: window,
			bubbles: true,
			cancelable: true,
		})

		a.dispatchEvent(clickEvt)
		a.remove()
	}

	const exportToCsv = async (leadList: LeadsList_leadsList_leads[]) => {
		const headers = ['Name,Phone,Email,Treatments,Clinic']

		const formattedData = leadList.map(lead => ({
			name: lead.person?.first_name,
			phone: lead.person?.phone,
			email: lead.person?.email,
			treatments: lead.inquiries?.inquiries?.map(inq => inq?.name)?.join('-'),
			clinic: lead.clinic?.id,
		})) as { name: string; phone: string; email: string; treatments?: string; clinic?: string }[]

		const usersCsv = formattedData.reduce((acc, user) => {
			const { name, phone, email, treatments, clinic } = user
			acc.push([name, phone, email, treatments, clinic].join(','))
			return acc
		}, [] as string[])

		downloadFile([...headers, ...usersCsv].join('\n'), `${columnId}_leads.csv`, 'text/csv')
	}

	if (disableDownload) {
		return (
			<Button
				leftIcon={<DownloadIcon />}
				colorScheme='messenger'
				size='sm'
				fontSize='0.7em'
				disabled={true}
			/>
		)
	}

	return (
		<Button
			isLoading={allLeadsDetails.loading}
			title='Download CSV'
			aria-label='Download CSV'
			leftIcon={<DownloadIcon />}
			colorScheme='messenger'
			onClick={() => fetchAllLeads()}
			size='sm'
			fontSize='0.7em'
		/>
	)
}

export default ExportCsvBtn
