import { ClinicsList } from '~/components/kanban/__generated__/ClinicsList'
import { StageStatusValues } from '~/constants'

import { LeadTeam } from './components/kanban/helpers'

type RefetchStorageVal = 'true' | 'false'

export const penniesToPounds = (amount?: number | null): number => {
	if (!amount) return 0
	return amount / 100
}

export const setKanbanRefetchVal = (active: RefetchStorageVal, leadTeam?: LeadTeam): void => {
	if (active === 'false') {
		const key = leadTeam === 'reception' ? 'reception' : 'tco'
		localStorage.setItem(`refetch_${key}_kanban`, active)
		return
	}

	localStorage.setItem('refetch_tco_kanban', active)
	localStorage.setItem('refetch_reception_kanban', active)
}

export const getKanbanRefetchVal = (leadTeam?: LeadTeam): RefetchStorageVal | null => {
	const key = leadTeam === 'reception' ? 'reception' : 'tco'

	return localStorage.getItem(`refetch_${key}_kanban`) as RefetchStorageVal | null
}

export const processTimeAsDate = (dateValue?: string, timeValue?: string): string | undefined => {
	return dateValue && timeValue ? new Date(`${dateValue}T${timeValue}`).toISOString() : undefined
}

export const createClinicOptions = (
	clinicsList?: ClinicsList
): { label: string; value: string | number }[] => {
	if (!clinicsList) {
		return []
	}
	return (
		clinicsList.clinics?.map(clinic => ({
			label: clinic?.name || '',
			value: clinic?.id ? Number(clinic?.id) : 1,
		})) ?? []
	)
}

export const createSelectOptions = (): { label: string; value: boolean }[] => {
	return [
		{
			label: 'No',
			value: false,
		},
		{
			label: 'Yes',
			value: true,
		},
	]
}

export const createStagesOptions = (): { label: string; value: string }[] => {
	return Object.entries(StageStatusValues).map(([key, value]) => ({
		label: value,
		value: key,
	}))
}

export const createSettledOptions = (): { label: string; value: boolean }[] => {
	return [
		{
			label: 'Not set',
			value: false,
		},
		{
			label: 'Set',
			value: true,
		},
	]
}
