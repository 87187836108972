import { gql, useQuery } from '@apollo/client'
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	Skeleton,
	Avatar,
	VStack,
	Heading,
	Text,
} from '@chakra-ui/react'

import { FC } from 'react'

import { UserDetails, UserDetailsVariables } from './__generated__/UserDetails'

/***
 *
 * Queries & Mutations
 *
 ***/
export const userDetialsQuery = gql`
	query UserDetails($userId: ID!) {
		user(id: $userId) {
			id
			username
			email
			role {
				id
				type
			}
		}
	}
`

/***
 *
 * Interface & Type
 *
 ***/
interface Props {
	userId: string
	isOpen: boolean
	onClose: () => void
}

/***
 *
 * User View Component
 *
 ***/
const UserView: FC<Props> = props => {
	const { userId, isOpen, onClose } = props

	const { data, loading } = useQuery<UserDetails, UserDetailsVariables>(userDetialsQuery, {
		variables: { userId },
	})

	return (
		<Modal isOpen={isOpen} onClose={onClose} size='md'>
			<ModalOverlay backdropBlur='xl' />
			<ModalContent as='form'>
				<ModalHeader>User Details</ModalHeader>
				<ModalCloseButton />
				<ModalBody p={10}>
					{!data || loading ? (
						<Skeleton height='12em' rounded='md' />
					) : (
						<VStack shadow='lg' py={10} rounded='md'>
							<Avatar name={data.user?.username} mb={3} />
							<Heading>{data.user?.username}</Heading>
							<Text fontWeight='bold' textTransform='capitalize'>
								{data.user?.role?.type}
							</Text>
							<Text>{data.user?.email}</Text>
						</VStack>
					)}
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}

export default UserView
