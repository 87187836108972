import { Box, Divider, Flex, Skeleton, Text } from '@chakra-ui/react'
import { format } from 'date-fns'

import { FC } from 'react'

interface Props {
	messageData: MessageType
	isUnread?: boolean
}

export type MessageType = {
	id: string
	author: string
	authorType: 'me' | 'other'
	body?: string
	createdAt: string
	isReply?: boolean
}

const Message: FC<Props> = props => {
	const { messageData, isUnread } = props

	return (
		<>
			{isUnread && (
				<Box w='full'>
					<Text fontSize='0.7em' color='blue.700' textAlign='center'>
						New messages
					</Text>
					<Divider borderColor='blue.700' />
				</Box>
			)}

			<Box
				id={messageData.id}
				maxW='18em'
				px={4}
				py={2}
				rounded='2xl'
				bg={!messageData.isReply ? 'gray.500' : 'cyan.500'}
				borderBottomLeftRadius={!messageData.isReply ? '2xl' : '0'}
				borderBottomRightRadius={!messageData.isReply ? '0' : '2xl'}
				alignSelf={!messageData.isReply ? 'end' : 'start'}
			>
				<Flex gap={1} direction='column' w='full'>
					<Flex gap='0.8em' align='center' fontWeight='bold' justify='space-between'>
						<Text fontSize='0.65em' color='white'>
							{!messageData.isReply ? 'Ruhdental' : messageData.author}
						</Text>
						<Text fontSize='0.65em' fontWeight='bold' color='white'>
							{format(new Date(messageData.createdAt), 'LLL dd y, HH:mm')}
						</Text>
					</Flex>
					<Text whiteSpace='pre-line' fontSize='0.8em' color='white'>
						{messageData.body}
					</Text>
				</Flex>
			</Box>
		</>
	)
}

export default Message

export const MessageSkeleton: FC = () => {
	const messages = [{ authorType: 'other' }, { authorType: 'me' }, { authorType: 'other' }]

	return (
		<>
			{messages.map((message, idx) => (
				<Skeleton
					key={idx}
					w='14em'
					px={4}
					py={2}
					rounded='2xl'
					bg={message.authorType === 'me' ? 'gray.500' : 'cyan.500'}
					borderBottomLeftRadius={message.authorType === 'me' ? '2xl' : '0'}
					borderBottomRightRadius={message.authorType === 'me' ? '0' : '2xl'}
					alignSelf={message.authorType === 'me' ? 'end' : 'start'}
					h='5em'
				/>
			))}
		</>
	)
}
