import { gql } from '@apollo/client'
import { ArrowBackIcon } from '@chakra-ui/icons'
import {
	Flex,
	Skeleton,
	Input,
	Grid,
	Divider,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
} from '@chakra-ui/react'
import { navigate } from 'gatsby'
import { useForm } from 'react-hook-form'

import React, { FC, useState } from 'react'

import Navbar from '~/components/Navbar'
import LeadAppts from '~/components/lead/components/LeadAppts'
import LeadJourney from '~/components/lead/components/LeadJourney'
import LeadMessages from '~/components/lead/components/LeadMessages'
import LeadTxPlan from '~/components/lead/components/LeadTxPlan'
import Button from '~/components/ui/Button'
import AssigneesInput from '~/inputs/assignees'
import AssigneesRoleInput from '~/inputs/assignees-role'
import ClinicInput from '~/inputs/clinic'
import InquiriesInput from '~/inputs/inquiries'
import PersonInput from '~/inputs/person'
import PrefContactInfoInput from '~/inputs/prefContactInfo'
import SourceInput from '~/inputs/source'
import StageInput from '~/inputs/stage'
import TagsInput from '~/inputs/tags'
import { FindLead_findLead } from '~/views/__generated__/FindLead'

import { editLeadInput, StageInput as StageDataInput } from '../../../__generated__/globalTypes'

/***
 *
 * Queries & Mutations
 *
 ***/
export const allTags = gql`
	query AllTags {
		tags {
			id
			text
		}
	}
`

/***
 *
 * Interface & Type
 *
 ***/
interface Props {
	mode: 'add' | 'edit'
	leadDetails?: FindLead_findLead
	onSubmit: (formData: editLeadInput) => Promise<void>
	onCreateStage?: (formData: StageDataInput) => Promise<string | undefined>
	onRefetch: () => Promise<void>
	onCancel: () => Promise<void>
}

/***
 *
 * Lead Form Component
 *
 ***/
const LeadFormNew: FC<Props> = props => {
	const { mode, leadDetails, onSubmit, onRefetch } = props
	const inEditMode = mode === 'edit'
	const [tabIndex, setTabIndex] = useState<number>(0)

	const { setValue } = useForm<editLeadInput>({
		mode: 'onChange',
		defaultValues: {
			tags: leadDetails?.tags?.map(t => t?.id),
		},
	})

	const createStageForm = useForm<StageDataInput>()

	const getTabsConfig = (
		props: Props
	): { id: string; title: string; component: React.ReactNode }[] => {
		return [
			{
				id: 'messagesTab',
				title: 'Messages',
				component: (
					<LeadMessages {...props} active={tabIndex === 0} createStageForm={createStageForm} />
				),
			},
			{
				id: 'apptsTab',
				title: 'Appts',
				component: <LeadAppts {...props} active={tabIndex === 1} />,
			},
			{
				id: 'txPlanTab',
				title: 'TX Plan',
				component: <LeadTxPlan {...props} active={tabIndex === 2} />,
			},
			{
				id: 'journeyTab',
				title: 'Journey',
				component: <LeadJourney {...props} active={tabIndex === 3} />,
			},
		]
	}

	const onBack = () => {
		return navigate(-1)
	}

	const tabsConfig = getTabsConfig(props)
	return (
		<>
			<Navbar />
			<Flex bg='#F2F2F2' height='3em' alignItems='center' px={12}>
				<Button
					title='Back'
					aria-label='Edit'
					bg='transparent'
					color='black'
					marginRight='10px'
					_hover={{ bg: 'transparent', color: '#EEAB7E' }}
					leftIcon={<ArrowBackIcon sx={{ width: '20px' }} />}
					size='m'
					onClick={onBack}
				>
					Back
				</Button>
				{inEditMode &&
					leadDetails?.current_stage?.id &&
					leadDetails?.id &&
					leadDetails?.person?.id && (
						<StageInput
							leadCurrentStageId={leadDetails.current_stage.id}
							leadId={leadDetails.id}
							txPlanId={leadDetails.treatment_plan_id}
							archivedLead={leadDetails.archive ?? false}
							personId={leadDetails.person.id}
							onRefetch={onRefetch}
						/>
					)}
			</Flex>
			<Flex height='calc(100vh - 5em - 3em)'>
				<Flex
					bg='white'
					width='500px'
					padding='50px 25px 0 25px'
					direction='column'
					overflowY='scroll'
					pb={10}
					sx={{
						'@media screen and (max-width: 1200px)': {
							minWidth: '340px',
						},
					}}
				>
					<PersonInput personId={leadDetails?.person?.id} leadDetails={leadDetails} />
					<Divider my='1em' />
					<PrefContactInfoInput
						editMode={inEditMode}
						defaultValue={{
							preferred_contact_method: leadDetails?.preferred_contact_method,
							preferred_contact_time: leadDetails?.preferred_contact_time,
							preferred_contact_day: leadDetails?.preferred_contact_day,
						}}
						onSubmit={async data => {
							if (inEditMode) {
								await onSubmit(data)
								await onRefetch()
							}

							setValue('preferred_contact_method', data.preferred_contact_method)
							setValue('preferred_contact_time', data.preferred_contact_time)
							setValue('preferred_contact_day', data.preferred_contact_day)
						}}
					/>
					<Divider my='1em' />
					<ClinicInput
						title='Clinic'
						editMode={inEditMode}
						leadId={leadDetails?.id}
						onCreate={async clinic => {
							if (inEditMode) {
								return await onSubmit({ clinic: clinic.toString() })
							}

							setValue('clinic', clinic.toString())
						}}
					/>
					<Divider my='1em' />
					{inEditMode && leadDetails?.inquiries?.origin ? (
						<InquiriesInput stageId={leadDetails?.inquiries?.origin} />
					) : (
						<>
							<InquiriesInput
								onCreate={ids => createStageForm.setValue('inquiries', ids)}
								stageId={leadDetails?.current_stage?.id}
							/>
							<Input hidden {...createStageForm.register('inquiries', { required: true })} />
						</>
					)}
					<Divider my='1em' />
					<AssigneesInput
						editMode={inEditMode}
						stageId={leadDetails?.assignees?.origin || leadDetails?.current_stage?.id}
						onAdd={selected => {
							const ids = selected.map(s => s.id)
							createStageForm.setValue('assignees', ids)
						}}
					/>
					<Divider my='1em' />
					<AssigneesRoleInput viewType='dentist' leadDetails={leadDetails} />
					<Divider my='1em' />
					<AssigneesRoleInput viewType='tco' leadDetails={leadDetails} />
					<Divider my='1em' />
					<AssigneesRoleInput viewType='cst' leadDetails={leadDetails} />
					<Divider my='1em' />
					<TagsInput
						leadTags={leadDetails?.tags}
						onCreate={async tags => {
							await onSubmit({ tags: tags })
							await onRefetch()
						}}
					/>
					<Divider my='1em' />
					<SourceInput
						leadId={leadDetails?.id}
						onAddSource={id => {
							console.log('source', id)
						}}
					/>
				</Flex>
				<Flex
					bg='#F8F8F8'
					width='100%'
					padding='0 60px'
					overflowY='scroll'
					sx={{
						'@media screen and (max-width: 1200px)': {
							padding: '0 20px',
						},
					}}
				>
					<Tabs
						variant='soft-rounded'
						colorScheme='transparent'
						width='100%'
						mt='45px'
						onChange={index => setTabIndex(index)}
					>
						<TabList pb={4}>
							{tabsConfig.map(item => {
								return (
									<Tab
										_selected={{ color: '#EEAB7E', border: '1px solid #EEAB7E' }}
										color='#191C1A'
										key={item.id}
										mr={8}
										fontSize='1em'
										textTransform='uppercase'
									>
										{item.title}
									</Tab>
								)
							})}
						</TabList>
						<Divider my='1em' />
						<TabPanels>
							{tabsConfig.map(item => {
								return (
									<TabPanel key={item.id + 'content'} padding='0'>
										{item.component}
									</TabPanel>
								)
							})}
						</TabPanels>
					</Tabs>
				</Flex>
			</Flex>
		</>
	)
}

export const LeadFormLoader: FC = () => (
	<Flex w='full' flexDirection='column' align='center' justify='space-between' h='full'>
		<Grid px='2em' templateColumns={{ base: '1fr 1fr 1fr' }} gap='1.5em' w='full' mt='5em'>
			<Skeleton height='10em' rounded='md' />
			<Skeleton height='10em' rounded='md' />
			<Skeleton height='10em' rounded='md' />
			<Skeleton height='6em' rounded='md' />
			<Skeleton height='6em' rounded='md' />
			<Skeleton height='6em' rounded='md' />
		</Grid>
		<Skeleton height='4em' rounded='md' w='full' />
	</Flex>
)

export default LeadFormNew
