import {
	BellIcon,
	ChatIcon,
	EmailIcon,
	PhoneIcon,
	RepeatClockIcon,
	TimeIcon,
} from '@chakra-ui/icons'
import { Box, Divider, Flex, Tag, Text, Tooltip, useDisclosure, VStack } from '@chakra-ui/react'
import { formatDistanceToNow } from 'date-fns'
import { navigate } from 'gatsby'
import { Draggable } from 'react-beautiful-dnd'
import { FaArchive, FaClone, FaRegBellSlash } from 'react-icons/fa'

import { FC } from 'react'

import {
	LeadsList_leadsList_leads,
	LeadsList_leadsList_leads_attempts,
} from '~/components/kanban/__generated__/LeadsList'
import { StageStatusValues } from '~/constants'
import { useUpdateLead } from '~/crud/lead'

import { ENUM_ATTEMPT_TYPE, ENUM_STAGE_TYPE } from '../../../__generated__/globalTypes'
import ConfirmPopup from '../ConfirmPopup'
import { joinValues } from '../helpers'
import Button from '../ui/Button'
import { ColumnId, getStatusColor, LeadTeam } from './helpers'

/***
 *
 * Interface & Type
 *
 ***/
interface Props {
	lead: LeadsList_leadsList_leads
	index: number
	columnId: ColumnId
	leadTeam?: LeadTeam
}

/***
 *
 * Card Component
 *
 ***/
const LeadCard: FC<Props> = props => {
	const { lead, index, columnId, leadTeam } = props

	const stageType = lead.current_stage?.type as ENUM_STAGE_TYPE

	const { onOpen, isOpen, onClose } = useDisclosure()

	const { handleLeadUpdate } = useUpdateLead({
		refetchQueries: ['LeadsList', 'LeadDetails', 'AllStages', 'RestrictedStages'],
		onSuccess: {
			message: 'Lead has been archived',
			handler: () => {
				onClose()
			},
		},
	})

	const checkOverdue = (): boolean => {
		const currentDate = new Date()
		const utcCurrentDate = new Date(
			currentDate.getUTCFullYear(),
			currentDate.getUTCMonth(),
			currentDate.getUTCDate(),
			currentDate.getUTCHours(),
			currentDate.getUTCMinutes(),
			currentDate.getUTCSeconds()
		)

		const dueDateTime = new Date(`${lead?.alarm?.due_date}T${lead?.alarm?.due_time || '00:00:00'}`)

		// Check if the due date and time are in the past
		return utcCurrentDate > dueDateTime
	}

	const inquiries = lead.inquiries?.inquiries.map(inq => inq?.name)
	const attempts =
		(lead.attempts
			?.filter(Boolean)
			.filter(attempt => attempt?.team == leadTeam) as LeadsList_leadsList_leads_attempts[]) ?? []

	const callAttempts = attempts.filter(attempt => attempt.type === ENUM_ATTEMPT_TYPE.call)
	const whatsappAttempts = attempts.filter(attempt => attempt.type === ENUM_ATTEMPT_TYPE.whatsapp)
	const otherAttempts = attempts.filter(attempt => attempt.type === ENUM_ATTEMPT_TYPE.other)

	const inquiryFragment =
		(inquiries?.length || 0) < 3 ? (
			<Text fontSize='0.6em' color='gray.500' fontWeight='bold'>
				{inquiries?.join(', ')}
			</Text>
		) : (
			<Flex align='center' gap='0.4em'>
				<Text fontSize='0.6em' color='gray.500' fontWeight='bold'>
					{inquiries?.slice(0, 2).join(', ')}
				</Text>
				<Tag rounded='md' px='0.7em' fontWeight='bold' colorScheme='cyan' fontSize='0.5em'>
					+ {(inquiries?.length || 0) - 2}
				</Tag>
			</Flex>
		)

	return (
		<>
			<Draggable key={lead.id} index={index} draggableId={lead.id}>
				{(provided, snapshot) => (
					<VStack
						position='relative'
						align='start'
						userSelect='none'
						mb={5}
						bg='white'
						border='1px'
						borderColor='gray.200'
						p={2}
						rounded='md'
						spacing={3}
						ref={provided.innerRef}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						style={{
							cursor: snapshot.isDragging ? 'grab' : 'pointer',
							...provided.draggableProps.style,
						}}
						onClick={() => navigate(`/lead/${lead.id}`)}
					>
						<Flex w='full' align='start' justify='space-between'>
							<VStack align='start'>
								<Text fontSize='0.8em' fontWeight='bold' color='gray.600'>
									{joinValues(
										lead.person?.first_name,
										lead.person?.middle_name,
										lead.person?.last_name
									)}
								</Text>

								{!inquiries ? (
									<Text fontSize='0.6em' color='gray.400' fontWeight='bold'>
										No enquiries
									</Text>
								) : (
									inquiryFragment
								)}
							</VStack>
							<VStack>
								<Flex align='center' gap={3} w='full' justify='end'>
									<Tooltip
										placement='right-end'
										hasArrow
										label={`${callAttempts.length} call ${
											callAttempts.length > 1 ? 'attempts' : 'attempt'
										}`}
										shouldWrapChildren
									>
										<PhoneIcon
											w='0.8em'
											color={callAttempts.length > 0 ? 'green.400' : 'gray.400'}
										/>
									</Tooltip>

									<Tooltip
										placement='right-end'
										hasArrow
										label={`${otherAttempts.length} other ${
											otherAttempts.length > 1 ? 'attempts' : 'attempt'
										}`}
										shouldWrapChildren
									>
										<EmailIcon
											w='0.9em'
											color={otherAttempts.length > 0 ? 'green.400' : 'gray.400'}
										/>
									</Tooltip>

									<Tooltip
										placement='right-end'
										hasArrow
										label={`${whatsappAttempts.length} call ${
											whatsappAttempts.length > 1 ? 'attempts' : 'attempt'
										}`}
										shouldWrapChildren
									>
										<PhoneIcon
											w='0.8em'
											color={whatsappAttempts.length > 0 ? 'green.400' : 'gray.400'}
										/>
									</Tooltip>
								</Flex>
								<Flex align='center' gap={3}>
									{lead.is_callback && (
										<Tooltip placement='right-end' hasArrow label={`Callback`} shouldWrapChildren>
											<RepeatClockIcon mb='0.2em' w='0.8em' color='orange.400' />
										</Tooltip>
									)}

									{lead.reapplication && (
										<Tooltip
											placement='right-end'
											hasArrow
											label={`Reapplication`}
											shouldWrapChildren
										>
											<FaClone fill='orange' />
										</Tooltip>
									)}

									{![
										ENUM_STAGE_TYPE.consult_failed_to_book,
										ENUM_STAGE_TYPE.failed_to_convert,
									].includes(stageType) ? (
										lead.alarm?.id ? (
											<BellIcon w='1em' color={checkOverdue() ? 'red.400' : 'gray.400'} />
										) : (
											<FaRegBellSlash color='#A0AEC0' />
										)
									) : (
										<></>
									)}

									<ChatIcon w='0.8em' color={lead.has_unread_message ? 'blue.400' : 'gray.400'} />
								</Flex>
							</VStack>
						</Flex>

						<Divider borderWidth={1} />

						<Flex w='full' align='center' justify='space-between'>
							<Box>
								{lead.current_stage?.type && (
									<Tag
										size='sm'
										fontSize='0.65em'
										fontWeight='bold'
										colorScheme={getStatusColor(columnId)}
									>
										{StageStatusValues[lead.current_stage?.type]}
									</Tag>
								)}
							</Box>

							<Box>
								{[
									ENUM_STAGE_TYPE.consult_failed_to_book,
									ENUM_STAGE_TYPE.failed_to_convert,
									ENUM_STAGE_TYPE.TX_done,
								].includes(stageType) &&
									!lead.archive && (
										<Tooltip placement='right-end' hasArrow label={`Archive`} shouldWrapChildren>
											<Button
												colorScheme='red'
												size='xs'
												sx={{
													svg: {
														marginLeft: '0.3em',
													},
												}}
												rightIcon={<FaArchive size='0.8em' fill='white' />}
												onClick={evt => {
													evt.stopPropagation()
													onOpen()
												}}
											>
												Archive
											</Button>
										</Tooltip>
									)}
							</Box>
						</Flex>

						<Flex w='full' justify='space-between' flexWrap='wrap'>
							<Flex align='center' gap={1}>
								<TimeIcon w='0.7em' color='gray.400' />
								<Text fontSize='0.7em' color='gray.400'>
									created {formatDistanceToNow(new Date(lead?.created_at))}
								</Text>
							</Flex>

							<Flex align='center' gap={1}>
								<TimeIcon w='0.7em' color='gray.400' />
								<Text fontSize='0.7em' color='gray.400'>
									updated {formatDistanceToNow(new Date(lead?.updated_at))}
								</Text>
							</Flex>
						</Flex>
					</VStack>
				)}
			</Draggable>

			{isOpen && (
				<ConfirmPopup
					isOpen={isOpen}
					onClose={onClose}
					title='Are you sure ?'
					message='Lead will be archived'
					onConfirm={async () => {
						await handleLeadUpdate(lead.id, { archive: true })
					}}
				/>
			)}
		</>
	)
}

export default LeadCard
