import React, { FC } from 'react'

import AuditLogsView from '~/views/auditLogs'

/***
 *
 * Interface & Type
 *
 ***/
interface Props {
	leadId?: string
}

/***
 *
 * Audit logs Input Component
 *
 ***/
const AuditLogsInput: FC<Props> = ({ leadId }) => {
	return <>{leadId && <AuditLogsView leadId={leadId} />}</>
}

export default AuditLogsInput
