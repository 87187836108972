import { Box, Text } from '@chakra-ui/react'

import React from 'react'

import Button from '~/components/ui/Button'

interface FormPickerProps {
	handleRenderFullPatientForm: () => void
	handleRenderTrackerPatientForm: () => void
}

const FormPicker: React.FC<FormPickerProps> = ({
	handleRenderFullPatientForm,
	handleRenderTrackerPatientForm,
}) => {
	return (
		<Box sx={{ borderTop: '1px solid #E8E8E8', padding: '20px 30px 40px 30px' }}>
			<Text fontWeight='bold' align='center'>
				Email address cannot be found.
			</Text>
			<Text fontWeight='bold' align='center'>
				Would you like to create a new record?
			</Text>
			<Box
				sx={{
					marginTop: '30px',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					display: 'flex',
				}}
			>
				<Button
					onClick={handleRenderTrackerPatientForm}
					sx={{
						background: '#2D3748',
						color: '#fff',
						fontSize: '17px',
						height: '40px',
						marginRight: '15px',
						width: '300px',
						textTransform: 'uppercase',
						borderRadius: '6px',
						_hover: {
							background: '#47546b',
						},
					}}
				>
					Create in Tracker
				</Button>
				<Button
					onClick={handleRenderFullPatientForm}
					sx={{
						marginTop: '20px',
						background: '#2D3748',
						color: '#fff',
						fontSize: '17px',
						height: '40px',
						marginRight: '15px',
						width: '300px',
						textTransform: 'uppercase',
						borderRadius: '6px',
						_hover: {
							background: '#47546b',
						},
					}}
				>
					Create in Tracker & Dentally
				</Button>
			</Box>
		</Box>
	)
}

export default FormPicker
