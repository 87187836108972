import { gql, useMutation } from '@apollo/client'
import { Button, Flex, FormControl, FormLabel, HStack, Skeleton } from '@chakra-ui/react'
import isEmpty from 'lodash/isEmpty'
import { useForm } from 'react-hook-form'
import { SingleValue } from 'react-select'

import React, { FC, useContext, useEffect, useState } from 'react'

import { onError } from '~/components/helpers'
import { createNoteMutation } from '~/components/lead/NotesForm'
import { CreateNote, CreateNoteVariables } from '~/components/lead/__generated__/CreateNote'
import {
	GetTemplate,
	GetTemplate_getTemplate,
	GetTemplateVariables,
} from '~/components/lead/components/__generated__/GetTemplate'
import { SendEmail, SendEmailVariables } from '~/components/lead/components/__generated__/SendEmail'
import { AuthContext } from '~/components/page/context'
import SelectDropdown from '~/components/ui/SelectDropdown'
import useToast from '~/components/ui/Toast'
import { SenderEmailOptions } from '~/constants'
import { FindLead_findLead } from '~/views/__generated__/FindLead'

export const emailTemplateMutation = gql`
	mutation GetTemplate($data: getTemplateInput!) {
		getTemplate(data: $data) {
			id
			test_data
			template_id
		}
	}
`

export const sendEmailMutation = gql`
	mutation SendEmail($data: sendEmailInput!) {
		sendEmail(data: $data) {
			message
		}
	}
`

interface EmailsFormProps {
	templateId: string
	versionId: string
	onRefetch: () => Promise<void>
	templateTitle: string
	leadDetails?: FindLead_findLead
}

const EmailsForm: FC<EmailsFormProps> = props => {
	const [templateData, setTemplateData] = useState<GetTemplate_getTemplate | undefined>(undefined)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [sender, setSender] = useState<string>('consultation_email')
	const { handleSubmit, formState } = useForm<{ [key: string]: string }>({
		mode: 'onSubmit',
	})
	const toast = useToast()
	const user = useContext(AuthContext)

	const { templateId, versionId, leadDetails, templateTitle, onRefetch } = props

	const [getTemplate] = useMutation<GetTemplate, GetTemplateVariables>(emailTemplateMutation, {
		onError: error => onError(error, toast),
	})

	const [sendEmail] = useMutation<SendEmail, SendEmailVariables>(sendEmailMutation, {
		onError: error => onError(error, toast),
	})

	const [createNote] = useMutation<CreateNote, CreateNoteVariables>(createNoteMutation, {
		onError: error => onError(error, toast),
	})

	useEffect(() => {
		setIsLoading(true)
		const fetcher = async () => {
			return await getTemplate({
				variables: { data: { templateId: templateId, versionId: versionId } },
			})
		}
		fetcher()
			.then(res => {
				setIsLoading(false)
				setTemplateData(res.data?.getTemplate)
			})
			.catch(err => {
				console.error(err)
				setIsLoading(false)
			})
	}, [templateId, versionId])

	const getSenderEmail = () => {
		return leadDetails?.clinic ? leadDetails?.clinic[sender] : null
	}

	const parseTemplateTitle = (templateTitle: string) => {
		if (templateTitle.includes('[')) {
			const split = templateTitle.split(', ')
			const parsed = split.map(s => s.split('[')[1].split(']')[0])
			return parsed.join(' - ')
		}
		return templateTitle
	}

	const submitHandler = async () => {
		const templateVars = templateData?.test_data ? JSON.parse(templateData?.test_data) : {}
		const templateKeys = Object.keys(templateVars)
		if (
			!isEmpty(formState.errors) ||
			!leadDetails ||
			!leadDetails?.person ||
			!leadDetails.person.email
		) {
			toast({ title: 'Person has not email.', position: 'top-right', status: 'error' })
			return
		}
		await sendEmail({
			variables: {
				data: {
					subject: 'Ruhdental',
					email: {
						to: leadDetails.person.email,
						name: `${leadDetails.person.first_name} ${leadDetails.person.last_name}`,
					},
					sender: getSenderEmail(),
					template_id: templateId,
					dynamic_template_data: addTemplateValues(templateKeys),
				},
			},
		}).then(async () => {
			const { errors } = await createNote({
				variables: {
					noteData: {
						data: {
							text: `Email "${parseTemplateTitle(templateTitle)}" sent`,
							owner: user.id,
							origin: leadDetails?.id,
						},
					},
				},
			})
			if (!errors) {
				await onRefetch()
				toast({ title: 'Email sent.', position: 'top-right' })
			}
		})
	}

	const addTemplateValues = (templateKeys: string[]) => {
		const templateData = {}
		templateKeys.forEach(key => {
			if (key === 'recipientFirstName') {
				templateData[key] = leadDetails?.person?.first_name
			}
			if (key === 'treatmentCoordinatorName') {
				templateData[key] = user?.username
			}
		})
		return templateData
	}

	if (isLoading) {
		return (
			<HStack w='full' h='4em' spacing='4em'>
				<Skeleton w='full' h='full' rounded='md' />
			</HStack>
		)
	}

	return (
		<Flex gap='1em' flexDirection='column'>
			<FormControl w='50%' isRequired sx={{ display: 'flex', alignItems: 'center' }}>
				<FormLabel color='#191C1A' sx={{ margin: '0 5px 0 0' }}>
					Sender
				</FormLabel>
				<SelectDropdown
					variant='small'
					options={SenderEmailOptions}
					defaultValue={SenderEmailOptions[0]}
					onChange={selected => {
						const option = selected as SingleValue<{ label: string; value: string }>
						setSender(option!.value)
					}}
				/>
			</FormControl>
			<Button
				colorScheme='green'
				type='button'
				w='200px'
				onClick={handleSubmit(submitHandler)}
				isLoading={formState.isSubmitting}
				disabled={true}
			>
				Send email
			</Button>
		</Flex>
	)
}

export default EmailsForm
