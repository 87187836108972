import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	FormControl,
	FormLabel,
	Flex,
} from '@chakra-ui/react'
import { ENUM_LEAD_PREFERRED_CONTACT_METHOD } from '__generated__/globalTypes'
import { Controller, useForm } from 'react-hook-form'
import { SingleValue } from 'react-select'

import { FC } from 'react'

import SelectDropdown from '~/components/ui/SelectDropdown'
import { ContactDayOptions, ContactMethodOptions, ContactTimeOptions } from '~/constants'

/***
 *
 * Interface & Type
 *
 ***/
interface Props {
	isOpen: boolean
	onClose: () => void
	defaultValue?: PreferredContactInfo
	onSubmit: (data: PreferredContactInfo) => Promise<void>
}

export type PreferredContactInfo = {
	preferred_contact_method?: ENUM_LEAD_PREFERRED_CONTACT_METHOD | null
	preferred_contact_time?: string | null
	preferred_contact_day?: string | null
}

/***
 *
 * Preferred Contact View Component
 *
 ***/
const PrefContactInfoView: FC<Props> = props => {
	const { isOpen, onClose, defaultValue, onSubmit } = props
	const { control, handleSubmit, formState } = useForm<PreferredContactInfo>({
		defaultValues: {
			preferred_contact_method: defaultValue?.preferred_contact_method,
			preferred_contact_time: defaultValue?.preferred_contact_time,
			preferred_contact_day: defaultValue?.preferred_contact_day,
		},
	})

	const submitHandler = async (formData: PreferredContactInfo) => onSubmit(formData)

	return (
		<Modal isOpen={isOpen} onClose={onClose} size='lg'>
			<ModalOverlay />
			<ModalContent as='form'>
				<ModalHeader>Preferred Contact Info</ModalHeader>
				<ModalCloseButton />
				<ModalBody pb={6}>
					<Flex align='center' gap='2em' my='1em'>
						<FormControl isRequired>
							<FormLabel>Method</FormLabel>
							<Controller
								control={control}
								name='preferred_contact_method'
								rules={{ required: true }}
								render={({ field: { onChange, value } }) => (
									<SelectDropdown
										variant='small'
										error={formState?.errors?.preferred_contact_method ? true : false}
										defaultValue={ContactMethodOptions.find(opt => opt.value === value)}
										options={ContactMethodOptions}
										placeholder='Select'
										onChange={selected => {
											const newOption = selected as SingleValue<{ value: string }>
											onChange(newOption?.value)
										}}
									/>
								)}
							/>
						</FormControl>

						<FormControl isRequired>
							<FormLabel>Time</FormLabel>
							<Controller
								control={control}
								name='preferred_contact_time'
								rules={{ required: true }}
								render={({ field: { onChange, value } }) => (
									<SelectDropdown
										variant='small'
										error={formState?.errors?.preferred_contact_time ? true : false}
										defaultValue={ContactTimeOptions.find(
											opt => opt.value === value || opt.label === value
										)}
										options={ContactTimeOptions}
										placeholder='Select'
										onChange={selected => {
											const newOption = selected as SingleValue<{ value: string }>
											onChange(newOption?.value)
										}}
									/>
								)}
							/>
						</FormControl>

						<FormControl isRequired>
							<FormLabel>Day</FormLabel>
							<Controller
								control={control}
								name='preferred_contact_day'
								rules={{ required: true }}
								render={({ field: { onChange, value } }) => (
									<SelectDropdown
										variant='small'
										error={formState?.errors?.preferred_contact_day ? true : false}
										defaultValue={ContactDayOptions.find(
											opt => opt.value === value || opt.label === value
										)}
										options={ContactDayOptions}
										placeholder='Select'
										onChange={selected => {
											const newOption = selected as SingleValue<{ value: string }>
											onChange(newOption?.value)
										}}
									/>
								)}
							/>
						</FormControl>
					</Flex>
				</ModalBody>

				<ModalFooter>
					<Button onClick={onClose} mr={3} isDisabled={formState.isSubmitting}>
						Cancel
					</Button>
					<Button
						colorScheme='green'
						type='button'
						isLoading={formState.isSubmitting}
						isDisabled={formState.isSubmitting}
						onClick={handleSubmit(submitHandler)} // Using handleSubmit on button to avoid event bubbling, as this is a nested form.
					>
						Save
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default PrefContactInfoView
