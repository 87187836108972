import { EmailTemplates_emailTemplates_templates } from '~/components/lead/components/__generated__/EmailTemplates'

export interface TreeNode {
	name: string
	id: string | number
	children: TreeNode[]
	title?: string
	updated_at?: string
	thumbnail_url?: string | null
	versionId?: string
}

export const filterEmailTemplates = (
	templates?: EmailTemplates_emailTemplates_templates[]
): TreeNode[] => {
	if (!templates || !templates.length) {
		return []
	}

	const result: TreeNode[] = []

	// Helper function to recursively add nodes to the result array
	function addNode(
		tree: TreeNode[],
		path: string[],
		item: EmailTemplates_emailTemplates_templates
	): void {
		if (path.length === 0) {
			return
		}

		const name = path.shift()!
		let node = tree.find(item => item.name === name)
		const id = Math.floor(Math.random() * 1000000)

		if (!node) {
			node = { name, id, children: [] }
			const parts = item.title.match(/c=\[(.+)\],\s*s=\[(.+)\],\s*n=\[(.+)\]/)
			const n = (parts as string[])[3]
			if (n === name) {
				node = { ...node, ...item }
			}
			tree.push(node as TreeNode)
		}

		addNode((node as TreeNode).children, path, item)
	}

	// Parse and filter each item in the input array
	templates.forEach(item => {
		if (item.title.includes('c=[')) {
			const parts = item.title.match(/c=\[(.+)\],\s*s=\[(.+)\],\s*n=\[(.+)\]/)
			if (parts) {
				const c = parts[1]
				const s = parts[2]
				const n = parts[3]

				const path = [c, s, n]

				addNode(result, path, item)
			}
		}
	})

	return result
}
