import { gql, useLazyQuery } from '@apollo/client'
import {
	FormControl,
	FormLabel,
	Stack,
	Text,
	useDisclosure,
	Skeleton,
	Flex,
} from '@chakra-ui/react'
import { BiEdit } from 'react-icons/bi'
import { BsPerson } from 'react-icons/bs'
import { CiBullhorn } from 'react-icons/ci'
import { IoPaperPlaneOutline } from 'react-icons/io5'

import React, { FC, useEffect } from 'react'

import Button from '~/components/ui/Button'
import SourceView from '~/views/source'

import { LeadSources, LeadSourcesVariables } from './__generated__/LeadSources'

/***
 *
 * Queries & Mutations
 *
 ***/
const leadSourcesQuery = gql`
	query LeadSources($id: ID!) {
		lead(id: $id) {
			id
			sources {
				id
				medium
				campaign
				source
			}
		}
	}
`

/***
 *
 * Interface & Type
 *
 ***/
interface Props {
	leadId?: string
	onAddSource?: (id: string) => void
}

/***
 *
 * Source Input Component
 *
 ***/
const SourceInput: FC<Props> = props => {
	const { leadId, onAddSource } = props
	const { isOpen, onOpen, onClose } = useDisclosure()
	const inEditMode = leadId ? true : false

	const [fetchLeadSources, { data, loading, refetch }] = useLazyQuery<
		LeadSources,
		LeadSourcesVariables
	>(leadSourcesQuery)

	const latestSource = data?.lead?.sources?.length
		? data?.lead?.sources[data.lead.sources.length - 1]
		: null

	const onRefetchHandler = async (id: string) => {
		if (leadId && refetch) {
			await refetch({ id: leadId })
			return
		}

		fetchLeadSources({ variables: { id } })
	}

	useEffect(() => {
		leadId && fetchLeadSources({ variables: { id: leadId } })
	}, [leadId])

	if (loading)
		return (
			<Stack spacing={10} w='full' mt={10}>
				<Skeleton height='4em' rounded='md' />
			</Stack>
		)

	return (
		<>
			<FormControl isRequired={!inEditMode}>
				<Flex align='center' justify='space-between'>
					<FormLabel
						fontSize='1em'
						color='#191C1A'
						fontWeight='bold'
						textTransform='uppercase'
						requiredIndicator={<span />}
						m={0}
					>
						Source
					</FormLabel>
					<Button
						colorScheme='transparent'
						p={0}
						size='sm'
						leftIcon={<BiEdit color='#0000004d' fontSize={20} />}
						onClick={onOpen}
					/>
				</Flex>

				{latestSource ? (
					<Stack spacing='0.5em' mt='1em'>
						{latestSource.source && (
							<Flex align='center' gap='0.5em'>
								<BsPerson color='#EEAB7E' fontSize={20} />
								<Text color='#64554B' fontSize='1em' fontWeight={400} casing='capitalize'>
									{latestSource.source}
								</Text>
							</Flex>
						)}

						{latestSource.medium && (
							<Flex align='center' gap='0.5em'>
								<IoPaperPlaneOutline color='#EEAB7E' fontSize={20} />
								<Text color='#64554B' fontSize='1em' fontWeight={400} casing='capitalize'>
									{latestSource.medium}
								</Text>
							</Flex>
						)}

						{latestSource.campaign && (
							<Flex align='center' gap='0.5em'>
								<CiBullhorn color='#EEAB7E' fontSize={20} />
								<Text color='#64554B' fontSize='1em' fontWeight={400} casing='capitalize'>
									{latestSource.campaign}
								</Text>
							</Flex>
						)}
					</Stack>
				) : (
					<Flex>
						<Text fontSize='0.9em' color='gray.400'>
							No source added
						</Text>
					</Flex>
				)}
			</FormControl>

			{isOpen && (
				<SourceView
					source={latestSource}
					leadId={leadId}
					isOpen={isOpen}
					onClose={onClose}
					onAddSource={onAddSource}
					onRefetch={onRefetchHandler}
				/>
			)}
		</>
	)
}

export default SourceInput
