import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	ModalFooter,
	Button,
	FormControl,
	FormLabel,
	Box,
	useDisclosure,
	Tag,
} from '@chakra-ui/react'
import { SingleValue } from 'react-select'

import { FC, useState } from 'react'

import SelectDropdown, { PeopleOptionFactory } from '~/components/ui/SelectDropdown'
import { useUpdateLead } from '~/crud/lead'
import { ListUsers_users } from '~/inputs/__generated__/ListUsers'
import { AssigneesViewType } from '~/inputs/assignees-role'
import UserView from '~/views/user'

/***
 *
 * Interface & Type
 *
 ***/
interface Props {
	isOpen: boolean
	leadId?: string
	onClose: () => void
	userList: ListUsers_users[]
	viewType: AssigneesViewType
	defaultValue?: string
}

/***
 *
 * Assignees Input Component
 *
 ***/
const AssigneesRoleView: FC<Props> = props => {
	const { isOpen, onClose, userList, defaultValue } = props
	const userViewModal = useDisclosure()
	const [isLoading, setIsLoading] = useState(false)

	const { handleLeadUpdate } = useUpdateLead({
		refetchQueries: ['FindLead'],
		onSuccess: {
			message: 'Lead has been updated',
			handler: () => {
				setIsLoading(false)
			},
		},
	})

	const [userId, setUserId] = useState('')
	const [assignee, setAssignee] = useState<string | null>(defaultValue || null)

	const onView = (id: string) => {
		setUserId(id)
		userViewModal.onOpen()
	}

	const submitHandler = async () => {
		if (props.leadId) {
			setIsLoading(true)
			await handleLeadUpdate(props.leadId, { [`${props.viewType}_assignee`]: assignee })
			onClose()
		}
	}
	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay backdropBlur='xl' />
				<ModalContent as='form'>
					<ModalHeader>Select</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<FormControl isRequired>
							<FormLabel color='gray.600' fontWeight='bold'>
								Assignees
							</FormLabel>
							<Box>
								<SelectDropdown
									// error={!assignees.length && submitAttempted ? true : false}
									variant='small'
									isDisabled={isLoading}
									options={userList}
									placeholder='Choose Assignee'
									getOptionLabel={opt => opt.username}
									getOptionValue={opt => opt.id}
									getOptionTag={opt => (
										<Tag
											colorScheme='green'
											size='sm'
											textTransform={
												['tco', 'cst'].includes(opt.role?.type || '') ? 'uppercase' : 'capitalize'
											}
										>
											{opt.role?.type}
										</Tag>
									)}
									defaultValue={userList?.find(opt => opt.id === assignee)}
									components={{
										Option: PeopleOptionFactory<ListUsers_users>({ onView }),
									}}
									onChange={value => {
										const option = value as SingleValue<ListUsers_users>

										setAssignee(option?.id || null)
									}}
								/>
							</Box>
						</FormControl>
					</ModalBody>
					<ModalFooter mt={2}>
						<Button onClick={onClose} mr={3} isDisabled={isLoading}>
							Cancel
						</Button>
						<Button
							colorScheme='green'
							type='button'
							isDisabled={isLoading}
							isLoading={isLoading}
							onClick={submitHandler}
						>
							Save
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>

			{isOpen && (
				<UserView userId={userId} isOpen={userViewModal.isOpen} onClose={userViewModal.onClose} />
			)}
		</>
	)
}

export default AssigneesRoleView
