import { gql, useQuery } from '@apollo/client'
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Image,
	Flex,
	Skeleton,
	Stack,
	Text,
	Box,
	useDisclosure,
	Modal,
	ModalOverlay,
	ModalContent,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import { AiOutlineZoomIn } from 'react-icons/ai'

import React, { FC, MouseEvent, useState } from 'react'

import EmailsForm from '~/components/lead/components/EmailsForm'
import {
	EmailTemplates,
	EmailTemplates_emailTemplates,
} from '~/components/lead/components/__generated__/EmailTemplates'
import { filterEmailTemplates, TreeNode } from '~/components/lead/components/helpers'
import { FindLead_findLead } from '~/views/__generated__/FindLead'

const StyledPopupButton = styled.div<{ disabled: boolean }>`
	width: 50px;
	height: 50px;
	display: flex;
	margin-right: 10px;
	align-items: center;
	justify-content: center;
	position: relative;
	svg {
		width: 20px;
		height: 20px;
		position: absolute;
		opacity: 0;
	}
	&:hover {
		svg {
			opacity: 1;
			z-index: 2;
		}
		&:after {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.5);
		}
	}
	&:disabled {
		&:hover {
			svg {
				opacity: 0;
			}
			&:after {
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
				background-color: transparent;
			}
		}
	}
`

interface CorrespondenceTabProps {
	leadDetails?: FindLead_findLead
	mode: 'add' | 'edit'
	onRefetch: () => Promise<void>
}

export const emailTemplatesQuery = gql`
	query EmailTemplates {
		emailTemplates {
			templates {
				id
				title
				updated_at
				thumbnail_url
				versionId
			}
		}
	}
`

const EmailsTab: FC<CorrespondenceTabProps> = props => {
	const { leadDetails, onRefetch } = props
	const [emailTemplates, setEmailTemplates] = useState<EmailTemplates_emailTemplates>()
	const [zoomedImage, setZoomedImage] = useState<string | null>('')
	const { isOpen, onOpen, onClose } = useDisclosure()

	const { loading } = useQuery<EmailTemplates>(emailTemplatesQuery, {
		onCompleted: data => setEmailTemplates(data.emailTemplates),
	})

	const openPopup = (event: MouseEvent<HTMLDivElement>, imageSrc: string) => {
		event.preventDefault()
		setZoomedImage(imageSrc)
		onOpen()
	}

	const closePopup = () => {
		setZoomedImage(null)
		onClose()
	}

	if (loading) {
		return (
			<Stack spacing={10} w='10em'>
				<Skeleton height='3em' rounded='md' />
			</Stack>
		)
	}

	const renderTemplates = () => {
		if (!emailTemplates?.templates.length) {
			return <p>No templates</p>
		}
		const filteredTemplates = filterEmailTemplates(emailTemplates?.templates)

		const renderTemplate = (treeNode: TreeNode) => {
			if (!treeNode.children.length) {
				const imageSrc = treeNode.thumbnail_url
					? treeNode.thumbnail_url
					: 'https://placehold.co/50x50?text=No+Preview'

				return (
					<AccordionItem key={treeNode.id}>
						{({ isExpanded }) => (
							<>
								<Flex width='100%' alignItems='center' _hover={{ backgroundColor: '#f7f2f2' }}>
									<AccordionButton
										sx={{ padding: '20px 0' }}
										_hover={{ backgroundColor: 'transparent' }}
									>
										<AccordionIcon sx={{ color: '#AF9179', marginRight: '20px' }} />
										<StyledPopupButton
											onClick={event => openPopup(event, imageSrc)}
											disabled={!treeNode.thumbnail_url}
										>
											<AiOutlineZoomIn fill='#fff' />
											<Box sx={{ width: '100%' }}>
												<Image src={imageSrc} alt='email template image' />
											</Box>
										</StyledPopupButton>
										<Text textAlign='left' color='#64554B' fontWeight={400} fontSize='1em'>
											{treeNode.name}
										</Text>
									</AccordionButton>
								</Flex>
								<AccordionPanel pb={4}>
									{isExpanded && (
										<EmailsForm
											templateId={treeNode.id as string}
											templateTitle={treeNode.title!}
											versionId={treeNode.versionId!}
											leadDetails={leadDetails}
											onRefetch={onRefetch}
										/>
									)}
								</AccordionPanel>
							</>
						)}
					</AccordionItem>
				)
			}
			return (
				<AccordionItem key={treeNode.id}>
					<>
						<Flex width='100%' alignItems='center' _hover={{ backgroundColor: '#f7f2f2' }}>
							<AccordionButton
								sx={{ padding: '20px 0' }}
								_hover={{ backgroundColor: 'transparent' }}
							>
								<AccordionIcon sx={{ color: '#AF9179', marginRight: '20px' }} />
								<Text textAlign='left' color='#64554B' fontWeight={400} fontSize='1em'>
									{treeNode.name}
								</Text>
							</AccordionButton>
						</Flex>
						<AccordionPanel pb={4}>
							<>
								{treeNode.children.map(child => {
									return renderTemplate(child)
								})}
							</>
						</AccordionPanel>
					</>
				</AccordionItem>
			)
		}

		return filteredTemplates.map(treeNode => renderTemplate(treeNode))
	}

	return (
		<Flex direction='column'>
			<Accordion defaultIndex={[0]} allowMultiple>
				{renderTemplates()}
			</Accordion>
			<Modal isOpen={isOpen} onClose={closePopup}>
				<ModalOverlay />
				<ModalContent>
					<Box sx={{ width: '100%' }}>
						<Image src={zoomedImage as string} alt='email template image zoomed' />
					</Box>
				</ModalContent>
			</Modal>
		</Flex>
	)
}

export default EmailsTab
