import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { AddIcon } from '@chakra-ui/icons'
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	FormControl,
	FormLabel,
	Stack,
	Heading,
	Text,
	useDisclosure,
	Divider,
	VStack,
	Skeleton,
	Flex,
	Link,
	Alert,
	AlertIcon,
	AlertTitle,
	Avatar,
	Drawer,
	DrawerOverlay,
	DrawerContent,
	DrawerHeader,
	DrawerBody,
} from '@chakra-ui/react'
import { navigate } from 'gatsby'
import { Controller, useForm } from 'react-hook-form'
import { BiEdit } from 'react-icons/bi'
import { FaMoneyCheck, FaEnvelopeOpenText, FaExchangeAlt } from 'react-icons/fa'
import { SingleValue } from 'react-select'

import React, { FC, useEffect, useState } from 'react'

import BalanceForm from '~/components/forms/BalanceForm'
import CreateDentallyPatientForm from '~/components/forms/CreatePatientDentally/CreateDentallyPatientForm'
import { joinValues, strMaxLength } from '~/components/helpers'
import Button from '~/components/ui/Button'
import SelectDropdown, { PeopleOptionFactory } from '~/components/ui/SelectDropdown'
import { useUpdateLead } from '~/crud/lead'
import { FindLead_findLead } from '~/views/__generated__/FindLead'
import { ListPeople, ListPeople_people } from '~/views/__generated__/ListPeople'
import PersonView from '~/views/person'
import { listPeopleQuery } from '~/views/source'
import TransactionsView from '~/views/transactions'

import {
	PersonDetails,
	PersonDetailsVariables,
	PersonDetails_person_transaction_history,
} from './__generated__/PersonDetails'
import {
	PersonLinkedLeads,
	PersonLinkedLeadsVariables,
	PersonLinkedLeads_leads,
} from './__generated__/PersonLinkedLeads'

/***
 *
 * Queries & Mutations
 *
 ***/
export const personDetailQuery = gql`
	query PersonDetails($personId: ID!) {
		person(id: $personId) {
			id
			wizard_step
			first_name
			middle_name
			twilio_conversation_sid
			last_name
			dentally_uuid
			dentally_person_id
			phone
			email
			balance
			transaction_history(sort: "created_at:desc") {
				id
				amount
				type
				medium
				note
				created_at
				owner {
					id
					username
				}
			}
		}
	}
`

const personLinkedLeads = gql`
	query PersonLinkedLeads($where: JSON) {
		leads(where: $where) {
			id
		}
	}
`

/***
 *
 * Interface & Type
 *
 ***/
interface Props {
	personId?: string
	leadDetails?: FindLead_findLead
}

/***
 *
 * Person Input Component
 *
 ***/
const PersonInput: FC<Props> = props => {
	const { personId, leadDetails } = props
	const inEditMode = Boolean(personId)

	const personSelectModal = useDisclosure()
	const personViewModal = useDisclosure()
	const transactionsViewModal = useDisclosure()
	const topUpFormModal = useDisclosure()
	const dentallyModal = useDisclosure()

	const [linkedLead, setlinkedLead] = useState<PersonLinkedLeads_leads | null>(null)

	const [
		fetchPersonDetails,
		{ data: personDetails, loading: personDetailsLoading, refetch: refetchPerson },
	] = useLazyQuery<PersonDetails, PersonDetailsVariables>(personDetailQuery)

	const [fetchPersonLinkedLeads, personLinkedLeadsResult] = useLazyQuery<
		PersonLinkedLeads,
		PersonLinkedLeadsVariables
	>(personLinkedLeads, {
		fetchPolicy: 'network-only',
		onCompleted: data => {
			const leads = data?.leads?.filter(Boolean) as PersonLinkedLeads_leads[]
			const linkedLead = leads?.length > 0 ? leads[0] : null

			setlinkedLead(linkedLead)
		},
	})

	const [personView, setPersonView] = useState<{ personId: string; editView: boolean }>({
		personId: '',
		editView: false,
	})

	const [balanceFormMode, setBalanceFormMode] = useState<'top_up' | 'refund'>()

	const personSelectForm = useForm<{ person: string }>({
		mode: 'onChange',
	})

	const {
		data: peopleData,
		loading: loadingPeople,
		refetch: refetchPeople,
	} = useQuery<ListPeople>(listPeopleQuery)

	const { handleLeadUpdate } = useUpdateLead({
		refetchQueries: ['LeadDetails', 'PersonDetails'],
		onSuccess: {
			message: inEditMode ? 'Person updated.' : 'Person added.',
			handler: () => {
				personSelectModal.onClose()
			},
		},
	})

	const balanceAmountInPounds = personDetails?.person?.balance
		? personDetails.person.balance / 100
		: 0

	const transactions =
		(personDetails?.person?.transaction_history?.filter(
			Boolean
		) as PersonDetails_person_transaction_history[]) || []

	const refetchPersonDetails = async () => {
		await refetchPerson?.()
	}

	const updatePersonHandler = async (formData: { person: string }) => {
		if (!leadDetails?.id) return
		await handleLeadUpdate(leadDetails?.id, formData)
	}

	const onView = (id: string) => {
		setPersonView({ personId: id, editView: true })
		personViewModal.onOpen()
	}

	const onCancel = () => {
		const personId = personDetails?.person?.id

		if (linkedLead && personId) personSelectForm.setValue('person', personId)

		setlinkedLead(null)
		personSelectModal.onClose()
	}

	const checkLinkedLeads = async (personId: string) => {
		fetchPersonLinkedLeads({ variables: { where: { person: personId } } })
	}

	useEffect(() => {
		personId &&
			fetchPersonDetails({
				variables: { personId },
			})
	}, [inEditMode])

	const redirectToProfile = () => {
		return navigate(`/person/${personId}`)
	}

	if (personDetailsLoading)
		return (
			<Stack>
				<Skeleton height='19.5em' rounded='md' />
			</Stack>
		)
	return (
		<>
			<FormControl isRequired>
				<Flex align='center' justify='space-between'>
					<FormLabel
						fontSize='1em'
						color='#191C1A'
						fontWeight='bold'
						textTransform='uppercase'
						requiredIndicator={<span />}
						m={0}
					>
						Personal Info
					</FormLabel>
					<Button
						title={inEditMode || personDetails?.person?.id ? 'Edit' : 'Add'}
						aria-label={inEditMode || personDetails?.person?.id ? 'Edit' : 'Add'}
						colorScheme='transparent'
						p={0}
						size='sm'
						leftIcon={<BiEdit color='#0000004d' fontSize={20} />}
						onClick={() => (inEditMode && personId ? onView(personId) : personSelectModal.onOpen())}
					/>
				</Flex>

				{(inEditMode || personDetails?.person?.id) && (
					<Flex w='full' direction='column' mt='1em'>
						<Flex w='full' alignItems='center' pb='1em'>
							<Avatar
								w='79px'
								h='79px'
								name={`${personDetails?.person?.first_name} ${personDetails?.person?.last_name}`}
								border='4px solid #EEAB7E'
							/>
							<Flex w='full' direction='column' ml='1em'>
								<Heading
									color='#191C1A'
									fontSize='1.2em'
									fontWeight={700}
									mb={1.5}
									onClick={redirectToProfile}
									_hover={{ textDecoration: 'underline' }}
									cursor='pointer'
								>
									{strMaxLength(
										joinValues(
											personDetails?.person?.first_name,
											personDetails?.person?.middle_name,
											personDetails?.person?.last_name
										),
										26
									)}
								</Heading>
								<Text fontSize='1em' color='#64554B'>
									{personDetails?.person?.phone}
								</Text>
								<Text fontSize='1em' color='#64554B'>
									{personDetails?.person?.email
										? strMaxLength(personDetails?.person?.email, 26)
										: 'No email'}
								</Text>
							</Flex>
						</Flex>
						{personDetails?.person?.dentally_uuid ? (
							<Button
								size='sm'
								bg='gray.600'
								color='white'
								_hover={{ bg: 'gray.600' }}
								onClick={() => {
									window.open(
										`${process.env.GATSBY_DENTALLY_URL}/patients/${personDetails?.person?.dentally_uuid}`,
										'_blank'
									)
								}}
							>
								Dentally info
							</Button>
						) : (
							<Button
								size='sm'
								bg='gray.600'
								color='white'
								_hover={{ bg: 'gray.600' }}
								onClick={dentallyModal.onOpen}
							>
								Provide Dentally data
							</Button>
						)}
						<Divider my='1em' borderColor='#D1D1D1' />
						<Flex w='full' gap='0.4em' direction='column' mt='1em' pb='1em'>
							<FormLabel
								fontSize='1em'
								color='#191C1A'
								fontWeight='bold'
								textTransform='uppercase'
								requiredIndicator={<span />}
								m={0}
							>
								Balance
							</FormLabel>
							<Stack gap='0.5em'>
								<Text fontSize='1.2em' color={balanceAmountInPounds > 0 ? 'gray.500' : 'red.500'}>
									£ {balanceAmountInPounds}
								</Text>
								<Flex gap='0.4em'>
									<Button
										size='sm'
										bg='gray.600'
										color='white'
										_hover={{ bg: 'gray.600' }}
										leftIcon={<FaMoneyCheck />}
										onClick={() => {
											setBalanceFormMode('top_up')
											topUpFormModal.onOpen()
										}}
									>
										Add Funds
									</Button>
									{balanceAmountInPounds > 0 && (
										<Button
											size='sm'
											colorScheme='red'
											leftIcon={<FaEnvelopeOpenText />}
											onClick={() => {
												setBalanceFormMode('refund')
												topUpFormModal.onOpen()
											}}
										>
											Refund
										</Button>
									)}
									{transactions.length > 0 && (
										<Button
											size='sm'
											colorScheme='yellow'
											leftIcon={<FaExchangeAlt />}
											onClick={() => transactionsViewModal.onOpen()}
										>
											Transactions
										</Button>
									)}
								</Flex>
							</Stack>
						</Flex>
					</Flex>
				)}
			</FormControl>

			{topUpFormModal.isOpen && personDetails?.person?.id && leadDetails?.id && (
				<BalanceForm
					mode={balanceFormMode}
					leadId={leadDetails?.id}
					onRefetch={refetchPersonDetails}
					personId={personDetails.person.id}
					isOpen={topUpFormModal.isOpen}
					onClose={topUpFormModal.onClose}
				/>
			)}

			<Modal isOpen={personSelectModal.isOpen} onClose={personSelectModal.onClose}>
				<ModalOverlay backdropBlur='xl' />
				<ModalContent as='form'>
					<ModalHeader>Choose Person</ModalHeader>
					<ModalCloseButton />
					<ModalBody pb={6}>
						<VStack spacing='2em'>
							<FormControl isRequired>
								<Controller
									name='person'
									control={personSelectForm.control}
									defaultValue={personDetails?.person?.id}
									rules={{ required: true }}
									render={({ field: { value, onChange } }) => (
										<SelectDropdown<ListPeople_people>
											variant='small'
											options={peopleData?.people as ListPeople_people[]}
											placeholder='Choose Person'
											getOptionLabel={opt =>
												joinValues(opt.first_name, opt.middle_name, opt.last_name)
											}
											getOptionValue={opt => opt.id}
											getOptionSublabel={opt => opt.email}
											defaultValue={peopleData?.people?.find(opt => opt?.id === value)}
											isLoading={loadingPeople}
											components={{
												Option: PeopleOptionFactory<ListPeople_people>({ onView }),
											}}
											error={!!personSelectForm.formState.errors.person}
											onChange={async selected => {
												const newOption = selected as SingleValue<ListPeople_people>
												if (!newOption?.id) return

												await checkLinkedLeads(newOption.id)
												onChange(newOption.id)
											}}
										/>
									)}
								/>
							</FormControl>

							{linkedLead && (
								<Alert rounded='md' status='error' variant='left-accent'>
									<AlertIcon />
									<AlertTitle fontSize='0.8em'>Person already associated with lead!</AlertTitle>
									<Link href={`/lead/${linkedLead.id}`} target='_blank'>
										<Button
											textDecoration='none'
											fontSize='0.8em'
											type='button'
											size='sm'
											colorScheme='teal'
										>
											View lead
										</Button>
									</Link>
								</Alert>
							)}

							<VStack w='full'>
								<Divider />
								<Text color='gray.600'>OR</Text>
								<Divider />
							</VStack>

							<Button
								title='Add new'
								aria-label='Add new'
								colorScheme='green'
								leftIcon={<AddIcon />}
								onClick={() => {
									setPersonView({ personId: '', editView: false })
									personViewModal.onOpen()
								}}
							/>
						</VStack>
					</ModalBody>

					<ModalFooter>
						<Button
							onClick={onCancel}
							mr={3}
							disabled={personSelectForm.formState.isSubmitting || personLinkedLeadsResult.loading}
						>
							Cancel
						</Button>
						<Button
							colorScheme='green'
							type='button'
							onClick={personSelectForm.handleSubmit(updatePersonHandler)} // Using handleSubmit on button to avoid event bubbling, as this is a nested form.
							isLoading={personSelectForm.formState.isSubmitting}
							disabled={
								personSelectForm.formState.isSubmitting ||
								personLinkedLeadsResult.loading ||
								Boolean(linkedLead)
							}
						>
							Save
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>

			<Drawer
				placement='right'
				onClose={dentallyModal.onClose}
				isOpen={dentallyModal.isOpen}
				size='lg'
			>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerHeader
						sx={{
							background: 'linear-gradient(90deg, #C29265 0%, #B68150 100%)',
							padding: '20px 40px',
							color: '#fff',
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<Text sx={{ marginRight: '10px' }}>Create Patient</Text>
					</DrawerHeader>
					<DrawerBody sx={{ padding: 0 }}>
						<CreateDentallyPatientForm
							defaultFormValues={{
								email: leadDetails?.person?.email,
								first_name: leadDetails?.person?.first_name,
								last_name: leadDetails?.person?.last_name as string,
								phone: leadDetails?.person?.phone,
							}}
							onClose={dentallyModal.onClose}
							leadId={leadDetails?.id}
							trackerPatientId={leadDetails?.person?.id as string}
						/>
					</DrawerBody>
				</DrawerContent>
			</Drawer>

			{personViewModal.isOpen && (
				<PersonView
					isOpen={personViewModal.isOpen}
					onClose={personViewModal.onClose}
					inEditMode={personView.editView}
					personId={personView.personId}
					onRefetch={refetchPeople}
					onAdd={updatePersonHandler}
				/>
			)}

			{transactionsViewModal.isOpen && transactions.length > 0 && (
				<TransactionsView
					transactions={transactions}
					isOpen={transactionsViewModal.isOpen}
					onClose={transactionsViewModal.onClose}
				/>
			)}
		</>
	)
}

export default PersonInput
