import { gql, useMutation } from '@apollo/client'
import { editLeadInput } from '__generated__/globalTypes'

import { onError } from '~/components/helpers'
import useToast from '~/components/ui/Toast'

import { LeadUpdate, LeadUpdateVariables } from './__generated__/LeadUpdate'

/***
 *
 * Custom hook for lead update actions
 *
 ***/
const updateLeadMutation = gql`
	mutation LeadUpdate($leadData: updateLeadInput!) {
		updateLead(input: $leadData) {
			lead {
				id
			}
		}
	}
`

interface SuccessConfig {
	message?: string
	handler: (data?: LeadUpdate) => void
}

type LeadUpdateArgs = {
	onSuccess: SuccessConfig
	refetchQueries?: string[]
}

type LeadUpdateHandler = (id: string, data: editLeadInput) => Promise<void>

export const useUpdateLead = (args?: LeadUpdateArgs): { handleLeadUpdate: LeadUpdateHandler } => {
	const toast = useToast()

	const [updateLead] = useMutation<LeadUpdate, LeadUpdateVariables>(updateLeadMutation, {
		onError: error => onError(error, toast),
		refetchQueries: args?.refetchQueries,
	})

	const handleLeadUpdate: LeadUpdateHandler = async (id, inputData) => {
		const { data, errors } = await updateLead({
			variables: {
				leadData: { where: { id }, data: inputData },
			},
		})

		if (data !== null && !errors) {
			args?.onSuccess.handler && args.onSuccess.handler(data)

			toast({
				title: args?.onSuccess.message || 'Lead updated',
				status: 'success',
				position: 'top-right',
			})
		}
	}

	return { handleLeadUpdate }
}
