import { gql, useMutation } from '@apollo/client'
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	ModalFooter,
	Button,
	FormControl,
	FormLabel,
	Box,
	useDisclosure,
	Tag,
} from '@chakra-ui/react'
import { MultiValue } from 'react-select'

import { FC, useState } from 'react'

import { onError } from '~/components/helpers'
import SelectDropdown, { PeopleOptionFactory } from '~/components/ui/SelectDropdown'
import useToast from '~/components/ui/Toast'
import { setKanbanRefetchVal } from '~/helpers'
import { ListUsers_users } from '~/inputs/__generated__/ListUsers'
import UserView from '~/views/user'

import { UpdateAssignees, UpdateAssigneesVariables } from './__generated__/UpdateAssignees'

/***
 *
 * Queries & Mutations
 *
 ***/
const updateAssigneesMutation = gql`
	mutation UpdateAssignees($input: updateStageInput) {
		updateStage(input: $input) {
			stage {
				id
			}
		}
	}
`

/***
 *
 * Interface & Type
 *
 ***/
interface Props {
	isOpen: boolean
	onClose: () => void
	userList: ListUsers_users[]
	defaultValues?: { id: string; type?: string | null }[]
	onAdd?: (selected: ListUsers_users[]) => void
	stageId?: string
	onRefetch: () => Promise<void>
}

/***
 *
 * Assignees Input Component
 *
 ***/
const AssigneesView: FC<Props> = props => {
	const { isOpen, onClose, userList, defaultValues, onAdd, stageId, onRefetch } = props
	const userViewModal = useDisclosure()
	const toast = useToast()

	const [updateAssignees, { loading }] = useMutation<UpdateAssignees, UpdateAssigneesVariables>(
		updateAssigneesMutation,
		{
			onError: error => onError(error, toast),
		}
	)

	const [userId, setUserId] = useState('')
	const [assignees, setAssignees] = useState<string[]>(
		defaultValues ? defaultValues.map(value => value.id) : []
	)
	const [submitAttempted, setSubmitAttempted] = useState(false)

	const onView = (id: string) => {
		setUserId(id)
		userViewModal.onOpen()
	}

	const submitHandler = async () => {
		setSubmitAttempted(true)
		setKanbanRefetchVal('true')

		if (!assignees.length) {
			toast({
				title: 'At least 1 assignee is requried.',
				status: 'error',
				position: 'top-right',
			})
			return
		}

		if (stageId) {
			await updateAssignees({
				variables: { input: { where: { id: stageId }, data: { assignees } } },
			})

			await onRefetch()
			onClose()

			toast({
				title: 'Assignees updated',
				status: 'success',
				position: 'top-right',
			})
			return
		}

		const selectedAssignees = userList.filter(item => assignees.includes(item.id))
		onAdd?.(selectedAssignees)

		toast({
			title: 'Assignees added.',
			status: 'success',
			position: 'top-right',
		})

		onClose()
	}

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay backdropBlur='xl' />
				<ModalContent as='form'>
					<ModalHeader>Select</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<FormControl isRequired>
							<FormLabel color='gray.600' fontWeight='bold'>
								Assignees
							</FormLabel>
							<Box>
								<SelectDropdown
									error={!assignees.length && submitAttempted ? true : false}
									variant='small'
									isMulti
									isDisabled={loading}
									options={userList}
									placeholder='Choose Assignees'
									getOptionLabel={opt => opt.username}
									getOptionValue={opt => opt.id}
									getOptionTag={opt => (
										<Tag
											colorScheme='green'
											size='sm'
											textTransform={
												['tco', 'cst'].includes(opt.role?.type || '') ? 'uppercase' : 'capitalize'
											}
										>
											{opt.role?.type}
										</Tag>
									)}
									defaultValue={
										userList?.filter(opt => {
											const ids = defaultValues?.map(v => v.id) ?? []
											return ids.includes(opt?.id ?? '')
										}) as ListUsers_users[]
									}
									components={{
										Option: PeopleOptionFactory<ListUsers_users>({ onView }),
									}}
									onChange={value => {
										const selectedValues = value as MultiValue<ListUsers_users>
										const ids = selectedValues.map(item => item.id)

										setAssignees(ids)
									}}
								/>
							</Box>
						</FormControl>
					</ModalBody>
					<ModalFooter mt={2}>
						<Button onClick={onClose} mr={3} isDisabled={loading}>
							Cancel
						</Button>
						<Button
							colorScheme='green'
							type='button'
							isDisabled={loading}
							isLoading={loading}
							onClick={submitHandler}
						>
							Save
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>

			{isOpen && (
				<UserView userId={userId} isOpen={userViewModal.isOpen} onClose={userViewModal.onClose} />
			)}
		</>
	)
}

export default AssigneesView
