import { gql, useMutation, useQuery } from '@apollo/client'
import {
	Avatar,
	Box,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Spinner,
	Text,
	Textarea,
} from '@chakra-ui/react'
import { useFormik } from 'formik'
import moment from 'moment'
import { default as ReactDatePicker } from 'react-datepicker'
import { SingleValue } from 'react-select'
import * as Yup from 'yup'

import React, { useEffect, useState } from 'react'

import { DentallyAppointmentDurationArray } from '~/components/Dentally/helpers'
import {
	dentallySitesMutation,
	getPractitionersMutation,
} from '~/components/forms/CreatePatientDentally/CreateDentallyPatientForm'
import {
	GetPractitioners,
	GetPractitioners_getPractitioners_practitioners,
	GetPractitionersVariables,
} from '~/components/forms/CreatePatientDentally/__generated__/GetPractitioners'
import {
	GetSites,
	GetSites_getSites_sites,
} from '~/components/forms/CreatePatientDentally/__generated__/GetSites'
import { onError } from '~/components/helpers'
import {
	CreateDentallyAppointment,
	CreateDentallyAppointmentVariables,
} from '~/components/lead/components/CreateAppointmentForm/__generated__/CreateDentallyAppointment'
import {
	GetAppointmentsAvailability,
	GetAppointmentsAvailability_getAppointmentsAvailability_availability,
	GetAppointmentsAvailabilityVariables,
} from '~/components/lead/components/CreateAppointmentForm/__generated__/GetAppointmentsAvailability'
import { getAppointmentsDataQuery } from '~/components/lead/components/LeadAppts'
import { AppointmentReasonsSettingsQuery } from '~/components/settings/Settings'
import { getAppointmentReasonsSettings } from '~/components/settings/__generated__/getAppointmentReasonsSettings'
import Button from '~/components/ui/Button'
import SelectDropdown from '~/components/ui/SelectDropdown'
import useToast from '~/components/ui/Toast'

import './form-datepicker-styles.css'

export const createDentallyAppointmentMutation = gql`
	mutation CreateDentallyAppointment($data: createDentallyAppointmentFields!) {
		createDentallyAppointment(data: $data) {
			id
		}
	}
`

export const getAppointmentsAvailabilityMutation = gql`
	mutation GetAppointmentsAvailability($params: getAppointmentsAvailabilityParams!) {
		getAppointmentsAvailability(params: $params) {
			availability {
				start_time
				finish_time
				available_duration
			}
		}
	}
`

interface CreateAppointmentFormProps {
	isOpen: boolean
	onClose: () => void
	dentallyPatientId?: string | null
}

const CreateAppointmentForm: React.FC<CreateAppointmentFormProps> = ({
	isOpen,
	onClose,
	dentallyPatientId,
}) => {
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [availabilitiesLoading, setAvailabilitiesLoading] = useState<boolean>(false)
	const [availabilitiesDateRange, setAvailabilitiesDateRange] = useState<{
		start_time: Date
		finish_time: Date
	}>({
		start_time: moment().add(1, 'hours').toDate(),
		finish_time: moment().endOf('month').toDate(),
	})
	const [datePickerValue, setDatePickerValue] = useState<Date | null>(null)
	const [loadingData, setLoadingData] = useState<boolean>(false)
	const [step, setStep] = useState<number>(1)
	const [practitioners, setPractitioners] = useState<
		GetPractitioners_getPractitioners_practitioners[]
	>([])
	const [dentallySites, setDentallySites] = useState<GetSites_getSites_sites[]>([])
	const [availabilities, setAvailabilities] = useState<
		GetAppointmentsAvailability_getAppointmentsAvailability_availability[]
	>([])

	const toast = useToast()

	const { data: appointmentReasonsData, loading: appointmentReasonsDataLoading } =
		useQuery<getAppointmentReasonsSettings>(AppointmentReasonsSettingsQuery)

	const [createDentallyAppointment] = useMutation<
		CreateDentallyAppointment,
		CreateDentallyAppointmentVariables
	>(createDentallyAppointmentMutation, {
		onError: error => onError(error, toast),
		refetchQueries: [
			{ query: getAppointmentsDataQuery, variables: { params: { patient_id: dentallyPatientId } } },
			'GetAppointmentsData',
		],
	})

	const [getPractitioners] = useMutation<GetPractitioners, GetPractitionersVariables>(
		getPractitionersMutation,
		{
			onError: error => onError(error, toast),
		}
	)

	const [getSites] = useMutation<GetSites>(dentallySitesMutation, {
		onError: error => onError(error, toast),
	})

	const [getAppointmentsAvailability] = useMutation<
		GetAppointmentsAvailability,
		GetAppointmentsAvailabilityVariables
	>(getAppointmentsAvailabilityMutation, {
		onError: error => onError(error, toast),
	})

	const createAppointmentSchema = Yup.object().shape({
		site_id: Yup.string().required('Site is required'),
		start_time: Yup.string().required('Start time is required'),
		finish_time: Yup.string().required('Finish time is required'),
		practitioner_id: Yup.number().required('Practitioner is required'),
		reason: Yup.string().required('Reason is required'),
		patient_id: Yup.number(),
		notes: Yup.string(),
	})

	const formik = useFormik({
		initialValues: {
			site_id: '',
			start_time: '',
			finish_time: '',
			duration: 15,
			practitioner_id: undefined,
			reason: '',
			patient_id: undefined,
			notes: '',
		},
		validateOnChange: true,
		validationSchema: createAppointmentSchema,
		onSubmit: () => {
			submitHandler()
		},
	})

	useEffect(() => {
		setLoadingData(true)
		getSites()
			.then(({ data }) => {
				if (data?.getSites) {
					setDentallySites(data.getSites.sites)
					formik.setFieldValue('site_id', data.getSites.sites[0].id)
				}
			})
			.finally(() => {
				setLoadingData(false)
			})
	}, [])

	useEffect(() => {
		if (step === 2 && formik.values.practitioner_id) {
			setLoadingData(true)
			setAvailabilitiesLoading(true)
			getAppointmentsAvailability({
				variables: {
					params: {
						start_time: availabilitiesDateRange.start_time as unknown as string,
						finish_time: availabilitiesDateRange.finish_time as unknown as string,
						practitioner_ids: [formik.values.practitioner_id],
						duration: formik.values.duration || 15,
					},
				},
			})
				.then(({ data }) => {
					if (data?.getAppointmentsAvailability) {
						setAvailabilities(data.getAppointmentsAvailability.availability)
					}
				})
				.finally(() => {
					setAvailabilitiesLoading(false)
					setLoadingData(false)
				})
		}
	}, [step, formik.values.duration, availabilitiesDateRange])

	useEffect(() => {
		if (formik.values.site_id) {
			setLoadingData(true)
			getPractitioners({
				variables: {
					params: {
						site_id: formik.values.site_id,
						active: true,
					},
				},
			})
				.then(({ data }) => {
					if (data?.getPractitioners) {
						setPractitioners(data.getPractitioners.practitioners)
					}
				})
				.finally(() => {
					setLoadingData(false)
				})
		}
	}, [formik.values.site_id])

	const getSiteValue = (value?: string | null) => {
		const item = dentallySites.find(site => site.id === value)
		if (item) {
			return {
				value: item.id,
				label: item.name,
			}
		}
		return undefined
	}

	const getPractitionerValue = (value?: number | null) => {
		const practitioner = practitioners.find(practitioner => practitioner.id === value)
		if (practitioner && practitioner.user) {
			return {
				value: practitioner.id,
				label: practitioner.user.first_name + ' ' + practitioner.user.last_name,
			}
		}
		return null
	}
	const getReasonsValue = (value?: string) => {
		const reason = appointmentReasonsData?.getAppointmentReasonsSettings.find(
			reason => reason.name === value
		)
		if (reason) {
			return {
				value: reason.name,
				label: reason.name,
			}
		}
		return null
	}

	const getAppointmentDurations = (value?: number) => {
		const duration = DentallyAppointmentDurationArray.find(duration => duration.value === value)
		if (duration) {
			return {
				value: duration.value,
				label: duration.label,
			}
		}
		return null
	}

	const submitHandler = async () => {
		await formik.validateForm().then(async () => {
			if (step === 1) {
				if (!formik.errors.practitioner_id && !formik.errors.reason) {
					setStep(2)
					return
				}
				return
			} else {
				setIsLoading(true)
				if (formik.values.practitioner_id && dentallyPatientId) {
					const model = {
						start_time: formik.values.start_time,
						finish_time: formik.values.finish_time,
						practitioner_id: formik.values.practitioner_id,
						reason: formik.values.reason,
						patient_id: Number(dentallyPatientId),
						notes: formik.values.notes,
					}
					await createDentallyAppointment({
						variables: {
							data: model,
						},
					}).then(() => {
						onClose()
					})
				}
			}
		})
	}

	const renderStep1 = () => {
		if (step === 1) {
			return (
				<Box>
					<FormControl w='100%' isRequired isInvalid={Boolean(formik?.errors?.site_id)} mb={2}>
						<FormLabel>Site</FormLabel>
						<SelectDropdown
							name='site_id'
							isLoading={loadingData}
							isDisabled={loadingData}
							variant='small'
							options={dentallySites.map(site => ({ label: site.name, value: site.id }))}
							placeholder='Select site'
							value={getSiteValue(formik.values.site_id)}
							onChange={selected => {
								const newOption = selected as SingleValue<{ value: string }>
								formik.setFieldValue('site_id', newOption?.value)
								formik.setFieldValue('practitioner_id', null)
							}}
						/>
					</FormControl>
					<FormControl
						w='100%'
						isRequired
						isInvalid={Boolean(formik?.errors?.practitioner_id)}
						mb={2}
					>
						<FormLabel>Practitioner</FormLabel>
						<SelectDropdown
							variant='small'
							required={true}
							isLoading={loadingData}
							isDisabled={loadingData}
							name='practitioner_id'
							isClearable={true}
							options={practitioners.map(practitioner => ({
								value: practitioner.id,
								label: practitioner.user?.first_name + ' ' + practitioner.user?.last_name,
							}))}
							value={getPractitionerValue(formik.values.practitioner_id)}
							placeholder='Select preferred Dentist'
							onChange={selected => {
								const newOption = selected as SingleValue<{ value: number }>
								formik.setFieldValue('practitioner_id', newOption?.value)
							}}
						/>
						<FormErrorMessage>Practitioner is required</FormErrorMessage>
					</FormControl>
					<FormControl w='100%' isRequired isInvalid={Boolean(formik?.errors?.reason)} mb={2}>
						<FormLabel>Reason</FormLabel>
						<SelectDropdown
							isLoading={appointmentReasonsDataLoading}
							isDisabled={appointmentReasonsDataLoading}
							variant='small'
							required={true}
							name='reason'
							isClearable={true}
							options={appointmentReasonsData?.getAppointmentReasonsSettings.map(reason => ({
								value: reason.name as string,
								label: reason.name as string,
							}))}
							value={getReasonsValue(formik.values.reason)}
							placeholder='Select Reason'
							onChange={selected => {
								const newOption = selected as SingleValue<{ value: string }>
								formik.setFieldValue('reason', newOption?.value)
							}}
						/>
						<FormErrorMessage>Reason is required</FormErrorMessage>
					</FormControl>
					<FormControl w='100%' isRequired isInvalid={Boolean(formik?.errors?.notes)} mb={2}>
						<FormLabel>Notes</FormLabel>
						<Textarea name='notes' required={false} placeholder='Add notes' fontSize='12px' />
					</FormControl>
				</Box>
			)
		}
		return null
	}

	const renderStep2 = () => {
		if (step === 2) {
			const practitioner = practitioners.find(
				practitioner => practitioner.id === formik.values.practitioner_id
			)

			const checkDayAvailability = (
				date: Date,
				availabilities: GetAppointmentsAvailability_getAppointmentsAvailability_availability[]
			) => {
				return availabilities.some(availability =>
					moment(availability.start_time).isSame(date, 'day')
				)
			}

			const renderDayContents = (day: number, date: Date) => {
				const tooltipText = `Tooltip for date: ${date}`
				const isAvailableDay = checkDayAvailability(date, availabilities)
				return (
					<span title={tooltipText} style={{ color: isAvailableDay ? 'green' : 'red' }}>
						{day}
					</span>
				)
			}

			const renderAvailableWindows = () => {
				const availableWindows = availabilities.filter(availability =>
					moment(availability.start_time).isSame(datePickerValue, 'day')
				)
				if (availableWindows.length) {
					return availableWindows.map(availability => {
						const isActive = moment(formik.values.start_time).isSame(
							availability.start_time,
							'minute'
						)
						return (
							<Flex
								onClick={() => {
									formik.setFieldValue('start_time', availability.start_time)
									formik.setFieldValue('finish_time', availability.finish_time)
								}}
								key={availability.start_time}
								sx={{
									cursor: 'pointer',
									background: isActive ? '#FFC299' : '#fff',
									border: '1px solid #FFC299',
									width: '100%',
									borderRadius: '6px',
									height: '50px',
									alignItems: 'center',
									justifyContent: 'center',
									marginBottom: '15px',
									_hover: {
										background: '#FFC299',
									},
								}}
							>
								<Text
									sx={{
										fontSize: '20px',
										color: '#717171',
									}}
								>
									{moment(availability.start_time).format('HH:mm')}
								</Text>
							</Flex>
						)
					})
				}
				return <Text>No available windows</Text>
			}

			return (
				<Box>
					<Flex>
						<Box w='50%' position='relative'>
							{availabilitiesLoading && (
								<Flex
									sx={{
										position: 'absolute',
										top: 0,
										left: 0,
										width: '100%',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										background: 'rgba(0,0,0,0.5)',
										zIndex: 5,
										height: '100%',
									}}
								>
									<Flex justify='center' gap='1em' p='1em'>
										<Spinner />
										<Text color='gray.500' fontSize='sm'>
											Loading
										</Text>
									</Flex>
								</Flex>
							)}
							<Box
								sx={{
									background: '#141414',
									display: 'flex',
									paddingTop: '20px',
									paddingBottom: '20px',
									alignItems: 'center',
									flexDirection: 'column',
									justifyContent: 'center',
								}}
							>
								<Avatar
									name={practitioner?.user?.first_name + '' + practitioner?.user?.last_name}
									src={practitioner?.user?.image_url || undefined}
									mb={3}
								/>
								<Text color='white' fontWeight='bold'>
									Book with {practitioner?.user?.first_name + ' ' + practitioner?.user?.last_name}
								</Text>
							</Box>
							<ReactDatePicker
								calendarClassName='appointments-datepicker'
								selected={datePickerValue}
								renderDayContents={renderDayContents}
								onMonthChange={date => {
									const startTime = moment(date).isSame(moment(), 'month')
										? moment().add(1, 'hours').toDate()
										: moment(date).startOf('month').toDate()

									formik.setFieldValue('start_time', '')
									formik.setFieldValue('finish_time', '')
									setAvailabilitiesDateRange({
										start_time: startTime,
										finish_time: moment(date).endOf('month').toDate(),
									})
								}}
								onChange={date => {
									// const startTime = moment(date).isSame(moment(), 'month')
									// 	? moment().add(1, 'hours').toDate()
									// 	: moment(date).startOf('month').toDate()
									setDatePickerValue(date)
									formik.setFieldValue('start_time', '')
									formik.setFieldValue('finish_time', '')
									// setAvailabilitiesDateRange({
									// 	start_time: startTime,
									// 	finish_time: moment(startTime).endOf('month').toDate(),
									// })
								}}
								maxDate={moment().add(3, 'months').toDate()}
								minDate={moment().toDate()}
								inline
							/>
						</Box>
						<Box w='50%' p={2}>
							<FormControl w='100%' isRequired isInvalid={Boolean(formik?.errors?.duration)} mb={2}>
								<FormLabel>Booking duration</FormLabel>
								<SelectDropdown
									variant='small'
									required={true}
									name='duration'
									options={DentallyAppointmentDurationArray}
									value={getAppointmentDurations(formik.values.duration)}
									placeholder='Select duration'
									onChange={selected => {
										const newOption = selected as SingleValue<{ value: number }>
										formik.setFieldValue('duration', newOption?.value)
									}}
								/>
								<FormErrorMessage>Duration is required</FormErrorMessage>
							</FormControl>
							<FormControl w='100%' mb={2}>
								<FormLabel>Availability Window</FormLabel>
								<Box
									sx={{
										maxHeight: '320px',
										overflowY: 'auto',
									}}
								>
									{renderAvailableWindows()}
								</Box>
							</FormControl>
						</Box>
					</Flex>
				</Box>
			)
		}
		return null
	}

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay backdropBlur='xl' />
			<ModalContent as='form' sx={{ maxWidth: step === 1 ? '450px' : '800px' }}>
				<ModalHeader
					sx={{
						background: 'linear-gradient(90deg, #C29265 24.23%, #B68150 86.46%)',
						color: 'white',
					}}
				>
					Book an Appointment
				</ModalHeader>
				<ModalCloseButton sx={{ marginTop: '7px' }} />
				<ModalBody>
					<form
						onSubmit={e => {
							e.preventDefault()
							submitHandler()
						}}
					>
						{renderStep1()}
						{renderStep2()}
					</form>
				</ModalBody>
				<ModalFooter>
					<Button
						colorScheme='gray'
						mr={3}
						onClick={() => {
							if (step === 1) {
								formik.resetForm()
								onClose()
							} else {
								setStep(1)
							}
						}}
					>
						{step === 1 ? 'Cancel' : 'Back'}
					</Button>
					<Button
						sx={{
							background: '#2D3748',
							color: '#fff',
							fontSize: '17px',
							height: '40px',
							width: '160px',
							textTransform: 'uppercase',
							borderRadius: '6px',
							_hover: {
								background: '#47546b',
							},
						}}
						isDisabled={Boolean(
							isLoading ||
								loadingData ||
								formik.errors.reason ||
								formik.errors.practitioner_id ||
								formik.errors.site_id
						)}
						isLoading={isLoading}
						onClick={submitHandler}
					>
						Confirm
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default CreateAppointmentForm
