import {
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	Text,
	useDisclosure,
} from '@chakra-ui/react'
import { AiOutlineInfoCircle } from 'react-icons/ai'

import React from 'react'

import CreateDentallyPatientForm from '~/components/forms/CreatePatientDentally/CreateDentallyPatientForm'
import Button from '~/components/ui/Button'
import { FindLead_findLead } from '~/views/__generated__/FindLead'

interface LeadApptsDentallyDataFormProps {
	leadDetails?: FindLead_findLead
}

const LeadApptsDentallyDataForm: React.FC<LeadApptsDentallyDataFormProps> = ({ leadDetails }) => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	if (!leadDetails?.person) return null
	return (
		<>
			<Flex justify='center' gap='1em' p='1em'>
				<Button
					size='sm'
					onClick={onOpen}
					sx={{
						width: '245px',
						height: '45px',
						background: '#2D3748',
						borderRadius: '6px',
						color: '#fff',
						fontSize: '1em',
						fontWeight: '600',
						_hover: {
							background: '#2D3748',
							opacity: '0.8',
						},
					}}
					leftIcon={<AiOutlineInfoCircle color='#EEAB7E' fontSize={20} />}
				>
					Provide dentally data
				</Button>
			</Flex>
			<Drawer placement='right' onClose={onClose} isOpen={isOpen} size='lg'>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerHeader
						sx={{
							background: 'linear-gradient(90deg, #C29265 0%, #B68150 100%)',
							padding: '20px 40px',
							color: '#fff',
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<Text sx={{ marginRight: '10px' }}>Create Patient</Text>
					</DrawerHeader>
					<DrawerBody sx={{ padding: 0 }}>
						<CreateDentallyPatientForm
							defaultFormValues={{
								email: leadDetails?.person?.email,
								first_name: leadDetails?.person?.first_name,
								last_name: leadDetails?.person?.last_name as string,
								phone: leadDetails?.person?.phone,
							}}
							onClose={onClose}
							leadId={leadDetails?.id}
							trackerPatientId={leadDetails?.person?.id}
						/>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</>
	)
}

export default LeadApptsDentallyDataForm
