import { gql, useMutation, useLazyQuery } from '@apollo/client'
import { navigate } from 'gatsby'

import { FC, useContext } from 'react'

import { useAsyncEffect } from '~/components/helpers'
import { onError } from '~/components/helpers'
import { LeadFormLoader } from '~/components/lead/LeadFormNew'
import LeadFormNew from '~/components/lead/LeadFormNew'
import { AuthContext } from '~/components/page/context'
import useToast from '~/components/ui/Toast'
import { useUpdateLead } from '~/crud/lead'

import { editLeadInput, ENUM_STAGE_TYPE, StageInput } from '../../__generated__/globalTypes'
import { CreateLead, CreateLeadVariables } from './__generated__/CreateLead'
import { CreateStage, CreateStageVariables } from './__generated__/CreateStage'
import { DeleteLead, DeleteLeadVariables } from './__generated__/DeleteLead'
import { FindLead, FindLeadVariables } from './__generated__/FindLead'
import { leadDetailsQuery } from './leadDetail'

/***
 *
 * Queries & Mutations
 *
 ***/
const createLeadMutation = gql`
	mutation CreateLead($createLeadInput: createLeadInput!) {
		createLead(input: $createLeadInput) {
			lead {
				id
			}
		}
	}
`

export const createStageMutation = gql`
	mutation CreateStage($createStageInput: createStageInput!) {
		createStage(input: $createStageInput) {
			stage {
				id
			}
		}
	}
`

const deleteLeadMutation = gql`
	mutation DeleteLead($deleteLeadInput: deleteLeadInput!) {
		deleteLead(input: $deleteLeadInput) {
			lead {
				id
			}
		}
	}
`

/***
 *
 * Lead View Component
 *
 ***/
const AddLeadView: FC = () => {
	const toast = useToast()
	const user = useContext(AuthContext)

	const [fetchLeadDetails, { data, refetch }] = useLazyQuery<FindLead, FindLeadVariables>(
		leadDetailsQuery
	)

	const [createLead, { data: createLeadData }] = useMutation<CreateLead, CreateLeadVariables>(
		createLeadMutation,
		{
			onError: error => onError(error, toast),
		}
	)

	const { handleLeadUpdate } = useUpdateLead({
		onSuccess: {
			handler: () => {
				navigate(-1)
			},
		},
	})

	const [deleteLead] = useMutation<DeleteLead, DeleteLeadVariables>(deleteLeadMutation, {
		onError: error => onError(error, toast),
	})

	const [createStage] = useMutation<CreateStage, CreateStageVariables>(createStageMutation)

	const refetchHandler = async () => {
		await refetch?.()
	}

	const createStageHandler = async (formData: StageInput) => {
		if (!createLeadData?.createLead?.lead?.id) return

		const origin = createLeadData?.createLead?.lead?.id

		const createStageInput: { data: StageInput } = {
			data: {
				...formData,
				type: ENUM_STAGE_TYPE.created,
				origin,
				owner: user.id,
			},
		}

		const { errors } = await createStage({
			variables: { createStageInput },
		})

		if (errors) {
			return Promise.reject(new Error('Unable to create stage.'))
		}

		return Promise.resolve('Stage created successfully')
	}

	const onSubmitHandler = async (formData: editLeadInput) => {
		const leadId = data?.findLead?.id
		if (!leadId) return

		await handleLeadUpdate(leadId, formData)
	}

	const onCancelHandler = async () => {
		const { errors } = await deleteLead({
			variables: {
				deleteLeadInput: { where: { id: data?.findLead?.id as string } },
			},
		})

		!errors && navigate(-1)
	}

	useAsyncEffect(async () => {
		const { data, errors } = await createLead({
			variables: { createLeadInput: { data: { owner: user.id } } },
		})

		if (data?.createLead?.lead?.id && !errors) {
			fetchLeadDetails({ variables: { id: data.createLead.lead.id } })
		}
	}, [])

	if (data?.findLead === null || !refetch) return <LeadFormLoader />

	return (
		<LeadFormNew
			mode='add'
			leadDetails={data?.findLead}
			onRefetch={refetchHandler}
			onSubmit={onSubmitHandler}
			onCreateStage={createStageHandler}
			onCancel={onCancelHandler}
		/>
	)
}

export default AddLeadView
